import React, { useEffect, useState } from "react"
import { Form, Button, Modal, Card, Alert, NavDropdown } from "react-bootstrap"
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs"
import { VscClose } from "react-icons/vsc"
import { useSelector } from "react-redux"
import styles from "./styles.module.css"

const PhotoViewer = ({
    showModal,
    setShowModal,
    profilePhotos,
    photos = [],
    showSelctedUserPhotos
}) => {
    const [activeImage, setActiveImage] = useState(0)
    const [photosList, setPhotosList] = useState([])

    useEffect(() => {
        if (profilePhotos && photos) {
            setPhotosList([profilePhotos, ...photos])
            if (showSelctedUserPhotos) {
                const selectedPhoto = [profilePhotos, ...photos].findIndex(items => items.image === showSelctedUserPhotos || items.user_profile_pic === showSelctedUserPhotos)
                setActiveImage(selectedPhoto)
            } else {
                setActiveImage(0)
            }
        } else {
            setPhotosList([...photos])
            if (showSelctedUserPhotos) {
                const selectedPhoto = [...photos].findIndex(items => items.image === showSelctedUserPhotos || items.user_profile_pic === showSelctedUserPhotos)
                setActiveImage(selectedPhoto)
            } else {
                setActiveImage(0)
            }
        }
    }, [profilePhotos, showSelctedUserPhotos])


    return (
        <Modal
            show={showModal}
            size="md"
            onHide={() => setShowModal(false)}
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <div classname="position-relative">
                <div className="position-absolute top-0 end-0 m-1">
                    <div
                        className={styles.closeButton}
                        onClick={() => setShowModal(false)}
                    >
                        <VscClose size={23} />
                    </div>
                </div>
                <img
                    className="card-img-top"
                    src={
                        photosList[activeImage]?.user_profile_pic ||
                        photosList[activeImage]?.image ||
                        '/Assest/image/homepage-userphoto.png'
                    }
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = "/Assest/image/homepage-userphoto.png"
                    }}
                    alt="."
                    style={{
                        objectFit: "scale-down",
                        objectPosition: "center",
                        borderRadius: "10px",
                    }}
                />
                <div className="position-absolute bottom-0 end-0 m-1 d-flex">
                    {activeImage > 0 && (
                        <div
                            className={`${styles.closeButton} mx-1`}
                            onClick={() => setActiveImage(activeImage - 1)}
                        >
                            <BsArrowLeftShort size={23} />
                        </div>
                    )}
                    {activeImage + 1 < photosList.length && (
                        <div
                            className={styles.closeButton}
                            onClick={() => setActiveImage(activeImage + 1)}
                        >
                            <BsArrowRightShort size={23} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default PhotoViewer
