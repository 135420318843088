import React from 'react'
import { Modal } from 'react-bootstrap'

const FamilyDetails = () => {
    return (
        <>
            <Modal.Header className="border-0 pb-0 align-items-baseline" closeButton>
                <Modal.Body className="pt-0 px-4">
                    FamilyDetails
                </Modal.Body>
            </Modal.Header>
        </>
    )
}

export default FamilyDetails