import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles.module.css";
import { useNavigate } from "react-router-dom";
import ChatMessenger from "./ChatMessenger";
import Loader from "../../../Components/Loader";
import moment from "moment";

export default function ChatSideBar({ setIsChatMessenger, isDesktop, socket, setactiveChatBoxPersonDetail }) {
  const navigate = useNavigate();

  const [chatUsers, setChatUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getAllChat = async () => {
    let data = {
      "event": "getAllChat",
      "data": {}
    }
    await socket.emit('request', (data));
    // await getSocketResponse()
  }

  useEffect(() => {
    getAllChat()
  }, [])

  useEffect(() => {
    socket.on("response", (data) => {
      if (data?.code === 200 && data?.event === 'getAllChat') {
        // const userList = data?.data?.userList
        setChatUsers(data?.data?.userList)
        //console.log(data?.data?.userList)
        //{person.premium_user}
      }
      setIsLoading(false)
    })

    // return () => {
    //   socket.off("response")
    // }
  }, [socket])

  return (
    <>
      <div className="sidebar">
        {isLoading ? (
          <Loader />
        ) : chatUsers?.length > 0 ?
          chatUsers?.map((person) => {
            const mDate = moment(person.lastMessage?.createdAt).format("MM/DD/YYYY").toString()
            const cDate = moment(new Date().toString()).format("MM/DD/YYYY").toString()
            return (
              <div
                className="d-flex py-3 border-bottom align-items-center cursor-pointer"
                onClick={() => {
                  if (!isDesktop) {
                    setIsChatMessenger(true)
                  }
                  setactiveChatBoxPersonDetail(person)
                  //pass {person.premium_user} property to PremiumUser screen to set ChatMessenger if user is premium or not
                }}
              >
                <div className="ms-3">
                  <img
                    src={person?.ProfilePhoto || "/Assest/image/chat-profile1.png"}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/Assest/image/chat-profile1.png";
                    }}
                    alt="."
                    className={`${styles.profileIcon} rounded-circle`}
                  />
                </div>
                <div className="flex-fill ms-3">
                  <p className={`${styles.profileName} mb-0 fw-semibold`}>
                    {person.name}
                  </p>
                  <h5 className={`${styles.chatMessage} mb-0 mt-2`}>
                    {person.lastMessage?.files?.length > 0 ? "Image" : person.lastMessage?.text}
                  </h5>
                </div>
                <div className="flex-fill text-end">
                  <h5 className={`${styles.chatMessageTime} ms-5 mt-2`}> {mDate === cDate ? moment(person.lastMessage?.createdAt).format("LT") : moment(person.lastMessage?.createdAt).format("l")}</h5>
                </div>
              </div>
            )
          })
          :
          (
            <>
              <h6 className="mt-3 text-center">You did not start conversation with anyone yet.</h6>
            </>
          )
        }
      </div>
    </>
  );
}
