import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TbChevronLeft } from "react-icons/tb";
import { AiFillCamera, AiFillPlusCircle } from "react-icons/ai";
import Header from "../../../Components/Header";
import styles from "../styles.module.css";
import Loader from "../../../Components/Loader";
import moment from "moment";
import PhotoViewer from "../../../Components/PhotoViewer";
import { getProfile } from "../../../redux/actions/inboxAction";
import { ProfileView } from "../../../redux/actions/matchesAction";
import TooltipUtils from "../../../Utils/TooltipUtils";
import { ConvertInchToFeetAndInch } from "../../../Utils/HeightUnitsConvertUtils"

const ViewProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showSelctedPhotos, setShowSelctedPhotos] = useState("");
  const [userData, setUserData] = useState({});
  const [appear, setAppear] = useState(false);

  const navigate = useNavigate();

  const [profileData, setprofileData] = useState({});
  const [morePhotos, setMorePhotos] = useState([]);

  // Fetch Homepage data from redux store
  const userDetails = useSelector((state) => state.home.userDetails);

  // Set fetched data to react-state
  useEffect(() => {
    if (userDetails) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(getProfile(location.state)).then((res) => {
      if (res.status === 200) {
        setprofileData(res.data.data);
        dispatch(ProfileView(location.state));
      }
    });
  }, []);

  return (
    <>
      <main>
        <Header />
        <Container>
          <Row className="mt-4 mb-3">
            <Col md={{ span: 9, offset: 1 }} className="cursor-pointer">
              <TbChevronLeft size={20} onClick={() => navigate(-1)} /> &nbsp;
              View Profile
            </Col>
          </Row>

          <Row className="mt-4 mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <div className="d-flex justify-content-center">
                  <div style={{ width: "fit-content" }}>
                    <div className={styles.editProfileImage}>
                      <img
                        // src="/Assest/image/homepage-userphoto.png"
                        src={
                          profileData?.user_details?.user_profile_pic ||
                          "/Assest/image/homepage-userphoto.png"
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "/Assest/image/homepage-userphoto.png";
                        }}
                        alt="profile"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <h5 className="fw-semibold my-2">
                    {profileData?.user_details?.user_name}
                  </h5>

                  {/* {profileData?.user_photos?.length > 0 && (
                    <div>
                      <small className="text-grey mb-0" htmlFor="inputGroupFile01">
                        {" "}
                        <AiFillCamera size={17} /> &nbsp; More Photos
                      </small>
                    </div>
                  )} */}
                </div>
                <div>
                  <div>
                    {userData?.user_detail?.plan_details?.isActive ?
                      (<>
                        {/* {
                             profileData?.user_photos?.length > 0 ?(
                              <div className="d-flex justify-content-end">
                              <p className={`${styles.unlockMorePhotos} align="end" fs-6`}>View more photos</p>
                              </div>
                             ) : (
                              <div>
                            
                              </div>
                             )
                         }           */}
                        {
                          <div className="d-flex flex-wrap">{
                            profileData?.user_photos?.length > 0 ? (
                              profileData?.user_photos?.map((data, index) => {
                                return (
                                  <>
                                    <div className="position-relative rounded-3" key={index}>
                                      <img
                                        className="m-1 rounded-3 position-relative"
                                        src={
                                          data?.image ||
                                          "/Assest/image/homepage-userphoto.png"
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/Assest/image/homepage-userphoto.png";
                                        }}
                                        alt="profile"
                                        height={120}
                                        onClick={() => {
                                          setShowPhotoModal(true);
                                          setShowSelctedPhotos(data?.image);
                                        }}
                                      />
                                    </div>
                                  </>
                                );
                              })
                            )

                              :
                              (
                                <div className="d-flex justify-content-center">
                                  <p className={`fs-4`}>More photos not available</p>
                                </div>
                              )
                          }
                          </div>
                        }
                      </>
                      )
                      :
                      (
                        <>
                          <div className="d-flex justify-content-end">
                            <button className={`${styles.unlockMorePhotos} align="end" fs-6`}
                              onClick={() => navigate("/premimumplans")}>
                              Unlock more photos</button>
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="position-relative rounded-3">
                              <div className="d-flex justify-content-center">
                                <div
                                  className={`${styles.circleImg} d-flex justify-content-center "position-relative rounded-circle ms-2`}
                                >
                                  <img
                                    src="/Assest/image/chat-crown.png"
                                    alt="profile"
                                    className={`${styles.chatCrown} mt-3`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="position-relative rounded-3">
                              <div className="d-flex justify-content-center">
                                <div
                                  className={`${styles.circleImg} d-flex justify-content-center "position-relative rounded-circle ms-2`}
                                >
                                  <img
                                    src="/Assest/image/chat-crown.png"
                                    alt="profile"
                                    className={`${styles.chatCrown} mt-3`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="position-relative rounded-3">
                              <div className="d-flex justify-content-center">
                                <div
                                  className={`${styles.circleImg} d-flex justify-content-center "position-relative rounded-circle ms-2`}
                                >
                                  <img
                                    src="/Assest/image/chat-crown.png"
                                    alt="profile"
                                    className={`${styles.chatCrown} mt-3`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="position-relative rounded-3">
                              <div className="d-flex justify-content-center">
                                <div
                                  className={`${styles.circleImg} d-flex justify-content-center "position-relative rounded-circle ms-2`}
                                >
                                  <img
                                    src="/Assest/image/chat-crown.png"
                                    alt="profile"
                                    className={`${styles.chatCrown} mt-3`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Father Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Father Details</h5>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.father_details
                            ?.father_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.father_details
                            ?.designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.father_details
                            ?.company_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.father_details
                            ?.income || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Mother Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Mother Details</h5>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.mother_details
                            ?.mother_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.mother_details
                            ?.designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.mother_details
                            ?.company_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.mother_details
                            ?.income || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Brother Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Brother Details</h5>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.brother_details
                            ?.brother_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.brother_details
                            ?.designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.brother_details
                            ?.company_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.brother_details
                            ?.income || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.brother_details
                            ?.status || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Sister Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Sister Details</h5>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.sister_details
                            ?.sister_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.sister_details
                            ?.designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.sister_details
                            ?.company_name || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.sister_details
                            ?.income || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {profileData?.family_details?.sister_details
                            ?.status || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Contact Details</h5>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Phone Number</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                      <TooltipUtils text="Please reach out to your match via chat to request any personal details.">
                        <h6>
                          {profileData?.user_contact_details?.mobile_number ||
                            ""}
                        </h6>
                        </TooltipUtils>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>E-mail Addresss</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                      <TooltipUtils text="Please reach out to your match via chat to request any personal details.">
                        <h6>
                          {profileData?.user_contact_details?.email || ""}
                        </h6>
                        </TooltipUtils>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}

          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">About Me</h5>
                    </div>
                  </Col>
                </Row>
                <hr />

                {/* <Row>
                                    <Col lg={6}>
                                        <Row className="align-items-center">
                                            <Col>
                                                <div className="d-flex justify-content-between align-items-center text-lightgrey">
                                                    <h6>First Name</h6>
                                                    <h6>:</h6>
                                                </div>
                                            </Col>
                                            <Col className="p-0">
                                                <h6>{profileData?.about_me?.FirstName || ""}</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        <Row className="align-items-center">
                                            <Col>
                                                <div className="d-flex justify-content-between align-items-center text-lightgrey">
                                                    <h6>Last Name</h6>
                                                    <h6>:</h6>
                                                </div>
                                            </Col>
                                            <Col className="p-0">
                                                <h6>{profileData?.about_me?.LastName || ""}</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.name || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Mother Tongue</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.mother_toungue || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Height</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        {profileData?.about_me?.height ? (
                          <h6>
                            {ConvertInchToFeetAndInch(profileData?.about_me?.height)[0]}'{ConvertInchToFeetAndInch(profileData?.about_me?.height)[1]}"
                            {/* {profileData?.about_me?.height} <small>inch</small> */}
                          </h6>
                        ) : (
                          "-"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Weight</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        {profileData?.about_me?.weight ? (
                          <h6>
                            {profileData?.about_me?.weight} <small>Pound</small>
                          </h6>
                        ) : (
                          "-"
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Gender</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.gender || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Education</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.education || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Resident Country</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.resident_country || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Resident State</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.resident_state || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Resident City</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.resident_city || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Home Country</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.home_country || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Home State</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.home_state || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Home City</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.home_city || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Occupation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.designation || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Annual Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.annual_income || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Work Experience</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.work_experiance || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Interested In</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.interested_in || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Marital Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.marital_status || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Body Type</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.body_type || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Passions/Hobbies</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.passions_hobbies || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Habits</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.habits || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Residency Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{profileData?.about_me?.residency_status || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row className="align-items-center">
                      <Col sm={3} className="pe-0">
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>About My Self </h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col sm={9}>
                        <h6>{profileData?.about_me?.about_yourself || "-"} </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Horoscope Details</h5>
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row>
                  {/* <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Date Of Birth</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                      <TooltipUtils text="Please reach out to your match via chat to request any personal details.">
                        <h6>
                          {profileData?.horoscope_details?.date_of_birth ===
                          "**//**//****"
                            ? "**//**//****"
                            : moment(
                                profileData?.horoscope_details?.date_of_birth
                              ).format("MM/DD/YYYY")}
                        </h6>
                        </TooltipUtils>
                      </Col>
                    </Row>
                  </Col> */}
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Horoscope</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        {/* <TooltipUtils text="Please reach out to your match via chat to request any personal details."> */}
                        <h6>
                          {profileData?.horoscope_details?.horoscope || "-"}{" "}
                        </h6>
                        {/* </TooltipUtils> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>

      {
        <PhotoViewer
          showModal={showPhotoModal}
          setShowModal={setShowPhotoModal}
          photos={profileData?.user_photos}
          showSelctedUserPhotos={showSelctedPhotos}
        />
      }
    </>
  );
};

export default ViewProfile;
