import React, { useState, useEffect } from "react"
import { Form, Modal, ProgressBar } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupBodyTypeList, signupStageSeven } from "../../redux/actions/authAction"


export default function StageSeven({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    interestedin: "",
    bodytype: '',
    castethnicity: '',
  })
  const [others, setOthers] = useState({
    bodytype: false,
    castethnicity: false
  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [bodyTypeData, setBodyTypeData] = useState([])

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    if (fields.interestedin && fields.bodytype) {
      setError({})
      setLoading(true)
      let data = {
        interestedin: fields.interestedin,
        bodytype: fields.bodytype,
        // castethnicity: fields.castethnicity
      }
      dispatch(signupStageSeven(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        interestedin: !fields.interestedin,
        bodytype: !fields.bodytype,
        // castethnicity: !fields.castethnicity
      })
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (value === "Other") {
      setOthers({ ...others, [name]: true })
    } else {
      setFiels({ ...fields, [name]: value })
    }
  }

  useEffect(() => {
    dispatch(signupBodyTypeList()).then((res) => {
      setBodyTypeData(res?.data?.data)
    })
  }, [])

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-block">
        <ProgressBar now={85} />
      </Modal.Header>
      <Modal.Body className="pt-3 px-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="m-0">Interested In</Form.Label>
            {others.interestedin ?
              <>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="interestedin"
                  value={fields.interestedin}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </> : <>
                <Form.Select
                  name="interestedin"
                  className={fields.interestedin === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Boy
                  </option>
                  <option value="boy">Boy</option>
                  <option value="girl">Girl</option>
                  <option value="other">Other</option>
                </Form.Select>
              </>}
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Body Type</Form.Label>
            {others.bodytype ?
              <>
                <Form.Control
                  type="text"
                  placeholder="Body Type"
                  name="bodytype"
                  value={fields.bodytype}
                  onChange={(e) => handleChange(e)}
                />
              </> : <>
                <Form.Select
                  name="bodytype"
                  className={fields.bodytype === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Body Type
                  </option>
                  {
                    bodyTypeData.map((data,id) => (
                      <>
                        <option key={id} value={data.bodyTypeName}>{data.bodyTypeName}</option>
                      </>
                    ))
                  }
                  <option value="Other">Other</option>
                </Form.Select>
              </>}
          </Form.Group>
          <br />
          {/* <Form.Group>
            <Form.Label className="m-0">Cast / Ethnicity</Form.Label>
            {others.castethnicity ?
              <>
                <Form.Control
                  type="text"
                  placeholder="Your Cast / Ethnicity"
                  name="castethnicity"
                  value={fields.castethnicity}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </> : <>
                <Form.Select
                  name="castethnicity"
                  className={fields.castethnicity === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Your Cast / Ethnicity
                  </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </>}
          </Form.Group> */}


          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.interestedin || !fields.bodytype} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
