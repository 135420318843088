import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import CustomButton from '../../../Components/CustomButton'
import { editSisterDetails, getMyProfileData } from '../../../redux/actions/editProfileAction'

const SisterDetails = ({ setModalShow, myProfileData }) => {
    const dispatch = useDispatch()
    const [fields, setFiels] = useState({
        sistername: myProfileData?.FamilyDetails?.SisterDetails?.SisterName || "",
        designation: myProfileData?.FamilyDetails?.SisterDetails?.Designation || "",
        companyname: myProfileData?.FamilyDetails?.SisterDetails?.CompanyName || "",
        sisterincome: myProfileData?.FamilyDetails?.SisterDetails?.SisterIncome || "",
        status: myProfileData?.FamilyDetails?.SisterDetails?.Status || ""
    })

    const [loading, setLoading] = useState(false)

    // Handle Form Submition
    async function handleSubmit(e) {
        e.preventDefault()

        setLoading(true)
        let data = {
            SisterName: fields.sistername,
            Designation: fields.designation,
            CompanyName: fields.companyname,
            SisterIncome: parseInt(fields.sisterincome),
            Status: fields.status,
        }
        dispatch(editSisterDetails(data)).then((res) => {
            if (res.status === 200) {
                dispatch(getMyProfileData())
                setModalShow(false)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

    }

    // Handle Field Values
    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "sisterincome") {
            setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        } else {
            setFiels({ ...fields, [name]: value })
        }
    }

    return (
        <>
            <Modal.Header className="border-0 pb-1 align-items-baseline" closeButton />
            <Modal.Body className="pt-0 px-4">
                <Form onSubmit={handleSubmit}>
                    <Form.Group >
                        <Form.Label className="m-0">Sister’s Name</Form.Label>
                        <Form.Control type="text" name="sistername"
                            placeholder='Sister’s name' value={fields.sistername}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Designation</Form.Label>
                        <Form.Control type="text" name="designation"
                            placeholder='Designation' value={fields.designation}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Company Name</Form.Label>
                        <Form.Control type="text" name="companyname"
                            placeholder='Company Name' value={fields.companyname}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Sister Income</Form.Label>
                        <Form.Control type="text" name="sisterincome"
                            placeholder='$000.00' value={fields.sisterincome}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label className="m-0">Status</Form.Label>
                        <Form.Select
                            name="status"
                            value={fields.status}
                            className={fields.status === "" && "selectplaceholder"}
                            onChange={(e) => handleChange(e)}
                            defaultValue="Select Status"
                        >
                            <option value=""  disabled hidden>
                                
                            </option>
                            <option value="married">married</option>
                            <option value="unmarried">unmarried</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="text-center my-5">
                        <CustomButton btntext="Continue" loading={loading} />
                    </Form.Group>
                </Form>
            </Modal.Body>
        </>
    )
}

export default SisterDetails