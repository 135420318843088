import React, { useState, useEffect } from "react"
import { Form, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import styles from "./styles.module.css"
import {
  generateRecaptcha,
  setPhoneNumber,
  loginwithFacebook,
  loginwithGoogle,
  loginwithApple,
  loginToDatabase,
} from "../../redux/actions/authAction"
import CustomButton from "../../Components/CustomButton"
import { toast } from "react-toastify"
import { APP_NAME } from '../../Services/config';

export default function Login(props) {
  const [fields, setFiels] = useState({
    mobile: "",
  })
  const [rememberme, setRememberme] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [modalStage, setModalStage] = useState(-2)
    // const [modalStage, setModalStage] = useState(4)

    useEffect(() => {
        setModalStage(props.modalstage)
    }, [props.modalstage])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target
    setError(false)
    setFiels({ ...fields, [name]: value.replace(/[^\d+]/g, "") })
  }

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()
    if (fields.mobile.length >= 7 && fields.mobile.length <= 15 && fields.mobile.startsWith("+")) {
      try {
        setError(false)
        setLoading(true)
        await generateRecaptcha()
        await dispatch(setPhoneNumber(fields.mobile)).then((res) => {
          if (res) {
            props.handleModalStage()
          }
        }).catch(error => {
          setLoading(false)
        })
      } catch (error) {
        toast.error(error?.message);
        setLoading(false)
      }
      setLoading(false)
    } else {
      setError(true)
      toast.warn("Please enter valid mobile number");
    }
  }

  // login with Facebook
  async function handleloginwithfacebook() {
    try {
      setError("")
      setLoading(true)
      await loginwithFacebook()
      dispatch(loginToDatabase()).then((res) => {
        if (res?.data?.isCompleted) {
          navigate("/", { replace: true })
        } else if (res?.data?.stage) {
          props.setModalStage(res?.data?.stage)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
      })
    } catch (error) {
      setError("Failed to sign in")
      setLoading(false)
    }
    setLoading(false)
  }

  // login with Google
  async function handleloginwithgoogle() {
    try {
      setError("")
      setLoading(true)
      await loginwithGoogle()
      dispatch(loginToDatabase()).then((res) => {
        if (res?.data?.isCompleted) {
          navigate("/", { replace: true })
        } else if (res?.data?.stage) {
          props.setModalStage(res?.data?.stage)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
      })

    } catch (error) {
      setError("Failed to sign in")
      setLoading(false)
    }
    setLoading(false)
  }

  // login with AppleID
  async function handleloginwithapple() {
    try {
      setError("")
      setLoading(true)
      await loginwithApple()
      dispatch(loginToDatabase()).then((res) => {
        if (res?.data?.isCompleted) {
          navigate("/", { replace: true })
        } else if (res?.data?.stage) {
          props.setModalStage(res?.data?.stage)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
      })
    } catch (error) {
      setError("Failed to sign in")
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <>
      <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <div className="d-flex justify-content-center align-items-center flex-column">
          {/* <div className={`${styles.loginlogo} bg-lightgrey`}> */}
          <img
            src="/Assest/icons/spouzeLogo.png"
            alt="."
            height={70}
            width={70}
          />
          {/* </div> */}
          
           {
            modalStage===-3?
            <div>
              <p className="text-grey fs-4 mt-3"> New to {APP_NAME}?{" "}Register Now!</p>
            </div>
            :
            <div>
              <p className="text-grey fs-4 mt-3">Welcome back! Please Login</p>
            </div>
           }
          
          
        </div>
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="MobileNumber">
              <Form.Label className="m-0">Mobile Number</Form.Label>
              <Form.Control
                type="text"
                required
                name="mobile"
                value={fields.mobile}
                onChange={handleChange}
                // maxLength={10}
                placeholder="Mobile number with country code"
              />
            </Form.Group>
            {/* <Form.Check
              type="radio"
              className="mt-3"
              id={`default-radio`}
              label={`Remember me?`}
              checked={rememberme}
              onClick={() => setRememberme(!rememberme)}
            /> */}
            {modalStage===-3?
            <Form.Group className="text-center mt-4">
              <CustomButton loading={loading || fields.mobile.length <= 7 || fields.mobile.length >= 15 || error} btntext="SIGN UP" />
            </Form.Group>
            :
            <Form.Group className="text-center mt-4">
              <CustomButton loading={loading || fields.mobile.length <= 7 || fields.mobile.length >= 15 || error} btntext="SIGN IN" />
            </Form.Group>}
          </Form>
        </div>
        {/* <div className="text-center mt-5">
          <p>
            New to {APP_NAME}?{" "}
            <span
              className="theme-color cursor-pointer"
              onClick={() => props.setModalStage(-1)}
            >
              Register Now!
            </span>
          </p>
        </div> */}
        <div className="text-center mt-3 text-lightgrey">
          <p>or use your social media</p>
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button className="border-0 bg-white" onClick={handleloginwithfacebook} disabled={loading}>
            <img src="/Assest/icons/facebook.svg " alt="" className={styles.signwith} />
          </button>
          <button className="border-0 bg-white" onClick={handleloginwithgoogle} disabled={loading}>
            <img src="/Assest/icons/google.svg" alt="" className={`${styles.signwith} mx-4`} />
          </button>
          <button className="border-0 bg-white" onClick={handleloginwithapple} disabled={loading}>
            <img src="/Assest/icons/apple.svg" alt="" className={styles.signwith} />
          </button>
        </div>

        <div className="pt-3 mt-4 text-black text-center">
            <p className={`${styles.footerText}`}>
              By continuing, you agree to our{" "}
              <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="theme-color"> <u>Terms & Service</u></a> <br />
              <span>and</span>{" "}
              <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="theme-color"> <u>Privacy Policy</u></a>.
            </p>
          </div>
      </Modal.Body>
      <div id="recaptcha-container"></div>
    </>
  )
}
