import React, { useEffect, useState } from "react"
import { Form, Button, Modal, Card, Alert, NavDropdown } from "react-bootstrap"
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs"
import { VscClose } from "react-icons/vsc"
import { useSelector } from "react-redux"
import styles from "../styles.module.css"

const ChatPhotoViewer = ({
    showModal,
    handleClose,
    imageLink
}) => {

    return (
        <Modal
            show={showModal}
            size="md"
            onHide={handleClose}
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <div classname="position-relative">
                <div className="position-absolute top-0 end-0 m-1">
                    <div
                        className={styles.closeButton}
                        onClick={handleClose}
                    >
                        <VscClose size={23} />
                    </div>
                </div>
                <img
                    className="card-img-top"
                    src={imageLink}
                    alt="."
                    style={{
                        objectFit: "scale-down",
                        objectPosition: "center",
                        borderRadius: "10px",
                    }}
                />
            </div>
        </Modal>
    )
}

export default ChatPhotoViewer
