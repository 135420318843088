import moment from "moment";
import React, { useEffect, useState } from "react";
import { CgChevronLeft } from "react-icons/cg";
import styles from "../styles.module.css";
import Messages from "./Messages";
import MessagesFooter from "./MessagesFooter";

export default function ChatMessenger({ setIsChatMessenger, isDesktop, socket, activeChatBoxPersonDetail, firstMessUser, senderId }) {


  const [messageList, setMessageList] = useState([])


  const getMessageList = async () => {
    let data = {
      "event": "getMessageList",
      "data": {
        "receiver": activeChatBoxPersonDetail.UserId || firstMessUser?.user_id,
        "sender": senderId,
        "limit": 20
      }
    }
    await socket.emit('request', (data));
    // await getSocketResponse()
  }

  useEffect(() => {
    console.log('activechatboxpersonaldetails')
    getMessageList()
  }, [activeChatBoxPersonDetail])

  useEffect(() => {
    socket.on("response", (data) => {
      // console.log("getMessageList_receivedMessage", data)
      if (data?.code === 200 && data?.event === 'getMessageList') {
        const message = [...data?.data?.messageList].reverse()
        setMessageList(message)
      }
      if (data?.code === 200 && data?.event === 'receivedMessage') {
        {
          setMessageList(state => [data?.data?.messageInfo, ...state])
        }
      }
    })

    return ()=>{ socket.off("response")}
  }, [socket])

  // console.log('messageList', activeChatBoxPersonDetail)

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className={`${styles.chatProfileHeader}`}>
          <div className="d-flex py-3">
            <div className="mx-3">
              <CgChevronLeft
                size={25}
                className={`${styles.arrows} mt-2`}
                onClick={() => {
                  if (!isDesktop) {
                    setIsChatMessenger(false)
                  }
                }}
              />
            </div>
            <img
              src={activeChatBoxPersonDetail?.ProfilePhoto || firstMessUser?.user_details?.user_profile_pic || "/Assest/image/chat-profile1.png"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/Assest/image/chat-profile1.png";
              }}
              alt="."
              width="46.44px"
              height="46.44px"
              className="rounded-circle"
            />
            <div className="ms-2 my-auto">
              <p className={`${styles.chatProfileName} mb-0 fw-semibold`}>
                {activeChatBoxPersonDetail?.name || firstMessUser?.user_details?.user_name}
              </p>
              {/* {activeChatBoxPersonDetail?.lastMessage &&
                <h5 className={`${styles.chatProfileStatus}`}>
                  {activeChatBoxPersonDetail?.lastMessage?.is_online ? "Online" : moment(activeChatBoxPersonDetail?.lastMessage?.last_active).format("MM/DD/YYYY")}
                </h5>
              } */}
            </div>
          </div>
        </div>
        <Messages messageList={messageList} senderId={senderId} />
        <MessagesFooter socket={socket} activeChatBoxPersonDetail={activeChatBoxPersonDetail} firstMessUser={firstMessUser} />
      </div>

    </>
  );
}
