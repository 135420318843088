import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import { HiOutlineChevronRight } from "react-icons/hi";
import { AiOutlineCrown } from "react-icons/ai";
import styles from "./styles.module.css";
import {
  getNotificationList,
  homePageData,
} from "../../redux/actions/homeAction";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Components/Loader";
import moment from "moment/moment";
import { io } from "socket.io-client";
import { getPlans } from "../../redux/actions/homeAction";
import { BsCheckCircleFill } from "react-icons/bs";

const socket = io("https://api.spouze.co/");

export default function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [notification, setNotification] = useState(null);
  const [notificationListdata, setNotificationListdata] = useState([]);
  // const [viewAllNotification, setViewAllNotification] = useState(false)
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [plans, setPlans] = useState([]);

  // Fetch Homepage data API
  useEffect(() => {
    dispatch(homePageData());
    dispatch(getNotificationList(page)).then((res) => {
      if (res.status === 200) {
        setNotification(res.data.data);
        setNotificationListdata(res.data?.data?.list);
        if (
          res?.data?.data?.pages === 0 ||
          res.data.data.current === res.data.data.pages
        ) {
          setHasMore(false);
        }
      }
    });
  }, []);

  // Fetch Homepage data from redux store
  const userDetails = useSelector((state) => state.home.userDetails);

  // Set fetched data to react-state
  useEffect(() => {
    if (userDetails) {
      setUserData(userDetails);
      dispatch(getPlans());
    }
  }, [userDetails]);

  const userplans = useSelector((state) => state.home.plans);

  useEffect(() => {
    if (userplans) {
      setPlans(userplans);
    }
  }, [userplans]);

  const fetchMoreNotification = () => {
    dispatch(getNotificationList(page + 1)).then((res) => {
      if (res.status === 200) {
        setNotificationListdata([
          ...notificationListdata,
          ...res?.data?.data?.list,
        ]);
        if (
          res?.data?.data?.pages === 0 ||
          res?.data?.data?.current === res?.data?.data?.pages
        ) {
          setHasMore(false);
        } else {
          setPage(res?.data?.data?.current);
        }
      }
    });
  };

  // const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
  // const currentUserID = useSelector(state => state.auth.currentUserID)
  // const getUserDetail = currentUserID || currentuser?.uid

  // useEffect(() => {
  //   if (currentuser && currentUserID) {
  //     let user = {
  //       UserId: getUserDetail,
  //     }
  //     socket.emit("authenticate", user)
  //   }
  // }, [currentuser, currentUserID, getUserDetail])

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="row mt-2 mb-5">
            <div className="col-sm-8">
              <div className={`${styles.userBox} mt-3`}>
                <div className={styles.userBoxTop}></div>
                <div className={styles.userBoxUserProfile}>
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        userData?.user_detail?.user_profile_pic ||
                        "/Assest/image/homepage-userphoto.png"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "/Assest/image/homepage-userphoto.png";
                      }}
                      alt=""
                      className={styles.userBoxUserImage}
                    />
                    <div className="ms-3 mt-4">
                      <h5>{userData?.user_detail?.user_name}</h5>
                      {/* <p className="text-lightgrey">(SH8569325)</p> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end text-end">
                    <div>
                      <h5 className="text-grey">Views</h5>
                      <h5 className="mb-3">{userData?.user_detail?.views}</h5>
                    </div>
                  </div>
                </div>
                <div className={`${styles.profileOption} mt-3`}>
                  <div className="row justify-content-around">
                    <div
                      className="col-sm-3 text-center mt-2 cursor-pointer"
                      onClick={() => navigate("/editprofile")}
                    >
                      <img
                        src="/Assest/image/edit.png"
                        alt="."
                        height="43px"
                        width="40px"
                      />
                      <p className="text-lightgrey mt-2">Edit Profile</p>
                    </div>
                    <div className="col-sm-1">
                      <div
                        className={`border-end-0 ${styles.userBoxProfileicon}`}
                      ></div>
                    </div>
                    <div
                      className={`col-sm-3 text-center mt-2 cursor-pointer`}
                      onClick={() => navigate("/editpreferences")}
                    >
                      <img
                        src="/Assest/image/document.png"
                        alt="."
                        height="43px"
                        width="35px"
                      />
                      <p className="text-lightgrey mt-2">Edit Prefrences</p>
                    </div>
                    <div className="col-sm-1 ">
                      <div
                        className={`border-start-0 ${styles.userBoxProfileicon}`}
                      ></div>
                    </div>
                    <div
                      className="col-sm-3 text-center mt-2 cursor-pointer"
                      onClick={() => navigate("/setting")}
                    >
                      <img
                        src="/Assest/image/setting.png"
                        alt="."
                        height="40px"
                        width="40px"
                      />
                      <p className="text-lightgrey mt-2">Setting</p>
                    </div>
                  </div>
                  {/* <div className="text-center mt-3">
                    <button
                      className={`${styles.profileBtn}`}
                      onClick={() => navigate("/premimumplans")}
                    >
                      <AiOutlineCrown size={25} className="me-3 mb-1" />
                      Upgrade Now
                    </button>
                  </div> */}
                </div>
              </div>
              <div className={`row m-0 p-4 mt-4 ${styles.userActivityBox}`}>
                <div>
                  <h5 className="theme-color">
                    Interests Sent
                    {/* ({userData?.interest_send}) */}
                  </h5>
                  <p className="text-grey mb-0 mt-3">
                    You have sent {userData?.interest_send} {userData?.interest_send > 1 ? `interests` : `interest`}. Contact
                    them
                    <br />
                    right away!
                  </p>
                </div>
                <div className="d-flex align-items-end justify-content-end cursor-pointer">
                  <h5 className="m-0" onClick={() => navigate("/inboxpage")}>
                    View now
                    <HiOutlineChevronRight />
                  </h5>
                </div>
              </div>
              <div className={`row m-0 p-4 mt-4 ${styles.userActivityBox}`}>
                <div>
                  <h5 className="theme-color">
                    Your Matches
                    {/* ({userData?.your_matches}) */}
                  </h5>
                  <p className="text-grey mb-0 mt-3">
                    You have {userData?.your_matches} new {userData?.your_matches > 1 ? `matches` : `match`}
                  </p>
                </div>
                <div className="d-flex align-items-end justify-content-end cursor-pointer">
                  <h5
                    className="m-0"
                    onClick={() =>
                      navigate("/matches", {
                        state: "6353b6110d631f876ce07286",
                      })
                    }
                  >
                    View now
                    <HiOutlineChevronRight />
                  </h5>
                </div>
              </div>
              <div className={`row m-0 p-4 mt-4 ${styles.userActivityBox}`}>
                <div>
                  <h5 className="theme-color">
                    Viewed Matches
                    {/* ({userData?.viewed_matches}) */}
                  </h5>
                  <p className="text-grey mb-0 mt-3">
                    You have {userData?.viewed_matches} viewed {userData?.viewed_matches > 1 ? `matches` : `match`}
                  </p>
                </div>
                <div className="d-flex align-items-end justify-content-end cursor-pointer">
                  <h5
                    className="m-0"
                    onClick={() =>
                      navigate("/matches", {
                        state: "6353b6340d631f876ce0729f",
                      })
                    }
                  >
                    View now
                    <HiOutlineChevronRight />
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mt-3 d-flex flex-column">
              <div className={`${styles.ads}`}>
                {
                  userData?.user_detail?.plan_details?.isActive ?
                    <>
                      {/* premium user */}
                      <div className="d-flex justify-content-center flex-column">
                        <div className="d-flex justify-content-center mt-4">
                          <div className="position-relative me-1">
                            <img src="/Assest/image/group111.png" alt="profile" />
                          </div>
                          <div
                            className={`${styles.circleImg} d-flex justify-content-center position-absolute rounded-circle mt-3 ms-2`}
                          >
                            <img
                              src="/Assest/image/chat-crown.png"
                              alt="profile"
                              className={`${styles.premiumCrown} mt-3`}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          <h5
                            className={`${styles.premiumText} position-relative text-center text-nowrap fw-bold`}
                          >
                            You are a premium member!
                          </h5>
                        </div>
                        <div className="d-flex justify-content-center mt-1">
                          <h5
                            className={`${styles.premiumText} position-relative text-center fw-bold`}
                          >
                            Your active plan is {userData?.user_detail?.plan_details?.plan_Name}
                          </h5>
                        </div>
                        <div className="d-flex justify-content-center mt-1">
                          <h5
                            className={`${styles.premiumText} position-relative text-center text-nowrap`}
                          >
                            Your plan expires on {moment.unix(userData?.user_detail?.plan_details?.expires_date/1000).format("MM/DD/YYYY hh:mm")}
                          </h5>
                        </div>
                        <div className="d-flex justify-content-center mt-1">
                          <h5
                            className={`${styles.premiumText} position-relative text-center text-nowrap fst-italic`}
                          >
                            Good luck with your search!
                          </h5>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {/* not premium user */}
                      <div className="d-flex justify-content-center flex-column">
                        <div className="d-flex justify-content-center mt-4">
                          <div className="position-relative me-1">
                            <img src="/Assest/image/group111.png" alt="profile" />
                          </div>
                          <div
                            className={`${styles.circleImg} d-flex justify-content-center position-absolute rounded-circle mt-3 ms-2`}
                          >
                            <img
                              src="/Assest/image/chat-crown.png"
                              alt="profile"
                              className={`${styles.premiumCrown} mt-3`}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          <h5
                            className={`${styles.premiumText} position-relative text-center text-nowrap fw-bold`}
                          >
                            Become a premium user!
                          </h5>
                        </div>
                        <div className="d-flex flex-column justify-content-center mt-2">
                          <p className="d-flex justify-content-center mb-2" style={{ fontSize: 12 }}>
                            <BsCheckCircleFill
                              className="me-2"
                              size={15}
                              style={{ color: "#14D111" }}
                            />
                            Chat with your Matches
                          </p>
                          <p className="d-flex mb-2" style={{ fontSize: 12 }}>
                            <BsCheckCircleFill
                              className="me-2"
                              size={15}
                              style={{ color: "#14D111",marginLeft: "15%" }}
                            />
                            Unlock all photos
                          </p>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          <h5
                            className={`${styles.premiumText} position-relative text-center text-nowrap fst-italic`}
                          >
                            Good luck with your search!
                          </h5>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          <button className={`${styles.upgradeNowBtn} mt-1 text-nowrap`}
                          onClick={() => navigate("/premimumplans")}>
                            Upgrade Now
                          </button>
                        </div>
                      </div>
                      {/*  */}
                    </>
                }
                {/* <button className={`${styles.btnactiveplan} btn btn-outline-light d-flex align-items-center me-4`}>
                  Current Plan
                </button> */}
                {/* {plans?.map((item) => {
                  return (
                    <>
                      {item?.userPlanActive == true ? (
                        <button className={`${styles.activeplan}`}>
                          {" "}
                          <AiOutlineCrown size={20} className="me-3" />
                          {item?.planName}
                        </button>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })} */}
              </div>
              <div className={`${styles.notification} mt-4 d-flex flex-column`}>
                <h6 style={{ color: "#f3134a" }}>
                  Notification ({notification?.totalitems || 0})
                </h6>
                <InfiniteScroll
                  dataLength={page * 10}
                  next={fetchMoreNotification}
                  hasMore={hasMore}
                  loader={<Loader />}
                  height={400}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                >
                  {notificationListdata?.map((item,index) => {
                    return (
                      <>
                        <div className="d-flex py-2 border-bottom" key={index}>
                          <div>
                            <img
                              src={
                                item?.user_profile_pic ||
                                "/Assest/image/homepage-userphoto.png"
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/Assest/image/homepage-userphoto.png";
                              }}
                              height="50px"
                              width="50px"
                              alt="."
                              className="rounded-circle"
                            />
                          </div>
                          <div className="ms-3">
                            <p className="mb-0 fw-semibold">{item.message}</p>
                            <p
                              className="mb-0 text-secondary"
                              style={{ fontSize: 14 }}
                            >
                              {moment(item.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </InfiniteScroll>
                {/* {notificationListdata?.length > 4 &&
                  <p className="text-center theme-color m-0 mt-2 fw-semibold" onClick={() => setViewAllNotification(!viewAllNotification)}>
                    {viewAllNotification ? "Hide All" : "View All"}
                  </p>
                } */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
