import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Header from "../../Components/Header"
import styles from "./styles.module.css"
import Slider from "react-slick"
import { BsCheckCircleFill } from "react-icons/bs"

// Hidden Arrows Right and Left
function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        />
    )
}

export default function BrowseMembership() {
    const navigate = useNavigate()
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        // initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1324,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1054,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <>
            {/* <Header /> */}
            <main>
                <div className="position-relative overflow-hidden">
                    <div className={styles.premimumBackCurv}></div>
                </div>
                <Container fluid className={styles.premimumCardsContainer}>
                    <div className="text-white text-center mb-4">
                        <h3 className="fw-semibold mb-4">Upgrade to Premium</h3>
                        <h5>
                            Limited time offer! Get up to <u>15 Extra Days</u> on <br />{" "}
                            Select Plans.
                        </h5>
                    </div>
                    <Slider {...settings}>
                        <div>
                            <div className="mt-3 d-flex justify-content-center">
                                <Card className={styles.premimumCard}>
                                    <Card.Body className="text-center">
                                        <Card.Title className="fw-bold">Classic</Card.Title>
                                        <p className="mb-2 text-grey">3 Months + 7 extra Days</p>
                                        <div className={styles.hrline}></div>
                                        <Card.Text>
                                            <p>
                                                <del className="fw-semibold">$2653</del> &nbsp; &nbsp;
                                                &nbsp; <span style={{ color: "#14D111" }}>50% off</span>
                                            </p>
                                            <h3 className="fw-bold">$1652</h3>
                                            <p className="text-grey">Per day - $12</p>
                                            <button
                                                className={`${styles.continueBtn}`}
                                                onClick={() => navigate("/membershippayments")}
                                            >
                                                Continue
                                            </button>
                                            <div className="text-start mt-4">
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Chat with your Matches
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    View 200 contact numbers
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Whatsapp your Matches
                                                </p>
                                            </div>
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link> */}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3 d-flex justify-content-center">
                                <Card className={styles.premimumCard}>
                                    <Card.Body className="text-center">
                                        <Card.Title className="fw-bold">Classic</Card.Title>
                                        <p className="mb-2 text-grey">3 Months + 7 extra Days</p>
                                        <div className={styles.hrline}></div>
                                        <Card.Text>
                                            <p>
                                                <del className="fw-semibold">$2653</del> &nbsp; &nbsp;
                                                &nbsp; <span style={{ color: "#14D111" }}>50% off</span>
                                            </p>
                                            <h3 className="fw-bold">$1652</h3>
                                            <p className="text-grey">Per day - $12</p>
                                            <button
                                                className={`${styles.continueBtn}`}
                                                onClick={() => navigate("/membershippayments")}
                                            >
                                                Continue
                                            </button>
                                            <div className="text-start mt-4">
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Chat with your Matches
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    View 200 contact numbers
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Whatsapp your Matches
                                                </p>
                                            </div>
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link> */}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3 d-flex justify-content-center">
                                <Card className={styles.premimumCard}>
                                    <Card.Body className="text-center">
                                        <Card.Title className="fw-bold">Classic</Card.Title>
                                        <p className="mb-2 text-grey">3 Months + 7 extra Days</p>
                                        <div className={styles.hrline}></div>
                                        <Card.Text>
                                            <p>
                                                <del className="fw-semibold">$2653</del> &nbsp; &nbsp;
                                                &nbsp; <span style={{ color: "#14D111" }}>50% off</span>
                                            </p>
                                            <h3 className="fw-bold">$1652</h3>
                                            <p className="text-grey">Per day - $12</p>
                                            <button
                                                className={`${styles.continueBtn}`}
                                                onClick={() => navigate("/membershippayments")}
                                            >
                                                Continue
                                            </button>
                                            <div className="text-start mt-4">
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Chat with your Matches
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    View 200 contact numbers
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Whatsapp your Matches
                                                </p>
                                            </div>
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link> */}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3 d-flex justify-content-center">
                                <Card className={styles.premimumCard}>
                                    <Card.Body className="text-center">
                                        <Card.Title className="fw-bold">Classic</Card.Title>
                                        <p className="mb-2 text-grey">3 Months + 7 extra Days</p>
                                        <div className={styles.hrline}></div>
                                        <Card.Text>
                                            <p>
                                                <del className="fw-semibold">$2653</del> &nbsp; &nbsp;
                                                &nbsp; <span style={{ color: "#14D111" }}>50% off</span>
                                            </p>
                                            <h3 className="fw-bold">$1652</h3>
                                            <p className="text-grey">Per day - $12</p>
                                            <button
                                                className={`${styles.continueBtn}`}
                                                onClick={() => navigate("/membershippayments")}
                                            >
                                                Continue
                                            </button>
                                            <div className="text-start mt-4">
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Chat with your Matches
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    View 200 contact numbers
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Whatsapp your Matches
                                                </p>
                                            </div>
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link> */}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3 d-flex justify-content-center">
                                <Card className={styles.premimumCard}>
                                    <Card.Body className="text-center">
                                        <Card.Title className="fw-bold">Classic</Card.Title>
                                        <p className="mb-2 text-grey">3 Months + 7 extra Days</p>
                                        <div className={styles.hrline}></div>
                                        <Card.Text>
                                            <p>
                                                <del className="fw-semibold">$2653</del> &nbsp; &nbsp;
                                                &nbsp; <span style={{ color: "#14D111" }}>50% off</span>
                                            </p>
                                            <h3 className="fw-bold">$1652</h3>
                                            <p className="text-grey">Per day - $12</p>
                                            <button
                                                className={`${styles.continueBtn}`}
                                                onClick={() => navigate("/membershippayments")}
                                            >
                                                Continue
                                            </button>
                                            <div className="text-start mt-4">
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Chat with your Matches
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    View 200 contact numbers
                                                </p>
                                                <p style={{ fontSize: 12 }}>
                                                    <BsCheckCircleFill
                                                        className="me-2"
                                                        size={15}
                                                        style={{ color: "#14D111" }}
                                                    />
                                                    Whatsapp your Matches
                                                </p>
                                            </div>
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link> */}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Slider>
                </Container>
            </main>
        </>
    )
}
