import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Avatar from "react-avatar-edit";
import styles from "./styles.module.css";

export default function UpdateProfile(props) {
  const { showModal, handleClose, onCrop, onClose, handleSubmit, setImageDetail } = props;

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered size="md">
        <Modal.Header
          closeButton
          className="border-0 pb-0 justify-content-center align-items-center"
        >
          <Modal.Title className="ms-auto">Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Row>
            <Col
              className={`${styles.profileCloseIcon} mx-4 d-flex justify-content-center align-items-center`}
            >
              <Avatar
                width={250}
                height={250}
                onCrop={onCrop}
                onClose={onClose}
                onFileLoad={e => setImageDetail(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 mx-5 d-flex justify-content-center align-items-center">
              <button className={`${styles.btnSave}`} onClick={handleSubmit}>
                Save
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
