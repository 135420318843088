/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userDetails } from "./redux/actions/authAction";
import LandingPage from "./Pages/LandingPage";
import PrivateRoute from "./PrivateRoute";
import Signup from "./Authintication/SignUp";
import HomePage from "./Pages/Home";
import Matches from "./Pages/Matches";
import PremimumPlans from "./Pages/Premimum";
import PremimumPayments from "./Pages/Premimum/PremimumPayments";
import Setting from "./Pages/Setting";
import InterestAndRequests from "./Pages/Others/InterestAndRequests";
import EditProfile from "./Pages/Others/EditProfile";
import InboxPage from "./Pages/Inbox";
import ChatHomePage from "./Pages/Chat";
import EditPreferences from "./Pages/Others/EditPreferences";
import Header from "./Components/Header";
import Pageone from "./Authintication/WelcomePages/Pageone";
import PageTwo from "./Authintication/WelcomePages/PageTwo";
import BrowseMembership from "./Pages/BeforeLogin/BrowseMembership";
import MembershipPay from "./Pages/BeforeLogin/MembershipPay";
import ViewProfile from "./Pages/Inbox/ViewProfile";
import { io } from "socket.io-client";
import PaymentSuccess from "./Pages/Premimum/PaymentSuccess";
import PaymentCancel from "./Pages/Premimum/PaymentCancel";

// import ChatMessenger from "./Pages/Chat/ChatMessenger";
// import ChatSideBar from "./Pages/Chat/PremiumUser/ChatSideBar";

const socket = io("https://api.spouze.co/");

function App() {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(socket.connected);

  // fetchUsers data
  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        socket.connect();
      }
    });

    return () => {
      socket.off("connect");
    };
  }, []);

  // const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
  // const currentUserID = useSelector(state => state.auth.currentUserID)
  // const getUserDetail = currentUserID || currentuser?.uid

  // useEffect(() => {
  //   if (isConnected && getUserDetail) {
  //     let user = {
  //       UserId: getUserDetail,
  //     }
  //     socket.emit("authenticate", user)
  //   }
  // }, [getUserDetail, isConnected])

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/matches" element={<Matches />} />
          <Route exact path="/inboxpage" element={<InboxPage />} />
          <Route exact path="/viewprofile" element={<ViewProfile />} />
          <Route exact path="/chats" element={<ChatHomePage />} />
          <Route exact path="/premimumplans" element={<PremimumPlans />} />
          <Route exact path="/paymentsuccess" element={<PaymentSuccess />} />
          <Route exact path="/paymentcancel" element={<PaymentCancel />} />
          <Route
            exact
            path="/premimumpayments"
            element={<PremimumPayments />}
          />
          <Route exact path="/setting" element={<Setting />} />
          <Route
            exact
            path="/interestandrequests"
            element={<InterestAndRequests />}
          />
          <Route exact path="/editprofile" element={<EditProfile />} />
          <Route exact path="/editpreferences" element={<EditPreferences />} />
        </Route>

        <Route path="/matrimonial" element={<LandingPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome" element={<Pageone />} />
        <Route path="/suggestion" element={<PageTwo />} />
        <Route exact path="/browsemembership" element={<BrowseMembership />} />
        <Route exact path="/membershippayments" element={<MembershipPay />} />
        <Route
          path="*"
          element={
            <>
              {/* <Header /> */}
              <h3 className="text-center">404 Page Not Found</h3>
            </>
          }
        />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
