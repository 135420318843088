import React, { useState, useRef } from "react"
import { Row, Form, Modal, Col, ProgressBar } from "react-bootstrap"
import { useDispatch } from "react-redux"
import DatePicker from "react-datepicker";
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupStageThree } from "../../redux/actions/authAction"
import moment from "moment";
import {ConvertInchToFeetAndInch, ConvertFeetToInch} from "../../Utils/HeightUnitsConvertUtils"


export default function StageThree({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    gender: "",
    height: "",
    heightF:"",
    heightI:"",
    weight: "",
  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [birthDate, setBirthDate] = useState('');

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    if (fields.gender && fields.heightF && fields.heightI && fields.weight && birthDate) {
      setError("")
      setLoading(true)
      // const timestampSeconds = Math.floor(birthDate.getTime() / 1000);
      let data = {
        dob: birthDate.getTime(),
        gender: fields.gender,
        // hight: fields.height,
        hight:ConvertFeetToInch(fields.heightF,fields.heightI),
        weight: fields.weight,
      }
      dispatch(signupStageThree(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        birthDate: !birthDate,
        gender: !fields.gender,
        // height: !fields.height,
        heightF:!fields.heightF,
        heightI:fields.heightI,
        weight: !fields.weight,
      })
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "weight") {
      setFiels({ ...fields, [name]: value.replace(/[^0-9']/g, "") })
    } else if (
      name === "heightF" ||
      name === "heightI"
    ) {
      if(isNaN(parseInt(value, 10)))
      {
        setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
      }
      else if(name === "heightF"){
        if((parseInt(value, 10)>=1)&& (parseInt(value, 10)<=10))
        {
          setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        }
      }else if(name === "heightI"){
        if((parseInt(value, 10)>=0)&& (parseInt(value, 10)<=11))
        {
          setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        }
      }
    }
    else {
      setFiels({ ...fields, [name]: value })
    }
  }

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-block">
        <ProgressBar now={10} />
      </Modal.Header>
      <Modal.Body className="pt-3 px-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label >Date Of Birth</Form.Label>
            <DatePicker
              selected={birthDate}
              onChange={(date) => setBirthDate(date)}
              // peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Click to select a date"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              minDate={moment().subtract(100, "years")._d}
              maxDate={moment().subtract(18, "years")._d}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Gender</Form.Label>
            <div>
              <Form.Check
                inline
                label="Male"
                name="group1"
                type="radio"
                onClick={() => setFiels({ ...fields, gender: "male" })}
              />
              <Form.Check
                inline
                label="Female"
                name="group1"
                type="radio"
                className="ms-sm-5"
                onClick={() => setFiels({ ...fields, gender: "female" })}
              />
              <Form.Check
                inline
                label="Other"
                name="group1"
                type="radio"
                className="ms-sm-5"
                onClick={() => setFiels({ ...fields, gender: "other" })}
              />
            </div>
          </Form.Group>
          <Row>
            <Col sm={6} className="mt-3">
              <Form.Group>
                <Form.Label className="m-0">Height</Form.Label>
                <Row>
                  <Col md={6}>
                    <Form.Control
                    type="text"
                    placeholder="feet"
                    name="heightF"
                    value={fields.heightF}
                    onChange={(e) => handleChange(e)}
                    required
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Control
                    type="text"
                    placeholder="inch"
                    name="heightI"
                    value={fields.heightI}
                    onChange={(e) => handleChange(e)}
                    required
                    />
                  </Col>
                </Row>                
              </Form.Group>
            </Col>
            <Col sm={6} className="mt-3">
              <Form.Group>
                <Form.Label className="m-0">Weight  (Pound)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Weight (Pound)"
                  name="weight"
                  value={fields.weight}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.gender || !fields.heightF || !fields.heightI || !fields.weight || !birthDate} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
