import React from 'react'
import styles from "./styles.module.css"


export default function ToggleSwitch({ label, name, checked, handleChange }) {
    return (
        <>
            <div className={styles.toggleSwitchMain}>
                <input type="checkbox" className={styles.togglecheckbox}
                    name={name} id={label} checked={checked} onChange={handleChange} />
                <label className={styles.togglelabel} htmlFor={label}>
                    <span className={styles.toggleinner} />
                    <span className={styles.toggleswitch} />
                </label>
            </div>
        </>
    )
}
