import React, { useEffect, useState } from "react"
import { Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import CustomButton from "../../Components/CustomButton"
import {
  signupBodyTypeList,
  signupCountryList,
  signupEducationList,
  signupMaritalList,
  signupMotherTongueList,
} from "../../redux/actions/authAction"
import { matchesFilter } from "../../redux/actions/matchesAction"
import styles from "./styles.module.css"

const Filter = ({ setShowFilterModal, setMatchesList }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Custom Css
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      boxShadow: "none",
      borderBottom: "2px solid #D9D9D9",
      borderRadius: 0,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#EBEBEB",
        borderRadius: "40px",
        padding: "5px 5px 0px",
      }
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#555555",
      padding: "0px 5px 3px",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      backgroundColor: "#D9D9D9",
      borderRadius: "10px",
      width: "20px",
      height: "20px",
      ":hover": {
        backgroundColor: data.color,
      },
    }),
  }

  const [motherTongueData, setMotherTongueData] = useState([])
  const [countryData, setCountryData] = useState([])
  const [stateData, setStateData] = useState([])
  const [maritalData, setMaritalData] = useState([])
  const [bodyType, setBodyTypeData] = useState([])
  const [education, setEducationData] = useState([])

  const [fields, setFiels] = useState({
    MotherTongue: [],
    MaritalStatus: [],
    Country: [],
    State: [],
    BodyType: [],
    InterestedIN: [],
    Education: [],
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(signupCountryList()).then((res) => {
      setCountryData(res?.data?.data)
    })
    dispatch(signupMotherTongueList()).then((res) => {
      setMotherTongueData(res?.data?.data)
    })
    dispatch(signupMaritalList()).then((res) => {
      setMaritalData(res?.data?.data)
    })
    dispatch(signupBodyTypeList()).then((res) => {
      setBodyTypeData(res?.data?.data)
    })
    dispatch(signupEducationList()).then((res) => {
      setEducationData(res?.data?.data)
    })
  }, [])

  async function handleSubmit() {
    try {
      setLoading(true)
      let data = {
        mothertounge: fields?.MotherTongue?.map((data,index) => data.value[index]),
        maritalstatus: fields?.MaritalStatus?.map((data,index) => data.value[index]),
        country: fields?.Country?.map((data,index) => data.value[index]),
        state: fields?.State?.map((data,index) => data.value[index]),
        bodytype: fields?.BodyType?.map((data,index) => data.value[index]),
        interestedin: fields?.InterestedIN?.map((data,index) => data.value[index]),
        education: fields?.Education?.map((data,index) => data.value[index]),
        
      }

      dispatch(matchesFilter(data))
        .then((res) => {
          if (res.status === 200) {
            setMatchesList(res?.data?.data?.list)
            setShowFilterModal(false)
            // console.log(res)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <>
      <Modal.Header
        className="border-0 pb-1 align-items-baseline"
        closeButton
      />
      <Modal.Body className="pt-0 px-4">
        <Row className="align-items-center">
          <Col lg={6} className="mt-5 mt-lg-0">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">Marital&nbsp;Status</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={maritalData?.map((data,index) => ({
                    value: data.marrital_status_name[index],
                    label: data.marrital_status_name[index],
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.MaritalStatus}
                  onChange={(e) => setFiels({ ...fields, MaritalStatus: e })}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="mt-5 mt-lg-0">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">Mother&nbsp;Tongue</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={motherTongueData?.map((data,index) => ({
                    value: data[index],
                    label: data[index],
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.MotherTongue}
                  onChange={(e) => setFiels({ ...fields, MotherTongue: e })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mt-5">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">Country</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={countryData?.map((data,index) => ({
                    value: data.country[index],
                    label: data.country[index],
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.Country}
                  onChange={(e) => {
                    setFiels({ ...fields, Country: e })
                    let states = []
                    e.map((j,index) => {
                      let arr = countryData.find(
                        (item) => item.country === j.value[index]
                      )
                      states.push(...arr.states)
                    })
                    setStateData(states)
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={6} className="mt-5">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">State</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={stateData?.map((data,index) => ({
                    value: data[index],
                    label: data[index],
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.State}
                  onChange={(e) => setFiels({ ...fields, State: e })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mt-5">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">Body type</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={bodyType?.map((data,index) => ({
                    value: data.bodyTypeName[index],
                    label: data.bodyTypeName[index],
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.BodyType}
                  onChange={(e) => setFiels({ ...fields, BodyType: e })}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={6} className="mt-5">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">Interested in</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={[
                    {
                      value: "Boy",
                      label: "Boy",
                    },
                    {
                      value: "Girl",
                      label: "Girl",
                    },
                  ]}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.InterestedIN}
                  onChange={(e) => setFiels({ ...fields, InterestedIN: e })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mt-5">
            <Row className="align-items-center">
              <Col lg={3}>
                <h6 className="fs-17 m-0">Education</h6>
              </Col>
              <Col lg={9}>
                <Select
                  isMulti
                  name="colors"
                  options={education?.map((data,index) => ({
                    value: data.education_name[index],
                    label: data.education_name[index],
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  value={fields.education}
                  onChange={(e) => setFiels({ ...fields, Education: e })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="text-center">
          <button
            className={`${styles.savebutton}`}
            type="button"
            onClick={() => handleSubmit()}
          >
            Save & Continue
          </button>
        </div>
      </Modal.Body>
    </>
  )
}

export default Filter
