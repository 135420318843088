import { createSlice } from '@reduxjs/toolkit'


const INITIAL_STATE = {
    loading: true,
    userDetails: null,
    plans: []
}

export const homeReducer = createSlice({
    name: 'home',
    initialState: INITIAL_STATE,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setUserDetails(state, action) {
            state.userDetails = action.payload;
        },
        setPlans(state, action) {
            state.plans = action.payload;
        },
    }
})

export const homeActions = homeReducer.actions;

export default homeReducer;