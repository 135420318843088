import React, { useEffect, useState } from "react"
import Header from "../../Components/Header"
import { Col, Container, Row, Card } from "react-bootstrap"
import Slider from "rc-slider"
import Select from "react-select"
import { TbChevronLeft } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import styles from "./styles.module.css"
import { useDispatch, useSelector } from "react-redux"
import { signupCountryList, signupMaritalList, signupMotherTongueList, signupStateList } from "../../redux/actions/authAction"
import { editPreferencesDetails, getPreferencesDetails } from "../../redux/actions/editProfileAction"
import {ConvertInchToFeetAndInch} from "../../Utils/HeightUnitsConvertUtils"

export default function EditPreferences() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [ageRange, setAgeRange] = useState([0, 0])
  const [heightRange, setHeightRange] = useState([0, 0])

  // Custom Css
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      boxShadow: "none",
      borderBottom: "2px solid #D9D9D9",
      borderRadius: 0,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#EBEBEB",
        borderRadius: "40px",
        padding: "5px 5px 0px",
      }
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#555555",
      padding: "0px 5px 3px",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      backgroundColor: "#D9D9D9",
      borderRadius: "10px",
      width: "20px",
      height: "20px",
      ":hover": {
        backgroundColor: data.color,
      },
    }),
  }

  const [motherTongueData, setMotherTongueData] = useState([])
  const [countryData, setCountryData] = useState([])
  const [stateData, setStateData] = useState([])
  const [maritalData, setMaritalData] = useState([])

  const [fields, setFiels] = useState({
    MotherTongue: [],
    MaritalStatus: [],
    Country: [],
    State: []
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      dispatch(getPreferencesDetails())
    }, 1000);

    dispatch(signupCountryList()).then((res) => {
      setCountryData(res?.data?.data)
    })
    dispatch(signupMotherTongueList()).then((res) => {
      setMotherTongueData(res?.data?.data)
    })
    dispatch(signupMaritalList()).then((res) => {
      setMaritalData(res?.data?.data)
    })
  }, [])

  const userPreferencesDetails = useSelector(state => state.editProfile.userPreferencesDetails)

  useEffect(() => {
    if (userPreferencesDetails) {
      setAgeRange([userPreferencesDetails.MinAge, userPreferencesDetails.MaxAge])
      setHeightRange([userPreferencesDetails.MinHeight, userPreferencesDetails.MaxHeight])
      setFiels(fields => {
        return ({
          ...fields, MaritalStatus: userPreferencesDetails.MaritalStatus?.map(item => { return ({ value: item, label: item }) }),
          MotherTongue: userPreferencesDetails.MotherTongue?.map(item => { return ({ value: item, label: item }) }),
          Country: userPreferencesDetails.CountryLivingIn?.map(item => { return ({ value: item, label: item }) }),
          State: userPreferencesDetails.StateLivingIn?.map(item => { return ({ value: item, label: item }) })
        })
      })
      let state = []
      userPreferencesDetails.CountryLivingIn?.map(e => {
        let arr = countryData.find(item => item.country === e)
        if (arr) {
          state.push(...arr?.states)
        }
      })
      setStateData(state)
    }
  }, [userPreferencesDetails])


  async function handleSubmit() {
    try {
      setLoading(true)
      let data = {
        "MinAge": ageRange[0],
        "MaxAge": ageRange[1],
        "MinHeight": heightRange[0].toString(),
        "MaxHeight": heightRange[1].toString(),
        "MotherTongue": fields?.MotherTongue?.map(data => data.value),
        "MaritalStatus": fields?.MaritalStatus?.map(data => data.value),
        "CountryLivingIn": fields?.Country?.map(data => data.value),
        "StateLivingIn": fields?.State?.map(data => data.value)
      }
      dispatch(editPreferencesDetails(data)).then((res) => {
        // if (res.status === 200) {
        //   console.log(res)
        //   dispatch(getPreferencesDetails())
        // }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } catch (error) {
      console.log("error", error)
    }
  }


  return (
    <>
      <main>
        <Header />
        <Container>
          <Row className="mt-4 mb-3">
            <Col md={{ span: 9, offset: 1 }} className="cursor-pointer">
              <TbChevronLeft size={20} onClick={() => navigate(-1)} /> &nbsp;
              Edit Preferences
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={`px-2 pt-3 ${styles.othersCard}`}>
                <Row className="align-items-center">
                  <Col lg={3}>
                    <h6 className="fs-17">Age</h6>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-between text-grey mb-2">
                      <h6>From {ageRange[0]} years</h6>
                      <h6>To {ageRange[1]} Years</h6>
                    </div>
                    <Slider
                      range
                      allowCross={false}
                      value={ageRange}
                      min={18}
                      max={100}
                      onChange={(value) => setAgeRange(value)}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                  <Col lg={3}>
                    <h6 className="fs-17">Height</h6>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-between text-grey mb-2">         
                        <h6>
                          From {ConvertInchToFeetAndInch(heightRange[0])[0]}' {ConvertInchToFeetAndInch(heightRange[0])[1]}"
                        </h6>
                        <h6>
                          To {ConvertInchToFeetAndInch(heightRange[1])[0]}' {ConvertInchToFeetAndInch(heightRange[1])[1]}"
                        </h6>
                      {/* <h6>
                        From {heightRange[0]} inch
                      </h6>
                      <h6>
                        To {heightRange[1]} inch
                      </h6> */}
                    </div>
                    <Slider
                      range
                      allowCross={false}
                      value={heightRange}
                      step={1}
                      min={36}
                      max={120}
                      onChange={(value) => setHeightRange(value)}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                  <Col lg={3}>
                    <h6 className="fs-17 m-0">Mother Tongue</h6>
                  </Col>
                  <Col lg={9}>
                    <Select
                      isMulti
                      name="colors"
                      options={motherTongueData?.map((data) => ({ value: data, label: data }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      value={fields.MotherTongue}
                      onChange={(e) => setFiels({ ...fields, MotherTongue: e })}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                  <Col lg={3}>
                    <h6 className="fs-17 m-0">Marital Status</h6>
                  </Col>
                  <Col lg={9}>
                    <Select
                      isMulti
                      name="colors"
                      options={maritalData?.map((data) => ({ value: data.marrital_status_name, label: data.marrital_status_name }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      value={fields.MaritalStatus}
                      onChange={(e) => setFiels({ ...fields, MaritalStatus: e })}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                  <Col lg={3}>
                    <h6 className="fs-17 m-0">Country living in</h6>
                  </Col>
                  <Col lg={9}>
                    <Select
                      isMulti
                      name="colors"
                      options={countryData?.map((data) => ({ value: data.country, label: data.country }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      value={fields.Country}
                      onChange={(e) => {
                        setFiels({ ...fields, Country: e })
                        let states = []
                        e.map(j => {
                          let arr = countryData.find(item => item.country === j.value)
                          states.push(...arr.states)
                        })
                        setStateData(states)
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                  <Col lg={3}>
                    <h6 className="fs-17 m-0">State living in</h6>
                  </Col>
                  <Col lg={9}>
                    <Select
                      isMulti
                      name="colors"
                      options={stateData?.map((data) => ({ value: data, label: data }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      value={fields.State}
                      onChange={(e) => setFiels({ ...fields, State: e })}
                    />
                  </Col>
                </Row>

                <div className="text-center">
                  <button className={`${styles.savebutton}`} type="button" onClick={() => handleSubmit()}>
                    Save & Continue
                  </button>
                </div>

              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}
