import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { Col, Container, Row, Card } from "react-bootstrap";
import styles from "./styles.module.css";
import { BiFilterAlt } from "react-icons/bi";
import { SiWechat } from "react-icons/si";
import { FaChevronRight } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  inboxData,
  acceptRequest,
  sendRequest,
  rejectRequest,
  withdrawRequest,
} from "../../redux/actions/inboxAction";
import Loader from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import { Pagination, PaginationItem } from "@mui/material";
import {ConvertInchToFeetAndInch} from "../../Utils/HeightUnitsConvertUtils"

export default function InboxPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.home.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [inboxList, setinboxList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpages, setTotalpages] = useState(1);
  const [inboxListCategory, setInboxListCategory] = useState("sent_item");

  function getinboxList(category) {
    setIsLoading(true);
    dispatch(inboxData(category, page)).then((res) => {
      setIsLoading(false);
      setinboxList(res?.data?.data?.list);
      setTotalpages(res?.data?.data?.pages);
    });
  }

  useEffect(() => {
    getinboxList(inboxListCategory);
  }, [page]);

  useEffect(() => {
    setinboxList([]);
    getinboxList(inboxListCategory);
  }, [inboxListCategory]);

  return (
    <>
      <Header />
      <div className="position-relative h-100">
        <div
          className="py-2"
          style={{ boxShadow: "0px 3px 12px 2px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="container text-center">
            <div className={`${styles.subheader_scrollbar}`}>
              <button
                className={`${styles.subHeaderBtn}`}
                onClick={() => {
                  setInboxListCategory("received");
                  setPage(1);
                }}
                style={
                  inboxListCategory === "received"
                    ? {
                        backgroundColor: "#F0003B",
                        color: "#FFFFFF",
                      }
                    : {}
                }
              >
                Received
              </button>
              <button
                className={`${styles.subHeaderBtn}`}
                onClick={() => {
                  setInboxListCategory("friend_list");
                  setPage(1);
                }}
                style={{
                  backgroundColor:
                    inboxListCategory === "friend_list" ? "#F0003B" : "#ffffff",
                  color:
                    inboxListCategory === "friend_list" ? "#FFFFFF" : "#000000",
                }}
              >
                Accepted
              </button>
              <button
                className={`${styles.subHeaderBtn}`}
                onClick={() => {
                  setInboxListCategory("sent_item");
                  setPage(1);
                }}
                style={{
                  backgroundColor:
                    inboxListCategory === "sent_item" ? "#F0003B" : "#ffffff",
                  color:
                    inboxListCategory === "sent_item" ? "#FFFFFF" : "#000000",
                }}
              >
                Sent&nbsp;Item
              </button>
              {/* <button
              className={`${styles.subHeaderBtn}`}
              onClick={() => setInboxListCategory("requested")}
              style={{
                backgroundColor:
                  inboxListCategory === "requested" ? "#F0003B" : "#ffffff",
                color:
                  inboxListCategory === "requested" ? "#FFFFFF" : "#000000",
              }}
            >
              Requests
            </button>
            <button
              className={`${styles.subHeaderBtn}`}
              onClick={() => setInboxListCategory("contact_viewed")}
              style={{
                backgroundColor:
                  inboxListCategory === "contact_viewed"
                    ? "#F0003B"
                    : "#ffffff",
                color:
                  inboxListCategory === "contact_viewed"
                    ? "#FFFFFF"
                    : "#000000",
              }}
            >
              Contacts&nbsp;Viewed
            </button> */}
              {/* <button
                className={`${styles.subHeaderBtn}`}
                onClick={() => {
                  setInboxListCategory("friend_list");
                  setPage(1);
                }}
                style={{
                  backgroundColor:
                    inboxListCategory === "friend_list" ? "#F0003B" : "#ffffff",
                  color:
                    inboxListCategory === "friend_list" ? "#FFFFFF" : "#000000",
                }}
              >
                All Connections
              </button> */}
            </div>
          </div>
        </div>

        <Container>
          <Row>
            {isLoading ? (
              <Loader />
            ) : inboxList?.length > 0 ? (
              <>
                {inboxList?.map((item,index) => {
                  return (
                    <Col lg={4} md={6} sm={12} className="my-3" key={index}>
                      <div
                        className="position-relative  m-auto"
                        style={{ borderRadius: 20, width: "85%" }}
                        // onClick={(e) => {
                        //   e.preventDefault()
                        //   navigate("/viewprofile", { state: item.user_id })
                        // }}
                      >
                        <div
                          className="cursor-pointer"
                          style={{
                            borderRadius: 20,
                            overflow: "hidden",
                            boxShadow: "rgb(0 0 0 / 24%) 1px 3px 5px 0px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/viewprofile", { state: item.user_id });
                          }}
                        >
                          <img
                            className="card-img-top"
                            src={
                              item?.user_details?.user_profile_pic ||
                              "/Assest/image/girl-4.png"
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/Assest/image/girl-2.png";
                            }}
                            alt="."
                            height={385}
                          />
                        </div>
                        <div
                          className={`${styles.matchesCardText} text-white w-100 p-3`}
                        >
                          <h6 className="m-0">
                            {item?.user_details?.user_name}{" "}
                          </h6>
                          <small className="card-text">
                            {item?.user_details?.age &&
                            item?.user_details?.age !== 0
                              ? item?.user_details?.age + " yrs"
                              : ""}

                            {item?.user_details?.height &&
                              (item?.user_details?.height !== 0
                                ? ", " + `${ConvertInchToFeetAndInch(item?.user_details?.height)[0]}'${ConvertInchToFeetAndInch(item?.user_details?.height)[1]}"`
                                : `0'0"`)}

                            {item?.user_details?.education &&
                              ((item?.user_details?.age &&
                                item?.user_details?.age !== 0) ||
                              (item?.user_details?.height &&
                                item?.user_details?.height !== 0) ? (
                                <>
                                  <GoDotFill className="mb-1" />{" "}
                                  {item?.user_details?.education}
                                </>
                              ) : (
                                <>{item?.user_details?.education}</>
                              ))}
                          </small>
                          <br />
                          <small className="card-text">
                            {item?.user_details?.mother_toungue &&
                              item?.user_details?.mother_toungue + " "}
                            {item?.user_details?.resident_city && (
                              <>
                                {item?.user_details?.mother_toungue && (
                                  <GoDotFill className="mb-1 me-1" />
                                )}
                                {item?.user_details?.resident_city}
                              </>
                            )}
                            {item?.user_details?.country}
                          </small>
                          {inboxListCategory === "received" ? (
                            <div className="d-flex justify-content-between mt-2">
                              {inboxListCategory === "received" ? (
                                <>
                                  <div
                                    className="p-2 rounded-circle border cursor-pointer"
                                    onClick={() => {
                                      dispatch(
                                        rejectRequest(item?.user_id)
                                      ).then(() => {
                                        getinboxList(inboxListCategory);
                                      });
                                    }}
                                  >
                                    <VscClose size={23} />
                                  </div>
                                  <div className="w-100 text-center">
                                    <button
                                      className={styles.acceptbtn}
                                      onClick={() =>
                                        dispatch(
                                          acceptRequest(item?.user_id)
                                        ).then(() => {
                                          getinboxList(inboxListCategory);
                                        })
                                      }
                                    >
                                      Accept
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="p-2 rounded-circle border cursor-pointer"
                                    onClick={() => {
                                      dispatch(
                                        withdrawRequest(item?.user_id)
                                      ).then(() => {
                                        // toast.success("Request Withdraw Successfully")
                                        getinboxList(inboxListCategory);
                                      });
                                    }}
                                  >
                                    <VscClose size={23} />
                                  </div>
                                  <div className="w-100 text-center">
                                    <button
                                      className={styles.sendbtn}
                                      // onClick={() =>
                                      //   dispatch(sendRequest(item?.user_id)).then(() => {
                                      //     getinboxList(inboxListCategory)
                                      //   })
                                      // }
                                    >
                                      Request&nbsp;Sent
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              <div
                                className="my-2"
                                style={{
                                  border: "1px solid #C7C7C7",
                                  opacity: 0.6,
                                }}
                              ></div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  {userDetails?.user_detail?.plan_details?.isActive ?
                                    (
                                      <div></div>
                                    ) :
                                    (
                                      <small>
                                      To Contact directly, <br />
                                      <span
                                        style={{
                                          color: "#FF648A",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => navigate("/premimumplans")}
                                      >
                                        Upgrade Now
                                      </span>
                                    </small>
                                    )
                                  }
                                 
                                </div>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="p-2 rounded-circle cursor-pointer"
                                    style={{
                                      background:
                                        "linear-gradient(180deg, #FF648A 0%, #F0003B 100%)",
                                    }}
                                    onClick={() =>
                                      navigate("/chats", { state: item })
                                    }
                                  >
                                    <SiWechat size={25} />
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate("/viewprofile", {
                                        state: item.user_id,
                                      });
                                    }}
                                  >
                                    <FaChevronRight
                                      size={15}
                                      className="fw-bold ms-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
                {/* {inboxList?.length > 3 &&
                <Row className="position-sticky bottom-0 mb-3 w-100 m-0 p-0">
                  <Col className='d-flex justify-content-center m-0 p-0'>
                    <Pagination
                      count={totalpages}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={(event, value) => setPage(value)}
                    />
                  </Col>
                </Row>
              } */}
              </>
            ) : (
              <>
                <Col sm={12}>
                  <h4 className="text-center mt-5">
                    {inboxListCategory === "received"
                      ? `You haven't received any request.`
                      : inboxListCategory === "sent_item"
                      ? "You haven't sent any invitation yet."
                      : "No connection found."}
                  </h4>
                </Col>
              </>
            )}
          </Row>
        </Container>
        {inboxList?.length > 0 && totalpages > 1 && (
          <Row className="position-sticky bottom-0 mb-3 w-100 m-0 p-0">
            <Col className="d-flex justify-content-center m-0 p-0">
              <Pagination
                count={totalpages}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={(event, value) => setPage(value)}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
