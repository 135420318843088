import React, { useEffect, useState } from "react"
import { Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../../Components/CustomButton"
import {
  signupAccountList,
  signupBodyTypeList,
  signupCountryList,
  signupEducationList,
  signupExperienceList,
  signupMaritalList,
  signupStateList,
} from "../../../redux/actions/authAction"
import {
  editPersonalDetails,
  getMyProfileData,
} from "../../../redux/actions/editProfileAction"

import {ConvertInchToFeetAndInch, ConvertFeetToInch} from "../../../Utils/HeightUnitsConvertUtils"

const AboutMe = ({ setModalShow, myProfileData }) => {
  const dispatch = useDispatch()

  const [accountData, setAccountData] = useState([])
  const [countryData, setCountryData] = useState([])
  const [educationData, setEducationData] = useState([])
  const [stateData, setStateData] = useState([])
  const [homeStateData, setHomeStateData] = useState([])
  const [experianceData, setExperianceData] = useState([])
  const [bodyTypeData, setBodyTypeData] = useState([])
  const [maritalData, setMaritalData] = useState([])

  useEffect(() => {
    dispatch(signupAccountList()).then((res) => {
      setAccountData(res?.data?.data)
    })
    dispatch(signupCountryList()).then((res) => {
      setCountryData(res?.data?.data)
      let arr = res?.data?.data.find(item => item.country === fields.Resident_Country)
      setStateData(arr.states)

      let arr2 = res?.data?.data.find(item => item.country === fields.Home_Country)
      setHomeStateData(arr2.states)
    })
    dispatch(signupEducationList()).then((res) => {
      setEducationData(res?.data?.data)
    })
    dispatch(signupExperienceList()).then((res) => {
      setExperianceData(res?.data?.data)
    })
    dispatch(signupBodyTypeList()).then((res) => {
      setBodyTypeData(res?.data?.data)
    })
    dispatch(signupMaritalList()).then((res) => {
      setMaritalData(res?.data?.data)
    })
  }, [])

  const [fields, setFiels] = useState({
    FirstName: myProfileData?.PersonalDetail?.FirstName || "",
    LastName: myProfileData?.PersonalDetail?.LastName || "",
    ProfileCreatedBy: myProfileData?.AccountType || "",
    Gender: myProfileData?.PersonalDetail?.Gender || "",
    // Hight: myProfileData?.PersonalDetail?.Hight || "",
    HightF: `${ConvertInchToFeetAndInch(myProfileData?.PersonalDetail?.Hight)[0]}` || "",
    HightI: `${ConvertInchToFeetAndInch(myProfileData?.PersonalDetail?.Hight)[1]}` || "",
    Weight: myProfileData?.PersonalDetail?.Weight || "",
    Resident_Country: myProfileData?.PersonalDetail?.Resident_Country || "",
    Resident_State: myProfileData?.PersonalDetail?.Resident_State || "",
    Resident_City: myProfileData?.PersonalDetail?.Resident_City || "",
    Home_Country: myProfileData?.PersonalDetail?.Home_Country || "",
    Home_State: myProfileData?.PersonalDetail?.Home_State || "",
    Home_City: myProfileData?.PersonalDetail?.Home_City || "",
    ResidentialStatus: myProfileData?.PersonalDetail?.ResidentialStatus || "",
    Education: myProfileData?.PersonalDetail?.Education || "",
    WorkExperience: myProfileData?.PersonalDetail?.WorkExperience || "",
    Designation: myProfileData?.PersonalDetail?.Occupation || "",
    AnnualIncome: myProfileData?.PersonalDetail?.AnnualIncome || "",
    MaritalStatus: myProfileData?.PersonalDetail?.MaritalStatus || "",
    MotherTongue: myProfileData?.PersonalDetail?.MotherTongue || "",
    InterestedIn: myProfileData?.PersonalDetail?.InterestedIn || "",
    BodyType: myProfileData?.PersonalDetail?.BodyType || "",
    PassionsorHobbies: myProfileData?.PersonalDetail?.PassionsorHobbies || "",
    Habits: myProfileData?.PersonalDetail?.Habits || "",
    AboutYourself: myProfileData?.PersonalDetail?.AboutYourself || "",
  })

  const [others, setOthers] = useState({
    MotherTongue: false,
    WorkExperience: false,
    Education: false,
    InterestedIn: false,
    BodyType: false,
    MaritalStatus: false,
    ProfileCreatedBy: false,
  })

  const [loading, setLoading] = useState(false)

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    // FatherName: fields.fathersname
    let data = {
      FirstName: fields.FirstName,
      LastName: fields.LastName,
      ProfileCreatedBy: fields.ProfileCreatedBy,
      Gender: fields.Gender,
      // Hight: fields.Hight,
      Hight: ConvertFeetToInch(fields.HightF,fields.HightI),
      Weight: fields.Weight,
      Resident_Country: fields.Resident_Country,
      Resident_State: fields.Resident_State,
      Resident_City: fields.Resident_City,
      Home_Country: fields.Home_Country,
      Home_State: fields.Home_State,
      Home_City: fields.Home_City,
      ResidentialStatus: fields.ResidentialStatus,
      Education: fields.Education,
      WorkExperience: fields.WorkExperience,
      Designation: fields.Designation,
      AnnualIncome: fields.AnnualIncome,
      MaritalStatus: fields.MaritalStatus,
      MotherTongue: fields.MotherTongue,
      InterestedIn: fields.InterestedIn,
      BodyType: fields.BodyType,
      PassionsorHobbies: fields.PassionsorHobbies,
      Habits: fields.Habits,
      AboutYourself: fields.AboutYourself,
    }
    dispatch(editPersonalDetails(data))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getMyProfileData())
          setModalShow(false)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (value === "Other") {
      setOthers({ ...others, [name]: true })
    } else if (
      name === "Weight" ||
      name === "AnnualIncome"
    ) {
      setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
    } else if (
      name === "HightF" ||
      name === "HightI"
    ) {
      if(isNaN(parseInt(value, 10)))
      {
        setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
      }
      else if(name === "HightF"){
        if((parseInt(value)>=3) && (parseInt(value, 10)<=13))
        {
          setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        }
      }else if(name === "HightI"){
        if((parseInt(value, 10)>=0)&& (parseInt(value, 10)<=11))
        {
          setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        }
      }
    }else {
      setFiels({ ...fields, [name]: value })
    }
  }
  return (
    <>
      <Modal.Header
        className="border-0 pb-1 align-items-baseline"
        closeButton
      />
      <Modal.Body className="pt-0 px-4">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="m-0">First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="FirstName"
                  placeholder="First Name"
                  value={fields.FirstName}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="LastName"
                  placeholder="Last Name"
                  value={fields.LastName}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Profile Created By</Form.Label>
                {others.ProfileCreatedBy ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Your work experience"
                      name="ProfileCreatedBy"
                      value={fields.ProfileCreatedBy}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : (
                  <Form.Select
                    name="ProfileCreatedBy"
                    value={fields.ProfileCreatedBy}
                    className={
                      fields.ProfileCreatedBy === "" && "selectplaceholder"
                    }
                    onChange={(e) => handleChange(e)}
                    defaultValue="Select One"
                  >
                    <option value=""  disabled hidden>
                      
                    </option>
                    {accountData.map((data,index) => {
                      return (
                        <option key={index} value={data.type}>
                          {data.type}{" "}
                          {data.type === "Client" && "(Marriage Bureau)"}
                        </option>
                      )
                    })}
                  </Form.Select>
                )}
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Mother Tongue</Form.Label>
                {others.MotherTongue ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="English"
                      name="MotherTongue"
                      value={fields.MotherTongue}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : (
                  <>
                    <Form.Select
                      name="MotherTongue"
                      value={fields.MotherTongue}
                      className={
                        fields.MotherTongue === "" && "selectplaceholder"
                      }
                      onChange={(e) => handleChange(e)}
                      defaultValue="Select"
                    >
                      <option value=""  disabled hidden>
                        
                      </option>
                      <option value="Hindi">Hindi</option>
                      <option value="Gujarati">Gujarati</option>
                      <option value="English">English</option>
                      {/* <option value="Other">Other</option> */}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Height</Form.Label>
                <Row>
                <Col md={6}>
                {/* <Form.Control
                  type="text"
                  name="HightF"
                  placeholder="Hight (feet)"
                  value={fields.HightF}
                  onChange={(e) => handleChange(e)}
                />   */}
                <Form.Select
                 name="HightF"
                 value={fields.HightF}
                 onChange={(e) => handleChange(e)}
                >
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                </Form.Select>
                </Col>
                <Col md={6}>
                {/* <Form.Control
                  type="text"
                  name="HightI"
                  placeholder="Hight (inch)"
                  value={fields.HightI}
                  onChange={(e) => handleChange(e)}
                /> */}
                  <Form.Select
                 name="HightI"
                 value={fields.HightI}
                 onChange={(e) => handleChange(e)}
                >
                  <option value='0'>0</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                
                </Form.Select>
                </Col>
                </Row>
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Weight</Form.Label>
                <Form.Control
                  type="text"
                  name="Weight"
                  placeholder="Weight (Pound)"
                  value={fields.Weight}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Gender</Form.Label>
                <Form.Select
                  name="Gender"
                  value={fields.Gender}
                  className={fields.Gender === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                  defaultValue="Select"
                >
                  <option value=""  disabled hidden>
                    
                  </option>
                  <option value="male">male</option>
                  <option value="female">female</option>
                </Form.Select>
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Education</Form.Label>
                {others.Education ?
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Write your Education"
                      name="Education"
                      value={fields.Education}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </>
                  :
                  <Form.Select
                    name="Education"
                    value={fields.Education}
                    className={fields.Education === "" && "selectplaceholder"}
                    onChange={(e) => handleChange(e)}
                    defaultValue=" Your Education"
                  >
                    <option value=""  disabled hidden>
                     
                    </option>
                    {
                      educationData.map((e,index) => (
                        <>
                          <option key={index} value={e.education_name}>{e.education_name}</option>
                        </>
                      ))
                    }
                    <option value="Other">Other</option>
                  </Form.Select>
                }
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Resident Country</Form.Label>
                <Form.Select
                  name="Resident_Country"
                  value={fields.Resident_Country}
                  className={fields.Resident_Country === "" && "selectplaceholder"}
                  onChange={(e) => {
                    handleChange(e)
                    let arr = countryData.find(item => item.country === e.target.value)
                    setStateData(arr.states)
                  }}
                  defaultValue="Country"
                >
                  <option value=""  disabled hidden>
                    
                  </option>
                  {countryData.map((a,index) => (
                    <option key={index} value={a.country}>{a.country}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Resident State</Form.Label>
                <Form.Select
                  name="Resident_State"
                  value={fields.Resident_State}
                  className={fields.Resident_State === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                  defaultValue="State"
                >
                  <option value=""  disabled hidden>
                    
                  </option>
                  {stateData.map((name,index) => (
                    <option key={index} value={name}>{name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Resident City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  name="Resident_City"
                  value={fields.Resident_City}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label className="m-0">Home Country</Form.Label>
                <Form.Select
                  name="Home_Country"
                  value={fields.Home_Country}
                  className={fields.Home_Country === "" && "selectplaceholder"}
                  onChange={(e) => {
                    handleChange(e)
                    let arr = countryData.find(item => item.country === e.target.value)
                    setHomeStateData(arr.states)

                  }}
                  defaultValue="Country"
                >
                  <option value=""  disabled hidden>
                    
                  </option>
                  {countryData.map((a,index) => (
                    <option key={index} value={a.country}>{a.country}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Home State</Form.Label>
                <Form.Select
                  name="Home_State"
                  value={fields.Home_State}
                  className={fields.Home_State === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                  defaultValue="State"
                >
                  <option value=""  disabled hidden>
                    
                  </option>
                  {homeStateData.map((name,index) => (
                    <option key={index} value={name}>{name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Home City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  name="Home_City"
                  value={fields.Home_City}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Occupation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation"
                  name="Designation"
                  value={fields.Designation}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Annual Income</Form.Label>
                <Form.Control
                  type="text"
                  name="AnnualIncome"
                  placeholder="$000.00"
                  value={fields.AnnualIncome}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Work Experience</Form.Label>
                {others.WorkExperience ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Your work experience"
                      name="WorkExperience"
                      value={fields.WorkExperience}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : (
                  <>
                    <Form.Select
                      name="WorkExperience"
                      value={fields.WorkExperience}
                      className={
                        fields.WorkExperience === "" && "selectplaceholder"
                      }
                      onChange={(e) => handleChange(e)}
                      defaultValue=" Your work experience"
                    >
                      <option value=""  disabled hidden>
                       
                      </option>
                      {experianceData.map((e,index) => (
                        <>
                          <option key={index} value={e.work_experiance_year}>
                            {e.work_experiance_year}
                          </option>
                        </>
                      ))}
                      {/* <option value="Other">Other</option> */}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Interested In</Form.Label>
                {others.InterestedIn ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="InterestedIn"
                      value={fields.InterestedIn}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </>
                ) : (
                  <>
                    <Form.Select
                      name="InterestedIn"
                      value={fields.InterestedIn}
                      className={
                        fields.InterestedIn === "" && "selectplaceholder"
                      }
                      onChange={(e) => handleChange(e)}
                      defaultValue="Boy"
                    >
                      <option value=""  disabled hidden>
                        
                      </option>
                      <option value="boy">Boy</option>
                      <option value="girl">Girl</option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </>
                )}
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Marital Status</Form.Label>
                {others.MaritalStatus ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Marital Status"
                      name="MaritalStatus"
                      value={fields.MaritalStatus}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </>
                ) : (
                  <>
                    <Form.Select
                      name="MaritalStatus"
                      value={fields.MaritalStatus}
                      className={
                        fields.MaritalStatus === "" && "selectplaceholder"
                      }
                      onChange={(e) => handleChange(e)}
                      defaultValue="Marital Status"
                    >
                      <option value=""  disabled hidden>
                        
                      </option>
                      {maritalData.map((data,index) => (
                        <>
                          <option key={index} value={data.marrital_status_name}>
                            {data.marrital_status_name}
                          </option>
                        </>
                      ))}
                      {/* <option value="Other">Other</option> */}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Body Type</Form.Label>
                {others.BodyType ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Body Type"
                      name="BodyType"
                      value={fields.BodyType}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </>
                ) : (
                  <>
                    <Form.Select
                      name="BodyType"
                      value={fields.BodyType}
                      className={fields.BodyType === "" && "selectplaceholder"}
                      onChange={(e) => handleChange(e)}
                      defaultValue="Body Type"
                    >
                      <option value=""  disabled hidden>
                        
                      </option>
                      {bodyTypeData.map((data,index) => (
                        <>
                          <option key={index} value={data.bodyTypeName}>
                            {data.bodyTypeName}
                          </option>
                        </>
                      ))}
                      <option value="Other">Other</option>
                    </Form.Select>
                  </>
                )}
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Passions/Hobbies</Form.Label>
                <Form.Control
                  type="text"
                  name="PassionsorHobbies"
                  placeholder="Write your Passions or Hobbies"
                  value={fields.PassionsorHobbies}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />

              <Form.Group>
                <Form.Label className="m-0">Habits</Form.Label>
                <Form.Control
                  type="text"
                  name="Habits"
                  placeholder="Write your Habits"
                  value={fields.Habits}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />
            </Col>
          </Row>

          <Row>
            <Form.Group>
              <Form.Label className="m-0">Residential Status</Form.Label>
              <Form.Control
                type="text"
                placeholder="Residential Status"
                name="ResidentialStatus"
                value={fields.ResidentialStatus}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Row>
          <br />

          <Form.Group>
            <Form.Label
              className="m-0 mb-1 fw-semibold"
              style={{ fontSize: "15px" }}
            >
              About Yourself
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Write somthing about yourself..."
              name="AboutYourself"
              value={fields.AboutYourself}
              onChange={(e) => handleChange(e)}
              style={{ fontSize: "13px" }}
              maxLength={4000}
            />
            <small className="d-block text-end mt-2">
              <span className="theme-color">{fields.AboutYourself.length}</span>
              (4000 max)
            </small>
          </Form.Group>

          <Form.Group className="text-center my-5">
            <CustomButton btntext="Save" loading={loading} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}

export default AboutMe
