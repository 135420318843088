import React, { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { sendImage } from "../../../redux/actions/inboxAction"
import styles from "../styles.module.css"

const ChatMessagesFooter = (props) => {
  const { socket, activeChatBoxPersonDetail, firstMessUser } = props

  const dispatch = useDispatch()

  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const sendMessage = async (e) => {
    e?.preventDefault()   
    if (message && (activeChatBoxPersonDetail || firstMessUser)) {
      console.log('message sent...')
      let data = {
        event: "sendMessage",
        data: {
          receiver: activeChatBoxPersonDetail?.UserId || firstMessUser?.user_id,
          text: message,
        },
      }
      await socket.emit("request", data)
      setMessage("")
      // await getSocketResponse()
    }
  }

  const sendImageFile = async (file) => {
    if (file?.length > 0 && (activeChatBoxPersonDetail || firstMessUser)) {
      let data = {
        event: "sendMessage",
        data: {
          receiver: activeChatBoxPersonDetail?.UserId || firstMessUser?.user_id,
          files: file || []
        },
      }
      await socket.emit("request", data)
      setMessage("")
      // await getSocketResponse()
    }
  }

  function handleFileUpload(e) {
    let files = e.target.files

    if (files.length > 0) {
      setIsLoading(true)
      dispatch(sendImage(e.target.files)).then((res) => {
        if (res?.status === 200) {
          sendImageFile(res.data.data)
        }
        setIsLoading(false)
      })
    }
  }

  return (
    <div>
      <footer className={`${styles.messageFooter}`}>
        <div className="d-flex align-items-center py-2">
          <div>
            <div className="custom-file">
              <input type="file" className="custom-file-input d-none" id="inputGroupFile01" multiple accept="image/*" onChange={(e) => handleFileUpload(e)} />
              {isLoading ?
                <div className="spinner-grow spinner-grow-sm text-danger mx-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <label className="custom-file-label text-grey cursor-pointer" htmlFor="inputGroupFile01">
                  <img
                    src="/Assest/image/Vector.png"
                    alt="."
                    width="20px"
                    height="23px"
                    className="mx-3 mb-1"
                  />
                </label>
              }
            </div>
            {/* <img
              src="/Assest/image/Vector.png"
              alt="."
              width="20px"
              height="23px"
              classNameName="mx-3 mb-1"
              onClick={() => setModalShow(true)}
            /> */}
          </div>
          <Form onSubmit={sendMessage} className="flex-fill">
            <Form.Group>
              <div>
                <input
                  type="text"
                  className="border-0 rounded-3 w-100 p-2"
                  placeholder="Write something.."
                  rows="1"
                  style={{ maxHeight: "150px" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                // onKeyDown={(e) => inputfocus(e)}
                />
              </div>
            </Form.Group>
          </Form>
          <div>
            <img
              src="/Assest/image/send.png"
              alt="."
              width="27px"
              height="26px"
              className="mx-3 mb-1"
              onClick={sendMessage}
            />
          </div>
        </div>
      </footer>
    </div>
  )
}

export default memo(ChatMessagesFooter)
