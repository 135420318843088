import React, { useState, useEffect } from "react"
import { Form, Modal, ProgressBar } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupMaritalList, signupMotherTongueList, signupStageSix } from "../../redux/actions/authAction"


export default function StageSix({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    maritalstatus: "",
    mothertongue: '',
    religion: '',
    horoscope: '',
  })
  const [others, setOthers] = useState({
    maritalstatus: false,
    mothertongue: false,
    religion: false,
  })
  const [horoscope, setHoroscope] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [maritalData, setMaritalData] = useState([])
  const [motherTongueData, setMotherTongueData] = useState([])

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()
    if (fields.maritalstatus) {
      setError("")
      setLoading(true)
      let data = {
        maritalstatus: fields.maritalstatus,
        mothertongue: fields.mothertongue,
        // religion: fields.religion,
        horoscope: fields.horoscope,
      }
      dispatch(signupStageSix(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        maritalstatus: !fields.maritalstatus,
        mothertongue: !fields.mothertongue,
        // religion: !fields.religion,
        horoscope: !fields.horoscope,
      })
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (value === "Other") {
      setOthers({ ...others, [name]: true })
    } else if (name === "horoscope" && value === "Yes") {
      setHoroscope(true)
    } else {
      if (name === "horoscope") {
        setHoroscope(false)
      }
      setFiels({ ...fields, [name]: value })
    }
  }


  const handleChangeOther = (e) => {
    const { name, value } = e.target

    setFiels({ ...fields, [name]: value })
  }

  useEffect(() => {
    dispatch(signupMaritalList()).then((res) => {
      setMaritalData(res?.data?.data)
    })
    dispatch(signupMotherTongueList()).then((res) => {
      setMotherTongueData(res?.data?.data)
    })
  }, [])

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-block">
        <ProgressBar now={70} />
      </Modal.Header>
      <Modal.Body className="pt-3 px-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="m-0">Marital Status</Form.Label>
            {others.maritalstatus ?
              <>
                <Form.Control
                  type="text"
                  placeholder="Single"
                  name="maritalstatus"
                  value={fields.maritalstatus}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </> : <>
                <Form.Select
                  name="maritalstatus"
                  className={fields.maritalstatus === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Single
                  </option>
                  {
                    maritalData?.map((data) => (
                      <>
                        <option key={data.id} value={data.marrital_status_name}>{data.marrital_status_name}</option>
                      </>
                    ))
                  }
                  {/* <option value="Other">Other</option> */}
                </Form.Select>
              </>}
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Mother Tongue</Form.Label>
            {others.mothertongue ?
              <>
                <Form.Control
                  type="text"
                  placeholder="English"
                  name="mothertongue"
                  value={fields.mothertongue}
                  onChange={(e) => handleChange(e)}
                />
              </> : <>
                <Form.Select
                  name="mothertongue"
                  className={fields.mothertongue === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    English
                  </option>
                  {motherTongueData?.map((language) => (
                    <option key={language.id} value={language}> {language}</option>
                  ))
                  }
                  {/* <option value="Other">Other</option> */}
                </Form.Select>
              </>}
          </Form.Group>
          <br />
          {/* <Form.Group>
            <Form.Label className="m-0">Religion</Form.Label>
            {others.religion ?
              <>
                <Form.Control
                  type="text"
                  placeholder="Religion"
                  name="religion"
                  value={fields.religion}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </> : <>
                <Form.Select
                  name="religion"
                  className={fields.religion === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Religion
                  </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </>}
          </Form.Group>
          <br /> */}
          {/* <Form.Group>
            <Form.Label className="m-0">Horoscope Must for Marriage? (Optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Horoscope Must for Marriage?"
              name="horoscope"
              value={fields.horoscope}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group> */}

          <Form.Group>
            <Form.Label className="m-0">Horoscope Must for Marriage? (Optional)</Form.Label>
            <Form.Select
              name="horoscope"
              className={fields.horoscope === "" && "selectplaceholder"}
              onChange={(e) => handleChange(e)}
            >
              <option value="" selected disabled hidden>
                Horoscope Must for Marriage?
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Select>
            {horoscope &&
              <>
                <Form.Control
                  type="text"
                  className="mt-2"
                  placeholder="What is Your Horoscope"
                  name="horoscope"
                  value={fields.horoscope}
                  onChange={(e) => handleChangeOther(e)}
                  required
                />
              </>
            }
          </Form.Group>



          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.maritalstatus} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
