import React, { useState } from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AiFillPlusCircle, AiOutlineCamera } from "react-icons/ai";
import styles from "./styles.module.css";
import UpdateProfile from "../../Components/UpdateProfile";
import { signupAddPhoto } from "../../redux/actions/authAction"


const StageFifteen = () => {
  const dispatch = useDispatch()

  const [modalShow, setModalShow] = useState(false);
  const [imgCrop, setImgCrop] = useState(false);
  const [profileImg, setProfileImg] = useState([]);
  const [Imgdetail, setImageDetail] = useState({});

  const onClose = () => {
    setImgCrop(null);
    setModalShow(false);
  };

  const onCrop = (view) => {
    setImgCrop(view);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const handlePhoto = (e) => {
    e.preventDefault();
    setProfileImg([...profileImg.splice(1), { imgCrop }]);
    setModalShow(false);
  };

  const profileImageShow = profileImg.map((item) => item.imgCrop);

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  async function handleSubmit() {
    if (profileImageShow.length > 0 && profileImageShow[0] !== false) {
      setError("")
      setLoading(true)
      var data = {
        img: profileImageShow[0],
        Imgdetail: Imgdetail
      }
      dispatch(signupAddPhoto(data)).then((res) => {
        if (res?.status === 200) {
          navigate("/welcome", { replace: true })
        } else {
          setError("Issue with Uploading the Image please try again later")
        }
        setLoading(false)
      }).catch(() => {
        setError("Issue with Uploading the Image please try again later")
        setLoading(false)
      })
    } else {
      setError("Please Select a Picture")
    }
  }

  return (
    <>
      <Modal.Body className="p-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={{ span: 12 }} className="">
              <div className="d-flex justify-content-center">
                <div style={{ width: "fit-content" }}>
                  <div
                    className={styles.userProfileImage}
                    onClick={() => setModalShow(true)}
                  >
                    <img
                      src={
                        profileImageShow.length > 0
                          ? profileImageShow
                          : "/Assest/image/homepage-userphoto.png"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/Assest/image/homepage-userphoto.png";
                      }}
                      alt="profile"
                    />
                    <AiFillPlusCircle size={32} />
                  </div>
                </div>
              </div>
              <div className="text-center text-black my-5">
                <h4 className="fw-semibold" style={{ fontSize: 18 }}>
                  Add Your Photos to Complete your Profile
                </h4>
                <h6 style={{ fontSize: 15, color: "#555555" }} className="mt-3">
                  Photo Privacy Controls Available in Settings
                </h6>
              </div>
              <div className="d-flex justify-content-center">
                {profileImageShow.length > 0 && profileImageShow[0] !== false ?
                  <button className={`${styles.addPhotoBtn}`} onClick={handleSubmit}>
                    <div>
                      <h5>Continue</h5>
                    </div>
                  </button>
                  :
                  <button className={`${styles.addPhotoBtn}`} onClick={() => setModalShow(true)}>
                    <div>
                      <AiOutlineCamera size={28} className="me-3" />
                      Add a photo
                    </div>
                  </button>
                }
                {/* <div className={`${styles.addGalleryBtnText}`}>
                    <GrGallery size={21} className="me-3" />
                    Add From Gallery
                  </div> */}
              </div>
              {/* <div className="d-flex justify-content-center">
                <button className={`${styles.addtakePhotoBtn} mx-5`}>
                  <AiOutlineCamera size={28} className="me-3" />
                  Take a photo
                </button>
              </div> */}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <UpdateProfile
        showModal={modalShow}
        onCrop={onCrop}
        onClose={onClose}
        handleSubmit={handlePhoto}
        handleClose={handleClose}
        setImageDetail={setImageDetail}
      />
    </>
  );
};

export default StageFifteen;
