import React, { useState } from "react";
import styles from "../styles.module.css";
import moment from "moment";
import ChatPhotoViewer from "./ChatPhotoViewer";

export default function Messages({ messageList, senderId }) {

  const [modalShow, setModalShow] = useState(false);
  const [viewImag, setViewImage] = useState('')

  const handleClose = () => {
    setModalShow(false);
    // await setViewImage('')
  };

  const handleImageShow = (file) => {
    setViewImage(file)
    setModalShow(true);
  }

  return (
    <>
      <div className={`${styles.chatMessages} flex-fill`}>
        {messageList?.map(mess => {
          const mDate = moment(mess.createdAt).format("MM/DD/YYYY").toString()
          const cDate = moment(new Date().toString()).format("MM/DD/YYYY").toString()
          return (
            <>
              {(mess?.sender?.sender === senderId || mess?.sender === senderId) ?
                <div className="my-1">
                  <div className={`${styles.senderdiv}`}>
                    {mess.files?.length > 0 ?
                      mess.files?.map(file => {
                        return (
                          <>
                            <img
                              className="m-1 rounded-3"
                              src={file}
                              alt="Corrupt File"
                              height={150}
                              onClick={() => handleImageShow(file)}
                            />
                          </>
                        )
                      })
                      :
                      <div className="p-1 px-2">
                        <small
                          className="fw-semibold text-white"
                        >
                          {mess.text}
                        </small>
                      </div>
                    }
                  </div>
                  <small className='fs-12 text-lightgrey fw-semibold d-flex justify-content-end me-1'>
                    {mDate === cDate ? moment(mess.createdAt).format("LT") : moment(mess.createdAt).format("lll")}
                  </small>
                </div>
                :
                <div className="my-1">
                  <div className={`${styles.receiverdiv}`}>
                    {mess.files?.length > 0 ?
                      mess.files?.map(file => {
                        return (
                          <>
                            <img
                              className="m-1 rounded-3"
                              src={file}
                              alt="Corrupt File"
                              height={150}
                              onClick={() => handleImageShow(file)}
                            />
                          </>
                        )
                      })
                      :
                      <div className="p-1 px-2">
                        <small
                          className="fw-semibold text-grey"
                        >
                          {mess.text}
                        </small>
                      </div>
                    }
                  </div>
                  <small
                    className={`fs-12 text-lightgrey fw-semibold ms-2`}
                  >
                    {mDate === cDate ? moment(mess.createdAt).format("LT") : moment(mess.createdAt).format("lll")}
                  </small>
                </div>
              }
            </>
          )
        })}
      </div>
      {/* {viewImag && */}
      <ChatPhotoViewer
        showModal={modalShow}
        imageLink={viewImag}
        handleClose={handleClose}
      />
      {/* } */}
    </>
  );
}
