import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    loading: true,
    profileSetting: null,
    notificationSetting: null
}

export const settingReducer = createSlice({
    name: 'setting',
    initialState: INITIAL_STATE,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setProfileSetting(state, action) {
            state.profileSetting = action.payload;
        },
        setNotificationSetting(state, action) {
            state.notificationSetting = action.payload;
        },
    }
})

export const settingActions = settingReducer.actions;

export default settingReducer;