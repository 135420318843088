import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  loading: true,
  accessToken: null,
  refreshToken: null,
  phonenumber: null,
  currentUser: null,
  currentUserID: null,
};

export const authReducer = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setUserPhoneNumber(state, action) {
      state.phonenumber = action.payload;
    },
    setCurrentuser(state, action) {
      state.currentUser = action.payload;
    },
    setCurrentuserID(state, action) {
      state.currentUserID = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const authActions = authReducer.actions;

export default authReducer;
