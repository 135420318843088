import React, { useState } from "react"
import { Form, Modal, ProgressBar } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupEducationList, signupExperienceList, signupIncomeList, signupStageFive } from "../../redux/actions/authAction"
import { useEffect } from "react"

export default function StageFive({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    education: "",
    workexperience: '',
    occupation: '',
    income: ''
  })
  const [others, setOthers] = useState({
    education: false,
    workexperience: false,
  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [educationData, setEducationData] = useState([])
  const [experianceData, setExperianceData] = useState([])
  const [incomeData, setIncomeData] = useState([])

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()
    if (fields.education && fields.workexperience) {
      setError("")
      setLoading(true)
      let data = {
        education: fields.education,
        workexperience: fields.workexperience,
        occupation: fields.occupation,
        income: parseInt(fields.income),
      }
      dispatch(signupStageFive(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        education: !fields.education,
        workexperience: !fields.workexperience,
        occupation: !fields.occupation,
        income: !fields.income,
      })
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (value === "Other") {
      setOthers({ ...others, [name]: true })
    } else if (name === "income") {
      setOthers({ ...others, [name]: false })
      setFiels({ ...fields, [name]: value.replace(/[^0-9]/g, "") })
    } else {
      setOthers({ ...others, [name]: false })
      setFiels({ ...fields, [name]: value })
    }
  }

  const handleChangeOther = (e) => {
    const { name, value } = e.target

    if (name === "income") {
      setFiels({ ...fields, [name]: value.replace(/[^0-9]/g, "") })
    } else {
      setFiels({ ...fields, [name]: value })
    }
  }




  useEffect(() => {
    dispatch(signupEducationList()).then((res) => {
      setEducationData(res?.data?.data)
    })
    dispatch(signupExperienceList()).then((res) => {
      setExperianceData(res?.data?.data)
    })
    dispatch(signupIncomeList()).then((res) => {
      setIncomeData(res?.data?.data)
    })
  }, [])


  return (
    <>
      <Modal.Header className="border-0 pb-0 d-block">
        <ProgressBar now={50} />
      </Modal.Header>
      <Modal.Body className="pt-3 px-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="m-0">Education</Form.Label>

            <Form.Select
              name="education"
              className={fields.education === "" && "selectplaceholder"}
              onChange={(e) => handleChange(e)}
            >
              <option value="" selected disabled hidden>
                Your Education
              </option>
              {
                educationData.map((e,id,exact) => (
                  <>
                    <option key={id} exact={exact} value={e.education_name}>{e.education_name}</option>
                  </>
                ))
              }
              <option value="Other">Other</option>
            </Form.Select>
            {others.education &&
              <>
                <Form.Control
                  type="text"
                  className="mt-2"
                  placeholder="Write your Education"
                  name="education"
                  value={fields.education}
                  onChange={(e) => handleChangeOther(e)}
                  required
                />
              </>}
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Work Experience</Form.Label>
            {others.workexperience ?
              <>
                <Form.Control
                  type="text"
                  placeholder="Your work experience"
                  name="workexperience"
                  value={fields.workexperience}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </> : <>
                <Form.Select
                  name="workexperience"
                  className={fields.workexperience === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Your work experience
                  </option>
                  {
                    experianceData.map((e,id,exact) => (
                      <>
                        <option key={id}  exact={exact} value={e.work_experiance_year}>{e.work_experiance_year}</option>
                      </>
                    ))
                  }
                  <option value="Other">Other</option>
                </Form.Select>
              </>}
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Occupation <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Control
              type="text"
              placeholder="Occupation"
              name="occupation"
              value={fields.occupation}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Income <small className='text-grey'>(Optional)</small></Form.Label>

            <Form.Select
              name="income"
              className={fields.income === "" && "selectplaceholder"}
              onChange={(e) => handleChange(e)}
            >
              <option value="" selected disabled hidden>
                Your Income
              </option>
              {
                incomeData.map((data,id,exact) => (
                  <>
                    <option key={id} exact={exact} value={data.income}>{data.income}</option>
                  </>
                ))
              }
              <option value="Other">Other</option>
            </Form.Select>
            {others.income &&
              <>
                <Form.Control
                  type="text"
                  className="mt-2"
                  placeholder="$000.00"
                  name="income"
                  value={fields.income}
                  onChange={(e) => handleChangeOther(e)}
                />
              </>}
          </Form.Group>

          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.education || !fields.workexperience} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
