import React, { useState, useRef } from "react"
import { Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupStageTwo } from "../../redux/actions/authAction"

export default function StageTwo({ handleModalStage }) {
  const emailRef = useRef(),
    firstname = useRef(),
    lastname = useRef()

  const dispatch = useDispatch()

  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    if (
      emailRef.current.value &&
      firstname.current.value &&
      lastname.current.value
    ) {
      setLoading(true)
      let data = {
        firstname: firstname.current.value,
        lastname: lastname.current.value,
        email: emailRef.current.value,
      }
      dispatch(signupStageTwo(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      // setError(true)
      // setError({
      //   ...error,
      //   firstname: !firstname.current.value,
      //   lastname: !lastname.current.value,
      //   email: !emailRef.current.value,
      // })
      setLoading(false)
    }
  }

  return (
    <>
      <Modal.Header className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div>
            <p className="fs-4 fw-semibold">Matches Within Your Community</p>
          </div>
          <div>
            <p className="fs-5 text-center mb-3" style={{ color: "#8E8E8E" }}>
              Genuine Profiles | Safe & Secure | Detailed Family Information
            </p>
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="m-0">First name</Form.Label>
            <Form.Control
              type="text"
              ref={firstname}
              placeholder="User First Name"
              required
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Last name</Form.Label>
            <Form.Control
              type="text"
              ref={lastname}
              placeholder="User Last Name"
              required
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">E-mail Address</Form.Label>
            <Form.Control
              type="email"
              ref={emailRef}
              placeholder="@gmail.com"
              required
            />
          </Form.Group>
          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
