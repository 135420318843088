import React, { useState } from "react"
import { Row, Form, Modal, Col, ProgressBar } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupHabitList, signupStageEight } from "../../redux/actions/authAction"
import { useEffect } from "react"

export default function StageEight({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    purposeofregistration: "",
    passionsorhobbies: "",
    habits: "",
  })
  const [others, setOthers] = useState({
    purposeofregistration: false,
    habits: false,
  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [arr, setArr] = useState([])
  // const [habitListData, setHabitListData] = useState([])

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    // if (fields.passionsorhobbies) {
    setError({})
    // setLoading(true)
    let data = {
      // purposeofregistration: fields.purposeofregistration,
      passionsorhobbies: fields.passionsorhobbies,
      habits: arr.toString()
      // habits:
      //   arr.length > 0
      //     ? fields.habits
      //       ? fields.habits.concat(",", arr.toString())
      //       : arr.toString()
      //     : fields.habits,
    }
    dispatch(signupStageEight(data)).then((res) => {
      if (res.status === 200) {
        handleModalStage()
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
    // } else {
    //   setError({
    //     purposeofregistration: !fields.purposeofregistration,
    //     passionsorhobbies: !fields.passionsorhobbies,
    //   })
    // }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (value === "Other") {
      setOthers({ ...others, [name]: true })
    } else {
      setFiels({ ...fields, [name]: value })
    }
  }

  // Handle Checkbox Values
  // const handleCheckBox = (e) => {
  //   const { name, checked } = e.target

  //   if (name === "Other") {
  //     setOthers({ ...others, habits: checked })
  //   } else if (checked) {
  //     setArr([...arr, name])
  //   } else {
  //     arr.splice(arr.indexOf(name), 1)
  //   }
  // }

  // useEffect(() => {
  //   dispatch(signupHabitList()).then((res) => {
  //     setHabitListData(res?.data?.data)
  //   })
  // }, [])

  // console.log("habitListData =>", habitListData)

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-block">
        <ProgressBar now={100} />
      </Modal.Header>
      <Modal.Body className="pt-3 px-4">
        <Form onSubmit={handleSubmit}>
          {/* <Form.Group>
            <Form.Label className="m-0">Purpose of registration</Form.Label>
            {others.purposeofregistration ? (
              <>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="purposeofregistration"
                  value={fields.purposeofregistration}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </>
            ) : (
              <>
                <Form.Select
                  name="purposeofregistration"
                  className={
                    fields.purposeofregistration === "" && "selectplaceholder"
                  }
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" selected disabled hidden>
                    Purpose of registration
                  </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </>
            )}
          </Form.Group>
          <br /> */}
          <Form.Group>
            <Form.Label className="m-0">Passions or Hobbies <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Control
              type="text"
              placeholder="Passions or Hobbies"
              name="passionsorhobbies"
              value={fields.passionsorhobbies}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0" style={{ fontSize: "15px" }}>
              Do you smoke? <small className='text-grey'>(Optional)</small>
            </Form.Label>
            <div className="">
              <Form.Check
                inline
                label="Yes"
                name="smoking"
                type="radio"
                onClick={() => {
                  if (arr.includes("Occasionally Smoking")) {
                    arr.splice(arr.indexOf("Occasionally Smoking"), 1)
                  }
                  setArr([...arr, "Smoking"])
                }}
              />
              <Form.Check
                inline
                label="No"
                name="smoking"
                type="radio"
                className="ms-sm-5"
                onClick={() => {
                  if (arr.includes("Smoking")) {
                    arr.splice(arr.indexOf("Smoking"), 1)
                  }
                  if (arr.includes("Occasionally Smoking")) {
                    arr.splice(arr.indexOf("Occasionally Smoking"), 1)
                  }
                }}
              />
              <Form.Check
                inline
                label="Occasionally"
                name="smoking"
                type="radio"
                className="ms-sm-5"
                onClick={() => {
                  if (arr.includes("Smoking")) {
                    arr.splice(arr.indexOf("Smoking"), 1)
                  }
                  setArr([...arr, "Occasionally Smoking"])
                }}
              />
            </div>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0" style={{ fontSize: "15px" }}>
              Do you drink? <small className='text-grey'>(Optional)</small>
            </Form.Label>
            <div className="mb-3">
              <Form.Check
                inline
                label="Yes"
                name="drinking"
                type="radio"
                onClick={() => {
                  if (arr.includes("Occasionally Drinking")) {
                    arr.splice(arr.indexOf("Occasionally Drinking"), 1)
                  }
                  setArr([...arr, "Drinking"])
                }}
              />
              <Form.Check
                inline
                label="No"
                name="drinking"
                type="radio"
                className="ms-sm-5"
                onClick={() => {
                  if (arr.includes("Drinking")) {
                    arr.splice(arr.indexOf("Drinking"), 1)
                  }
                  if (arr.includes("Occasionally Drinking")) {
                    arr.splice(arr.indexOf("Occasionally Drinking"), 1)
                  }
                }}
              />
              <Form.Check
                inline
                label="Occasionally"
                name="drinking"
                type="radio"
                className="ms-sm-5"
                onClick={() => {
                  if (arr.includes("Drinking")) {
                    arr.splice(arr.indexOf("Drinking"), 1)
                  }
                  setArr([...arr, "Occasionally Drinking"])
                }}
              />
            </div>
          </Form.Group>
          {/* <Form.Group>
            <Form.Label className="m-0 mb-3" style={{ fontSize: "15px" }}>
              Habits
            </Form.Label>
            <Row className="mb-3">
              <Col xs="12">
                {
                  habitListData.map((data) => (
                    <Form.Check
                      inline
                      label={data.habitName}
                      className="me-5"
                      name={data.habitName}
                      type="checkbox"
                      onChange={(e) => handleCheckBox(e)}
                    />
                  ))
                }
              </Col>
            </Row>
            {others.habits && (
              <>
                <Form.Control
                  type="text"
                  placeholder="Your habits"
                  name="habits"
                  value={fields.habits}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </>
            )}
          </Form.Group> */}
          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
