import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import ChatSideBar from "./ChatSideBar"
import ChatMessenger from "./ChatMessenger"
import styles from "../styles.module.css"
import { io } from "socket.io-client"
import Loader from "../../../Components/Loader"
import EmptyChatMessenger from "./EmptyChatMessenger"
import { useLocation, useNavigate } from "react-router-dom"

const socket = io("https://api.spouze.co/")
// const socket = io("http://localhost:3000/")

export default function PremiumUser() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 991)
  const [isChatMessenger, setIsChatMessenger] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [isConnected, setIsConnected] = useState(socket.connected)
  const [activeChatBoxPersonDetail, setactiveChatBoxPersonDetail] = useState("")
  const [firstMessUser, setFirstMessUser] = useState(null)

  const location = useLocation()
  const navigate = useNavigate()

  const [userData, setUserData] = useState({});

  const userDetails = useSelector((state) => state.home.userDetails);
  useEffect(() => {
    if (userDetails) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 991)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true)
    })

    // socket.on("disconnect", () => {
    //   setIsConnected(false)
    // })
    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect()
      }
      // else the socket will automatically try to reconnect
    })

    // return () => {
    //   socket.off("connect")
    //   //   socket.off("disconnect")
    // }
  }, [])


  const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
  const currentUserID = useSelector(state => state.auth.currentUserID)
  const getUserDetail = currentUserID || currentuser?.uid

  useEffect(() => {
    if (location.state) {
      setFirstMessUser(location.state)
      setIsChatMessenger(true)
      window.history.replaceState({}, document.title)
    }
  }, [])


  useEffect(() => {
    if (isConnected && getUserDetail) {
      let user = {
        UserId: getUserDetail,
      }
      socket.emit("authenticate", user)

    }
  }, [getUserDetail, isConnected])

  useEffect(() => {
    socket.on("authenticate", (data) => {
      if (data?.code === 200) {
        setIsLoading(false)
      }
    })
  }, [socket])

  // useEffect(()=>{
  //   console.log("activeChatBoxPersonDetail ::: "+JSON.stringify(activeChatBoxPersonDetail))
  // },[activeChatBoxPersonDetail])


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Container style={{ maxWidth: 1206 }}>
          <Row className={isDesktop && "my-4"}>
            {isDesktop ? (
              <>
                <Col md={4} sm={12} className={`${styles.chatProfile} `}>
                  <ChatSideBar
                    isDesktop={isDesktop}
                    socket={socket}
                    setactiveChatBoxPersonDetail={setactiveChatBoxPersonDetail}
                  />
                </Col>
                <Col md={8} sm={12} className={`${styles.chatMessageBody} p-0`}>
                  {/* //if any user is premium continue this, else display unlock premium profile in space of chatmessanger */}
                  {activeChatBoxPersonDetail?.premium_user || userDetails?.user_detail?.plan_details?.isActive ?
                    (
                      (activeChatBoxPersonDetail || firstMessUser ? (
                        <ChatMessenger
                          isDesktop={isDesktop}
                          activeChatBoxPersonDetail={activeChatBoxPersonDetail}
                          firstMessUser={firstMessUser}
                          socket={socket}
                          senderId={getUserDetail}
                        />
                      ) : (
                        <EmptyChatMessenger />
                      ))
                    ) :
                    (
                      <>
                        <div className="d-flex justify-content-center mt-4">
                          <div className="position-relative me-1">
                            <img src="/Assest/image/group111.png" alt="profile" />
                          </div>
                          <div
                            className={`${styles.circleImg} d-flex justify-content-center position-absolute rounded-circle mt-3 ms-2`}
                          >
                            <img
                              src="/Assest/image/chat-crown.png"
                              alt="profile"
                              className={`${styles.chatCrown} mt-3`}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          <h5
                            className={`${styles.chatText} position-absolute text-center`}
                          >
                            Only Premium Members can start <br />a new conversation.
                          </h5>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                          <button className={`${styles.upgradeNowBtn} mt-4`}
                          onClick={() => navigate("/premimumplans")}>
                            Upgrade Now
                          </button>
                        </div>
                      </>
                    )
                  }
                </Col>
              </>
            ) : isChatMessenger ?
              (
                activeChatBoxPersonDetail?.premium_user || userDetails?.user_detail?.plan_details?.isActive ?
                  (
                    <Col sm={12} className={`${styles.chatMessageBody} p-0`}>
                      <ChatMessenger
                        setIsChatMessenger={setIsChatMessenger}
                        isDesktop={isDesktop}
                        socket={socket}
                        activeChatBoxPersonDetail={activeChatBoxPersonDetail}
                        firstMessUser={firstMessUser}
                        senderId={getUserDetail}
                      />
                    </Col>
                  )
                  :
                  (
                    <>
                      <div className="d-flex justify-content-center mt-4">
                        <div className="position-relative me-1">
                          <img src="/Assest/image/group111.png" alt="profile" />
                        </div>
                        <div
                          className={`${styles.circleImg} d-flex justify-content-center position-absolute rounded-circle mt-3 ms-2`}
                        >
                          <img
                            src="/Assest/image/chat-crown.png"
                            alt="profile"
                            className={`${styles.chatCrown} mt-3`}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <h5
                          className={`${styles.chatText} position-absolute text-center`}
                        >
                          Only Premium Members can start <br />a new conversation.
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center mt-5">
                        <button className={`${styles.upgradeNowBtn} mt-4`}
                        onClick={() => navigate("/premimumplans")}>
                          Upgrade Now
                        </button>
                      </div>
                    </>
                  )
              )
              : (
                <Col sm={12} className={`${styles.chatProfile}`}>
                  <ChatSideBar
                    setIsChatMessenger={setIsChatMessenger}
                    isDesktop={isDesktop}
                    socket={socket}
                    setactiveChatBoxPersonDetail={setactiveChatBoxPersonDetail}
                  />
                </Col>
              )}
          </Row>
        </Container>
      )}
    </>
  )
}
