import React, { useEffect, useState } from "react"
import { Form, Button, Modal, Card, Alert, NavDropdown } from "react-bootstrap"
import { useSelector } from "react-redux"
import { FaChevronDown } from "react-icons/fa"
import { logout } from "../../redux/actions/authAction"

function CustomModal(props) {
  const user = useSelector((store) => store.auth.currentUser)
  const [isDesktop, setDesktop] = useState(window.innerWidth)

  const updateMedia = () => {
    setDesktop(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  return (
    <>
      <div
        className={props.modalStage > 0 ? "d-block" : "d-none"}
        style={{
          position: "absolute",
          top: isDesktop > 426 ? "5%" : "2%",
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: 1056,
        }}
      >
        <h3 className="text-white text-center m-0">
          {props.modalStage > 0 && props.modalStage < 9
            ? "Personal Details"
            : props.modalStage === 9
              ? "You are Almost Done!"
              : props.modalStage > 9 && props.modalStage < 15
                ? "Family Details"
                : "Add Photo"}
        </h3>
      </div>
      <div
        className={props.modalStage > 0 ? "d-block" : "d-none"}
        style={{
          position: "absolute",
          top: isDesktop > 426 ? "4%" : "2%",
          right: "2%",
          zIndex: 1056,
        }}
      >
        <NavDropdown
          title={
            <>
              <img
                src={user?._delegate?.photoURL || "/Assest/image/homepage-userphoto.png"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/Assest/image/homepage-userphoto.png";
                }}
                alt="."
                height="40px"
                width="40px"
                className="rounded-circle"
              />
              <FaChevronDown className="ms-2" style={{ color: "#F2F2F2" }} />
            </>
          }
          id="basic-nav-dropdown"
          className="ms-5 ms-lg-3 text-white"
        >
          {user?._delegate?.displayName && (
            <NavDropdown.Item>{user?._delegate?.displayName}</NavDropdown.Item>
          )}
          <NavDropdown.Item
            href="/matrimonial"
            onClick={async () => {
              await logout()
              localStorage.clear()
            }}
          >
            Log Out
          </NavDropdown.Item>
        </NavDropdown>
      </div>
      <Modal
        {...props}
        size="md"
        fullscreen="sm-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={props.modalStage > 0 && "pinkshow"}
      >
        {props.children}
      </Modal>
      {isDesktop > 600 &&
        <div
          className={props.modalStage > 0 ? "d-block" : "d-none"}
          style={{
            width: "100%",
            position: "absolute",
            bottom: "4%",
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 1056,
          }}
        >
          <h5 className="text-white text-center m-0">
            By continuing, you agree to our  <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="text-white"> <u style={{ textUnderlinePosition: "from-font" }}>Terms & Service</u></a> <br /> and  <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="text-white"> <u style={{ textUnderlinePosition: "from-font" }}>Privacy Policy</u></a>.
          </h5>
        </div>
      }
    </>
  )
}

export default CustomModal
