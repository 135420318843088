import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPeopleRoof,
  faUserGroup,
  faLink,
} from "@fortawesome/free-solid-svg-icons"
import styles from "./styles.module.css"
import Modals from "../../Authintication"

const LandingPage = () => {
  const navigate = useNavigate()
  const [modalShow, setModalShow] = useState(false);
  const [initial, setInitial] = useState(-2);

  return (
    <>
      <header className={styles.lheader}>
        <div className="container">
          <nav className="navbar navbar-light">
            <div className="container-fluid p-0">
              <img
                src="/Assest/icons/spouzeLogo.png"
                alt="."
                height={50}
                width={50}
              />
              <div className="d-flex">
                <button
                  className={`my-3 mx-2 ${styles.headerbtn1}`}
                  onClick={() => {
                    setInitial(-2)
                    setModalShow(true)
                  }}
                >
                  Login
                </button>
                <button
                  className={`my-3 mx-2 ${styles.headerbtn2}`}
                  onClick={() => {
                    setInitial(-3)
                    setModalShow(true)
                  }}
                >
                  Join Now
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <main>
        {/* Hero */}
        <section className="position-relative">
          <div className={`d-flex align-items-center ${styles.sliderbg}`} style={{ backgroundImage: "url('/Assest/image/slider-img.webp')" }}>
            <div className="customcontainer">
              <div className="col-sm-6 text-center text-white">
                <h1 className="display-5 fw-bold">
                  Millions found their perfect match Matrimony!
                </h1>
                <p className="fs-3">You too can find a life partner</p>
              </div>
            </div>
          </div>
        </section>
        {/* Hero */}

        {/* History-Start */}
        <section>
          <div className="bg-dark py-30">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 text-center text-white py-2">
                  <h6 className="fs-5">40+ Languages</h6>
                  <h6>Offering Multilingual Choices</h6>
                </div>
                <div className="col-lg-3 col-sm-6 text-center text-white py-2">
                  <h6 className="fs-5">480+ Castes</h6>
                  <h6>Within India &amp; Abroad</h6>
                </div>
                <div className="col-lg-3 col-sm-6 text-center text-white py-2">
                  <h6 className="fs-5">3200+ Cities</h6>
                  <h6>Across 4 countries of operation</h6>
                </div>
                <div className="col-lg-3 col-sm-6 text-center text-white py-2">
                  <h6 className="fs-5">4 Countries</h6>
                  <h6>Connecting beyond borders</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* History-End */}

        {/* Comunity-Start */}
        {/* <section>
          <div className={`${styles.comunitybg} py-100 col-sm-12`}>
            <div className="container">
              <div className="comunity-title text-center">
                <h2 className="fw-bolder">Matches Within Your Community</h2>
                <p className="fw-semibold">
                  Genuine Profiles | Safe &amp; Secure | Detailed Family
                  Information
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={`${styles.comunitycontaint} bg-white rounded-4 p-sm-5 p-2 col-lg-8`}
                >
                  <div className="row text-center text-lg-start">
                    <div className="col-lg-6 p-0">
                      <form>
                        <div className="my-3">
                          <h6>Create Profile For</h6>
                          <select
                            id="selectbox"
                            className={styles.comunityselect}
                          >
                            <option>Select</option>
                            <option value="Women">Woman</option>
                            <option value="Man">Man</option>
                          </select>
                        </div>
                        <div className="my-3">
                          <h6>Mother Tangue</h6>
                          <select
                            id="selectbox"
                            className={styles.comunityselect}
                          >
                            <option>Select</option>
                            <option value="english">English</option>
                            <option value="gujrati">Gujrati</option>
                          </select>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-6 p-0">
                      <form>
                        <div className="my-3">
                          <h6>Date of Birth</h6>
                          <input
                            type="date"
                            className={styles.comunityselect}
                          />
                        </div>
                        <div className="my-3">
                          <h6>Religion</h6>
                          <select
                            id="selectbox"
                            className={styles.comunityselect}
                          >
                            <option>Select</option>
                            <option value="hindu">Hindu</option>
                            <option value="muslim">Muslim</option>
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button className={styles.comunitybtn}>Let's Begin</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Comunity-End */}

        {/* Why Choose Start */}
        <section>
          <div className="why-choose-bg py-100">
            <div className="container">
              <div className="why-choose-title text-center">
                <h2 className="fw-bolder fs-1">Why Choose......</h2>
                <p className="fw-semibold fs-5">
                  Sangam is different from other matchmaking sites because of
                  some unique
                  <br /> benefits that every parent will find highly useful
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 text-center mt-5">
                  {/* <i
                                        className="fa-solid fa-user-check bg-black text-white fs-3 mb-2"
                                        style={{ borderRadius: "50%", padding: 30 }}
                                    /> */}
                  <FontAwesomeIcon
                    icon={faPeopleRoof}
                    className="bg-black text-white fs-3 mb-2"
                    style={{ borderRadius: "50%", padding: 30 }}
                  />

                  <p className="my-1 fw-semibold">
                    Get Completet Family Information
                  </p>
                  <p className="fw-semibold">
                    You will find detailed family Information in
                    <br />
                    every profile. knowing the family will help
                    <br /> you take the next step with confidence
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 text-center mt-5">
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className="bg-black text-white fs-3 mb-2"
                    style={{ borderRadius: "50%", padding: 30 }}
                  />
                  <p className="my-1 fw-semibold">
                    Get Completet Family Information
                  </p>
                  <p className="fw-semibold">
                    You will find detailed family Information in
                    <br />
                    every profile. knowing the family will help
                    <br /> you take the next step with confidence
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 text-center mt-5">
                  <FontAwesomeIcon
                    icon={faLink}
                    className="bg-black text-white fs-3 mb-2"
                    style={{ borderRadius: "50%", padding: 30 }}
                  />
                  <p className="my-1 fw-semibold">
                    Get Completet Family Information
                  </p>
                  <p className="fw-semibold">
                    You will find detailed family Information in
                    <br />
                    every profile. knowing the family will help
                    <br /> you take the next step with confidence
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Why Choose End */}

        {/* Regesiter-start */}
        <section>
          <div className="py-50" style={{ backgroundColor: "#fbfbfb" }}>
            <div className="container d-flex justify-content-center">
              <div
                className={`${styles.registerbg} py-50 row align-items-center`}
                style={{ backgroundImage: "url('/Assest/image/register-bg.jpg')" }}
              >
                <div className="col-lg-1 col-0"></div>
                <div className="col-lg-8 text-center text-lg-start">
                  <p className="fs-5 fw-semibold mb-0">
                    <span className="fs-3">Most Trusted</span> Matrimony Brand
                    in USA{" "}
                  </p>
                  <p className="fs-5 fw-semibold">
                    With 140+ branches across USA, largest network of its kind
                  </p>
                </div>
                <div className="col-lg-3 text-center">
                  <button className={`${styles.registerbtn} fw-bold fs-5`} onClick={() => {
                    setInitial(-1)
                    setModalShow(true)
                  }}>
                    Register Free Now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Regesiter-end */}

        {/* Matrimony Card-start */}
        <section>
          <div className="py-50">
            <div className="container">
              <div>
                <p className="fs-2 fw-semibold text-center mb-5">
                  Millions have found their life partner at USA Matrimony!
                </p>
              </div>
              <div className={`row justify-content-center`}>
                <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                  <div className={`${styles.matrimonyCard} my-3`}>
                    <img
                      className="card-img-top"
                      src="/Assest/image/card-1.jpg"
                      alt=""
                      height="380px"
                    />
                    <div className="card-body pb-5">
                      <h6 className="card-text fw-semibold fs-5">
                        Pooja &amp; Milan
                      </h6>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                        <span
                          className="fw-bold ms-3"
                          style={{ color: "#f3134a" }}
                        >
                          Read More &gt;
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                  <div className={`${styles.matrimonyCard} my-3`}>
                    <img
                      className="card-img-top"
                      src="/Assest/image/card-2.jpg"
                      alt=""
                      height="380px"
                    />
                    <div className="card-body pb-5">
                      <h6 className="card-text fw-semibold fs-5">
                        Pooja &amp; Milan
                      </h6>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                        <span
                          className="fw-bold ms-3"
                          style={{ color: "#f3134a" }}
                        >
                          Read More &gt;
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                  <div className={`${styles.matrimonyCard} my-3`}>
                    <img
                      className="card-img-top"
                      src="/Assest/image/card-3.jpg"
                      alt=""
                      height="380px"
                    />
                    <div className="card-body pb-5">
                      <h6 className="card-text fw-semibold fs-5">
                        Pooja &amp; Milan
                      </h6>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                        <span
                          className="fw-bold ms-3"
                          style={{ color: "#f3134a" }}
                        >
                          Read More &gt;
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="fs-2 fw-semibold text-center mt-5">
                    Now it is your turn to be happily married
                  </p>
                </div>
                <div className="text-center mt-3">
                  <button className={`${styles.registerbtn2} fw-semibold`} onClick={() => {
                    setInitial(-1)
                    setModalShow(true)
                  }}>
                    Register Free Now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Matrimony Card End */}

        {/* Mobile-app-start */}
        <section>
          <div className="py-50" style={{ backgroundColor: "#ffe9ee" }}>
            <div className="container">
              <div className="row">
                <div className="mobile-img col-lg-6 d-flex justify-content-lg-end">
                  <img
                    src="/Assest/image/mobile.png"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center mt-4">
                  <div className="mobile-containt text-center text-lg-start">
                    <h5 className="fw-bold mb-4 fs-4">
                      Happiness is Just an App Away!
                    </h5>
                    <p className="fw-semibold fs-5">
                      Stay up-to-date with your Partner
                      <br />
                      search using our Matrimony App
                    </p>
                    <p
                      className="fw-semibold mb-5"
                      style={{ color: "#f3134a", fontSize: 14 }}
                    >
                      {" "}
                      <i className="fa-solid fa-hand-point-right me-2" />
                      Simple | Fast | Convenient | Safe &amp; Secure
                    </p>
                    <p
                      className="fw-semibold text-secondary"
                      style={{ fontSize: 14 }}
                    >
                      Available on iOS &amp; Android
                    </p>
                    <div>
                      <a href="https://play.google.com/store/apps/details?id=com.peopleconnect.spouze" target="_blank">
                        <img
                          src="/Assest/image/googleplay.png"
                          alt=""
                          width="110px"
                          height="35px"
                        />
                      </a>
                      <a href="https://apps.apple.com/us/app/spouze/id1645705008" target="_blank">
                        <img
                          src="/Assest/image/appstore.png"
                          alt=""
                          width="110px"
                          height="35px"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Mobile-app-and */}

        {/* contact-start */}
        <section>
          <div className="py-50">
            <div className="container">
              <div className="contact-title text-center">
                <h4 className="fw-bold">
                  Upgrade your membership to connect with those you like.
                </h4>
                <p className="text-secondary fw-semibold">
                  Search your perfect life-partner from millions of trusted
                  &amp; authentic profiles.
                </p>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 text-center p-3 m-4"
                  style={{
                    width: "18rem",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <img
                    src="/Assest/image/phone.png"
                    alt=""
                    height="40px"
                    width="40px"
                    className="mb-2"
                  />
                  <h5 className="mt-3 fw-bold">View Contacts</h5>
                  <p
                    className="text-secondary fw-semibold"
                    style={{ fontSize: 14 }}
                  >
                    Access contact number and connect directly on call or via
                    sms
                  </p>
                </div>
                <div
                  className="col-lg-4 text-center p-3 m-4"
                  style={{
                    width: "18rem",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <img
                    src="/Assest/image/message.png"
                    alt=""
                    height="35px"
                    width="50px"
                    className="mb-2"
                  />
                  <h5 className="mt-3 fw-bold">View Email</h5>
                  <p
                    className="text-secondary fw-semibold"
                    style={{ fontSize: 14 }}
                  >
                    Get the email id of your selected profile and reach via
                    email
                  </p>
                </div>
                <div
                  className="col-lg-4 text-center p-3 m-4"
                  style={{
                    width: "18rem",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <img
                    src="/Assest/image/chat.png"
                    alt=""
                    height="40px"
                    width="40px"
                    className="mb-2"
                  />
                  <h5 className="mt-3 fw-bold">Chat</h5>
                  <p
                    className="text-secondary fw-semibold"
                    style={{ fontSize: 14 }}
                  >
                    Chat instantly with all other
                    <br />
                    online users
                  </p>
                </div>
              </div>
              <div className="text-center mt-3">
                <button className={`${styles.registerbtn2} fw-semibold`} onClick={() => navigate("/browsemembership")}>
                  BROWSE MEMBERSHIP PLANS
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* contact-end */}

        {/* Matrimonial-profile-start */}
        <section>
          <div className={`${styles.mainprofile} bg-dark`}>
            <div className="container">
              <div className="text-center">
                <h5 className="text-white fw-bold mb-5">
                  Browser Matrimonial Profiles By
                </h5>
              </div>
              <div
                className={`${styles.gallerytopic} d-flex flex-wrap justify-content-center`}
              >
                <span className="fs-5 text-white active">Religion</span>
                <span className="fs-5 text-white">Caste</span>
                <span className="fs-5 text-white">City</span>
                <span className="fs-5 text-white">State</span>
                <span className="fs-5 text-white">Country</span>
                <span className="fs-5 text-white">Occupation</span>
              </div>
              <div
                className={`${styles.gallerymenu} text-center d-flex flex-wrap justify-content-center`}
              >
                <span className="fs-5 text-white mt-5">Hindu</span>
                <span className="fs-5 text-white mt-5">Muslim</span>
                <span className="fs-5 text-white mt-5">Christain</span>
                <span className="fs-5 text-white mt-5">Buddhist</span>
                <span className="fs-5 text-white mt-5">Shikh</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <h6 className="text-white me-5 cursor-pointer"

              >
                <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="text-white"> <u >Terms & Service</u></a>
              </h6>
              <h6 className="text-white cursor-pointer" >
                <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="text-white"><u >Privacy Policy</u></a>
              </h6>
            </div>
          </div>
        </section>
        {/* Matrimonial-profile-end */}
      </main>
      <footer className={styles.lfooter}>

        {/* <div className="py-100">
          <div className="container">
            <div className="row text-secondary">
              <div className="col-lg-3 text-center">
                <h5 className="fw-bold position-relative mb-4">Need Help?</h5>
                <ul className="fw-semibold p-0">
                  <li>Member Login</li>
                  <li>Sign Up</li>
                  <li>How to use</li>
                  <li>Primium Memberships</li>
                  <li>Customer Support</li>
                  <li>Site Map</li>
                </ul>
              </div>
              <div className="col-lg-3 text-center">
                <h5 className="fw-bold position-relative mb-4">Need Help?</h5>
                <ul className="fw-semibold p-0">
                  <li>Member Login</li>
                  <li>Sign Up</li>
                  <li>How to use</li>
                  <li>Primium Memberships</li>
                  <li>Customer Support</li>
                  <li>Site Map</li>
                </ul>
              </div>
              <div className="col-lg-3 text-center">
                <h5 className="fw-bold position-relative mb-4">Need Help?</h5>
                <ul className="fw-semibold p-0">
                  <li>Member Login</li>
                  <li>Sign Up</li>
                  <li>How to use</li>
                  <li>Primium Memberships</li>
                  <li>Customer Support</li>
                  <li>Site Map</li>
                </ul>
              </div>
              <div className="col-lg-3 text-center">
                <h5 className="fw-bold position-relative mb-4">Need Help?</h5>
                <ul className="fw-semibold p-0">
                  <li>Member Login</li>
                  <li>Sign Up</li>
                  <li>How to use</li>
                  <li>Primium Memberships</li>
                  <li>Customer Support</li>
                  <li>Site Map</li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
      <Modals modalShow={modalShow} setModalShow={setModalShow} initial={initial} />
    </>
  )
}

export default LandingPage