import React, { useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom"

const Pageone = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate("/suggestion", { replace: true })
        }, 5000);
    }, [])

    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center "
            style={{
                background:
                    "linear-gradient(163.09deg, #f54872 -4.42%, #f0003b 97.78%)",
                boxShadow: "0px 3px 12px 2px rgba(0, 0, 0, 0.05)",
                height: "100vh"
            }}
        >
            <div >
                <div className="text-center">
                    <div className="text-white fw-semibold">
                        <p className={`${styles.heading}`}>Welome to </p>
                        <p className={`${styles.heading2}`}>Spouze</p>
                    </div>
                </div>
                <div className="py-5">
                    <div className="py-5 justify-content-center align-content-center d-flex position-relative m-1">
                        <img
                            className={`${styles.circleImage1} img-fluid position-absolute rounded-circle border-box`}
                            src="/Assest/image/girl-24.png"
                            alt="."
                        />
                        {/* <span> */}
                        <img
                            className={`${styles.circleImage2} position-absolute rounded-circle border-box`}
                            src="/Assest/image/boy-1.png"
                            alt="."
                        />
                        <img
                            className={`${styles.circleImage3} position-absolute rounded-circle border-box`}
                            src="/Assest/image/girl-25.png"
                            alt="."
                        />
                        {/* </span> */}
                        <span
                            className={`${styles.circleImageText} position-absolute rounded-circle border-box`}
                        >
                            <p className={`${styles.circleText}text-center text-white`}>
                                50+
                            </p>
                        </span>
                    </div>
                </div>
                <div className="text-white text-center mt-3">
                    <p className={`${styles.paragraphText} pb-5 mb-5`}>
                        Find Matches within your community with <br /> detailed family
                        information
                    </p>
                </div>
                <div className="position-absolute bottom-0">
                    <Footer />
                </div>
            </div>
            {/* <Row className="pb-3">
                <Col sm={12} className="text-center  my-4 pb-4">
                    <button className={`${styles.continueBtn} `}>Continue</button>
                </Col>
            </Row> */}
        </Container>

    )
}

export default Pageone