import React, { useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Accordion, Card, Col, Container, Row } from "react-bootstrap"
import { BsCreditCard2Back, BsCreditCard2Front } from "react-icons/bs"
import { TbWorld } from "react-icons/tb"
import Header from "../../Components/Header"
import styles from "./styles.module.css"
import axios from "axios"
import { BASE_URL } from "../../Services/config"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#ffabc0",
      color: "rgb(255, 100, 138)",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#0e0004" },
      "::placeholder": { color: "#ffabc0" },
    },
    invalid: {
      iconColor: "red",
      color: "red"
    }
  }
}
const PUBLIC_KEY = "pk_test_51MbJENSJhtf0sRqoMhpz4Qh4ZErRBSJdObOF22Rw6IuVaZo4JMnreu1tAdRgkYZlhfTmeXdfXGjD5WcuNY4xOBJO00HRRyItHs"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function PremimumPayments(props) {
  return (
    <Elements stripe={stripeTestPromise}>
      <Wrapper {...props} />
    </Elements>
  )
}

function Wrapper() {

  const accessToken = useSelector((store) => store.auth.accessToken)

  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()

  // console.log("location", location)

  const handleSubmit = async (e) => {
    e.preventDefault()
    fetch(`${BASE_URL}/app/plan/planpayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(
        { id: location.state._id },
      ),
    }).then(res => {
      if (res.ok) return res.json()
      return res.json().then(json => Promise.reject(json))
    }).then((res) => {
      window.location = res.data.url
    }).catch(e => {
      console.error(e.error)
    })
  }
  // const handleSubmit = async (e) => {
  //   e.preventDefault()
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: elements.getElement(CardElement)
  //   })


  //   // const response = await axios.post(`${BASE_URL}/app/plan/planpayment`, {
  //   // , {
  //   //   headers: {
  //   //     Authorization: `Bearer ${accessToken}`,
  //   //   }
  //   // }
  //   if (!error) {
  //     try {
  //       const { id } = paymentMethod
  //       const response = await axios.post(`http://localhost:4000/payment`, {
  //         amount: 12,
  //         id
  //       }
  //       )
  //       console.log("Payment", response)
  //       if (response.data.success) {
  //         setSuccess(true)
  //       }

  //     } catch (error) {
  //       console.log("Error", error)
  //     }
  //   } else {
  //     console.log(error.message)
  //   }
  // }

  return (
    <>
      <main>
        <Header />
        <Container>
          <Row className="mt-3">
            <Col md={{ span: 8, offset: 2 }}>
              <Card body className={styles.paymentCards}>
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="mb-0 text-grey">{location.state.days} Days</h5>
                    <small style={{ color: "#0FA958" }}>
                      (50% off applied)
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <del className="text-grey me-3">${location.state.price * 2}.00 </del>
                    <h5 className="mb-0">${location.state.price}.00</h5>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <h5 className="fw-semibold m-0">
                    Grand Total <small className="text-grey">(incl GST)</small>
                  </h5>
                  <h5 className="fw-semibold m-0">${location.state.price}.00</h5>
                </div>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5 m-auto">
            <Col>
              <div className="text-center mt-3">
                <button className={`${styles.paybtn}`} onClick={handleSubmit}>
                  Pay
                </button>
              </div>
              {/* <button
                className={`${styles.continueBtn}`}
                onClick={handleSubmit}
              >
                Pay
              </button> */}
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}
