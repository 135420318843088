import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import editProfileReducer from './reducers/editProfileReducer';
import homeReducer from './reducers/homeReducer';
import inboxReducer from './reducers/inboxReducer';
import matchesReducer from './reducers/matchesReducer';
import settingReducer from './reducers/settingReducer';

const loggerMiddleware = store => next => action => {
    // console.log('dispatching', action)
    if (action.type?.startsWith('auth/setCurrentuser')) {
        const { currentUser } = store.getState().auth;
        if (currentUser) {
            localStorage.setItem('matrimonialAuthCredentials', JSON.stringify(currentUser));
        }
    }
    // console.log('next state', store.getState())
    return next(action)
}

const middlewares = [thunk];

const store = configureStore({
    reducer: {
        auth: authReducer.reducer,
        home: homeReducer.reducer,
        matches: matchesReducer.reducer,
        inbox: inboxReducer.reducer,
        editProfile: editProfileReducer.reducer,
        setting: settingReducer.reducer,
    },
    middleware: [...middlewares],
})

export default store;