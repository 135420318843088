/* global grecaptcha */
import { auth } from '../../firebase'
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, RecaptchaVerifier, signInWithPopup } from "firebase/auth";
import { authActions } from '../reducers/authReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Services/config';

// Handle error messages
const getMessage = error => {
    let message = null;
    if (error && typeof error === 'object' && Object.keys(error).length) {
        if (error?.response && error?.response?.data) {
            if (error?.response?.data?.status && error?.response?.data?.status?.message) {
                if ((error?.response?.data?.status?.code === 400 && error?.response?.data?.status?.message === "User Not Found") || error?.response?.data?.status?.code === 401) {
                    logout()
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
                message = error?.response?.data?.status?.message;
            }
        }
    }

    if (!message) {
        message = 'Unable to proceed at the moment!!';
    }

    return message;
};

//----------------------------------------------------------------//
//--------------------- FIREBASE SIGN-UP -------------------------//
//----------------------------------------------------------------//


// Generate Google Recaptcha
export async function generateRecaptcha() {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
    }, auth);
}

// Send OTP request to server
export async function requestOTP(phone) {
    let phoneNumber = phone
    let appVerifier = window.recaptchaVerifier
    return auth.signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            return true
        }).catch((error) => {
            // Error; SMS not sent
            toast.error(error.message);

            setTimeout(() => {
                window.recaptchaVerifier.render().then(function (widgetId) {
                    window.recaptchaWidgetId=widgetId;
                    grecaptcha.reset(widgetId)
                })
            }, 2000)
            return false
        });
}

// OTP Varification
export async function verifyOTP(otp) {
    let confirmationResult = window.confirmationResult
    return confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        // const user = result.user;
        return true
    }).catch((error) => {
        toast.error(error.message);
        return false
    });
}

// Storing Phone Number to Redu-Store
export const setPhoneNumber = (phone) => {
    let response;
    return async dispatch => {
        await requestOTP(phone).then(res => {
            response = res;
        })
        dispatch(authActions.setUserPhoneNumber(phone))
        return response
    }
}

// Login with Facebook
export async function loginwithFacebook() {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider)
    // .then((result) => {
    //     // The signed-in user info.
    //     const user = result.user;

    //     // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //     const credential = FacebookAuthProvider.credentialFromResult(result);
    //     const accessToken = credential.accessToken;

    //     console.log("token", accessToken, user);
    //     // ...
    // }).catch((error) => {
    //     // Handle Errors here.
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // The email of the user's account used.
    //     const email = error.customData.email;
    //     // The AuthCredential type that was used.
    //     const credential = FacebookAuthProvider.credentialFromError(error);
    //     console.log("errorCode", error, errorCode, errorMessage, email, credential);
    //     // ...
    // })
}

// Login with Google account
export async function loginwithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: "select_account",
    })
    return signInWithPopup(auth, provider)
    // return auth.signInWithPopup(provider)
    //     .then((result) => {
    //         // This gives you a Google Access Token. You can use it to access the Google API.
    //         // const credential = GoogleAuthProvider.credentialFromResult(result);
    //         // const token = credential.accessToken;
    //         // The signed-in user info.
    //         const usertoken = result.user._delegate.stsTokenManager;
    //         // sessionStorage.setItem('Auth Token', usertoken.refreshToken)
    //         // console.log("token", result, usertoken.refreshToken);
    //         // ...
    //     }).catch((error) => {
    //         // Handle Errors here.
    //         const errorCode = error.code;
    //         toast.error(error.message);
    //         // The email of the user's account used.
    //         const email = error.customData.email;
    //         // The AuthCredential type that was used.
    //         const credential = GoogleAuthProvider.credentialFromError(error);
    //         console.log("errorCode", error, errorCode, email, credential);
    //         // ...
    //     })
}

// Login with AppleID
export async function loginwithApple() {
    const provider = new OAuthProvider('apple.com');
    // await signInWithRedirect(auth, provider)
    return auth.signInWithPopup(provider)
    // .then((result) => {
    //     // The signed-in user info.
    //     const user = result.user;

    //     // Apple credential
    //     const credential = OAuthProvider.credentialFromResult(result);
    //     const accessToken = credential.accessToken;
    //     const idToken = credential.idToken;

    //     console.log("token", accessToken, user, result);
    //     // ...
    // }).catch((error) => {
    //     // Handle Errors here.
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     toast.error(error.message);
    //     // The email of the user's account used.
    //     const email = error.customData.email;
    //     // The credential that was used.
    //     const credential = OAuthProvider.credentialFromError(error);
    //     console.log("errorCode", error, errorCode, errorMessage, email, credential);
    //     // ...
    // })
}

// Fetch User Detail
export const userDetails = () => {
    return async dispatch => {
        dispatch(authActions.setLoading(true));
        auth.onAuthStateChanged(user => {
            dispatch(authActions.setCurrentuser(user));
            dispatch(authActions.setCurrentuserID(user?.multiFactor?.user?.uid));
            if (user) {
                let refreshtoken = user?._delegate?.stsTokenManager?.refreshToken
                let accessToken = user?._delegate?.stsTokenManager?.accessToken
                // const expiryTime = new Date().getTime() + user?._delegate?.stsTokenManager?.expirationTime * 1000
                if (refreshtoken && accessToken) {
                    dispatch(authActions.setRefreshToken(refreshtoken));
                    dispatch(authActions.setAccessToken(accessToken));
                }
            }
        })
        await dispatch(authActions.setLoading(false));
    }
}

export async function logout() {
    // await auth.revokeAccess();
    await auth.signOut()
    localStorage.clear()
    console.log("1-1")
    // return auth.signOut()
}

//----------------------------------------------------------------//
//----------------- SIGN-UP STAGES STARTED -----------------------//
//----------------------------------------------------------------//


export const loginToDatabase = () => {
    return async (dispatch, getState) => {
        const { accessToken, currentUser, currentUserID } = getState().auth;
        // console.log(getState().auth)
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/auth/login";
            const response = await axios.post(url, { "user_id": currentUserID }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    // use
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        if (response.data.data.isCompleted) {
                            localStorage.setItem('matrimonialAuthCredentials', JSON.stringify(currentUser));
                        }
                        return response.data

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }

        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

// Stage One API
export const signupStageOne = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage1";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Two API
export const signupStageTwo = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage2";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Three API
export const signupStageThree = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage3";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Four API
export const signupStageFour = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage4";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Five API
export const signupStageFive = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage5";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Six API
export const signupStageSix = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage6";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Seven API
export const signupStageSeven = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage7";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Eight API
export const signupStageEight = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage8";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Stage Nine API
export const signupStageNine = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/stage9";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Get Current Stage
export const getCurrentStage = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/currentstage";
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Add Father Details
export const signupFatherDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/add_fatherdetail";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return dispatch(getCurrentStage())

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Add Mother Details
export const signupMotherDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/add_motherdetail";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return dispatch(getCurrentStage())

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Add Brother Details
export const signupBrotherDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/add_brotherdetail";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return dispatch(getCurrentStage())

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Add Sister Details
export const signupSisterDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/add_sisterdetail";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return dispatch(getCurrentStage())

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// Add Sibling Details
export const signupSiblingsDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/journey/add_siblingsdetail";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

export const signupAddPhoto = (props) => {
    return async (dispatch, getState) => {
        const { accessToken, currentUser } = getState().auth;
        dispatch(authActions.setLoading(true))

        const { img, Imgdetail } = props

        var formData = new FormData();
        var file;
        await fetch(img).then(res => res.blob()).then(blob => {
            file = new File([blob], Imgdetail.name, { type: Imgdetail.type });
            formData.append('image', file);
        })
        try {
            const url = BASE_URL + "/app/journey/add_photo";
            const response = await axios.post(url, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        localStorage.setItem('matrimonialAuthCredentials', JSON.stringify(currentUser));
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

export const signupSuggestionRequestSent = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/request/sendmultyrequest";
            const response = await axios.post(url, props, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

export const signupSuggestionList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/suggetion_user_list?page=1&limit=50";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

//----------------------------------------------------------------//
//----------- Select Dropdown lists for Login Stages -------------//
//----------------------------------------------------------------//

// signupEducationList
export const signupEducationList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_education_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupExperienceList
export const signupExperienceList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_work_experiance_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupAccountList
export const signupAccountList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_accouny_type_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupIncomeList
export const signupIncomeList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_income_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupMaritalList
export const signupMaritalList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_marrital_status_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

export const signupMotherTongueList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_mother_tongue_language";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupBodyTypeList
export const signupBodyTypeList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_body_type_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupHabitList
export const signupHabitList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_habits_detail";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupCountryList
export const signupCountryList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_country_state";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}

// signupStateList
export const signupStateList = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(authActions.setLoading(true))
        try {
            const url = "https://countriesnow.space/api/v0.1/countries/states";
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(authActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(authActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(authActions.setLoading(false))
    }
}