import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Header from "../../Components/Header"
import PremiumUser from "./PremiumUser"

export default function ChatPage() {
  const [isPrimiumUser, setisPrimiumUser] = useState(false)
  const [userData, setUserData] = useState({});

  const userDetails = useSelector((state) => state.home.userDetails);
  useEffect(() => {
    if (userDetails) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  return (
    <>
      <Header />
      <PremiumUser /> 
    </>
  )
}
