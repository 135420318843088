import React from 'react'
import { BiMessageDots } from 'react-icons/bi'
import styles from "../styles.module.css"


const EmptyChatMessenger = () => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div
                className="rounded-circle"
                style={{
                    width: 218,
                    height: 218,
                    background: "#F9F9F9",
                }}
            ></div>
            <div
                className="position-absolute rounded-circle"
                style={{
                    width: 192,
                    height: 192,
                    background: "#F2F2F2",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
                }}
            >
                <div
                    className={`${styles.chatMessageIcon} d-flex flex-column justify-content-center align-items-center h-100`}
                >
                    <BiMessageDots
                        size={70}
                    />
                    <small
                        className="text-center fw-semibold mt-3"
                        style={{
                            width: 120,
                            height: 33,
                            // fontSize: 10,
                            color: "#777777",
                        }}
                    >
                        start a Conversation
                    </small>
                </div>
            </div>
        </div>
    )
}

export default EmptyChatMessenger