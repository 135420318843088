import { createSlice } from '@reduxjs/toolkit'


const INITIAL_STATE = {
    loading: true,
    refreshToken: null,
    phonenumber: null,
    currentUser: null,
}

export const matchesReducer = createSlice({
    name: 'matches',
    initialState: INITIAL_STATE,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
})

export const matchesActions = matchesReducer.actions;

export default matchesReducer;