import React, { useState } from "react"
import { Col, Form, Modal, ProgressBar, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupCountryList, signupStageFour } from "../../redux/actions/authAction"
import { useEffect } from "react"

export default function StageFour({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    resident_country: "",
    resident_state: '',
    resident_city: '',
    home_country: "",
    home_state: '',
    home_city: '',
    residentialarea: '',

  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [countryData, setCountryData] = useState([])
  const [stateData, setStateData] = useState([])
  const [homeStateData, setHomeStateData] = useState([])

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    if (fields.resident_country && fields.resident_state && fields.resident_city && fields.home_country && fields.home_state && fields.home_city) {
      setError("")
      setLoading(true)
      let data = {
        resident_country: fields.resident_country,
        resident_state: fields.resident_state,
        resident_city: fields.resident_city,
        home_country: fields.home_country,
        home_state: fields.home_state,
        home_city: fields.home_city,
        residentialstatus: fields.residentialarea,
      }
      dispatch(signupStageFour(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        resident_country: !fields.resident_country,
        resident_state: !fields.resident_state,
        resident_city: !fields.resident_city,
        home_country: !fields.home_country,
        home_state: !fields.home_state,
        home_city: !fields.home_city,
        residentialarea: !fields.residentialarea,
      })
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    setFiels({ ...fields, [name]: value })
  }

  useEffect(() => {
    dispatch(signupCountryList()).then((res) => {
      if (res.status === 200) {
        setCountryData(res?.data?.data)
      }
    })
  }, [])

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-block">
        <ProgressBar now={30} />
      </Modal.Header>
      <Modal.Body className="pt-3 px-4">
        <Form onSubmit={handleSubmit}>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="m-0">Resident Country</Form.Label>
                <Form.Select
                 
                  name="resident_country"
                  className={fields.resident_country === "" && "selectplaceholder"}
                  onChange={(e) => {
                    handleChange(e)
                    let arr = countryData?.find(item => item.country === e.target.value)
                    setStateData(arr.states)
                  }}
                  required
                >
                   
                  <option defaultValue={'Country'} value="" selected disabled hidden>
                    Country
                  </option>
                  {
                    countryData.map((a,id) => (
                      <option key={id} value={a.country}>{a.country}</option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Resident State</Form.Label>
                <Form.Select 
                    
                  name="resident_state"
                  className={fields.resident_state === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  
                  <option defaultValue={'State'} value="" selected disabled hidden>
                    State
                  </option>
                  {
                    stateData.map((a, id) => (
                      <option key={id} value={a}>{a}</option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Resident City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  name="resident_city"
                  value={fields.resident_city}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className="m-0">Home Country</Form.Label>
                <Form.Select
               
                  name="home_country"
                  className={fields.home_country === "" && "selectplaceholder"}
                  onChange={(e) => {
                    handleChange(e)
                    let arr = countryData?.find(item => item.country === e.target.value)
                    setHomeStateData(arr.states)
                  }}
                  required
                >
                  
                  <option defaultValue={'Country'} value="" selected disabled hidden>
                  Country
                  </option>
                  {
                    countryData.map((a,id) => (
                      <option key={id} value={a.country}>{a.country}</option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Home State</Form.Label>
                <Form.Select
               
                  name="home_state"
                  className={fields.home_state === "" && "selectplaceholder"}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  
                  <option defaultValue={'State'} value="" selected disabled hidden>
                    State
                  </option>
                  {
                    homeStateData.map((a,id) => (
                      <option key={id} value={a}>{a}</option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label className="m-0">Home City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  name="home_city"
                  value={fields.home_city}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <br />
            </Col>
          </Row>
          <Form.Group>
            <Form.Label className="m-0">Residential Status <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Control
              type="text"
              placeholder="Residential Area"
              name="residentialarea"
              value={fields.residentialarea}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <br />

          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.resident_country || !fields.resident_state || !fields.resident_city || !fields.home_country || !fields.home_state || !fields.home_city} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
