import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Services/config';
import { editProfileActions } from '../reducers/editProfileReducer';
import { logout } from './authAction';

// Fetch UserToken from store
const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))

const token = currentuser?.stsTokenManager

// Global Headers
const headers = {
    headers: {
        Authorization: `Bearer ${token?.accessToken}`,
    },
};

// Handle error messages
const getMessage = error => {
    let message = null;
    if (error && typeof error === 'object' && Object.keys(error).length) {
        if (error?.response && error?.response?.data) {
            if (error?.response?.data?.status && error?.response?.data?.status?.message) {
                if ((error?.response?.data?.status?.code === 400 && error?.response?.data?.status?.message === "User Not Found") || error?.response?.data?.status?.code === 401) {
                    logout()
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
                message = error?.response?.data?.status?.message;
            }
        }
    }

    if (!message) {
        message = 'Unable to proceed at the moment!!';
    }

    return message;
};

export const getMyProfileData = () => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/my_profile";
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setUserProfileDetails(response?.data?.data))
                        dispatch(editProfileActions.setLoading(false))
                        break
                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const editProfilePicture = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))

        const { img, Imgdetail } = props

        var formData = new FormData();
        var file;
        await fetch(img).then(res => res.blob()).then(blob => {
            file = new File([blob], Imgdetail.name, { type: Imgdetail.type });
            formData.append('image', file);
        })
        try {
            const url = BASE_URL + "/app/profile/edit_profile_picture";
            const response = await axios.post(url, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const addMorePhotos = (props) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))

        // const { img, Imgdetail } = props

        var formData = new FormData();
        for (let i = 0; i < props.length; i++) {
            formData.append('image', props[i])
        }
        // formData.append('image', props);
        // var file;
        // await fetch(img).then(res => res.blob()).then(blob => {
        //     file = new File([blob], Imgdetail.name, { type: Imgdetail.type });
        //     formData.append('image', file);
        // })
        try {
            const url = BASE_URL + "/app/profile/add_images";
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editFatherDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_father_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editMotherDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_mother_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editBrotherDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_brother_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editSisterDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_sister_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editContactDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_contact_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editPersonalDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_personal_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editHoroscopeDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_horoscope_info";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const deletePhotos = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + `/app/profile/deleteimages?image_id=${data}`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        dispatch(getMyProfileData())
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}


// =========================================================================
// Preferences
// =========================================================================

export const getPreferencesDetails = () => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/get_preferance";
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setUserPreferencesDetails(response?.data?.data))
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}

export const editPreferencesDetails = (data) => {
    return async (dispatch, getState) => {
        const { accessToken } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/profile/edit_preferance";
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        toast.success(response?.data?.status?.code === 200 && "Preferences update successfully");
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}


// =========================================================================
// Settings
// =========================================================================

export const deleteProfile = () => {
    return async (dispatch, getState) => {
        const { accessToken, currentUserID } = getState().auth;
        dispatch(editProfileActions.setLoading(true))
        try {
            const url = BASE_URL + `/app/profile/deleteprofile?userid=${currentUserID}`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(editProfileActions.setLoading(false))
                        return response;

                    default:
                        toast.error(response?.data?.message);
                        dispatch(editProfileActions.setLoading(false))
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
        dispatch(editProfileActions.setLoading(false))
    }
}