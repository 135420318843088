import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";

export default function Footer() {
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="pt-3 mt-4 text-white text-center">
            <p className={`${styles.footerText}`}>
              By continuing, you agree to our{" "}
              <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="text-white"> <u>Terms & Service</u></a> <br />
              <span>and</span>{" "}
              <a target="_blank" href="https://spouze.co/PrivacyPolicy.html" rel="noreferrer" className="text-white"> <u>Privacy Policy</u></a>.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
}
