import moment from 'moment/moment'
import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import CustomButton from '../../../Components/CustomButton'
import { editHoroscopeDetails, getMyProfileData } from '../../../redux/actions/editProfileAction'

const HoroscopeDetails = ({ setModalShow, myProfileData }) => {
    const dispatch = useDispatch()
    const [fields, setFiels] = useState({
        Horoscope: myProfileData?.PersonalDetail?.Horoscope || '',
    })
    const [horoscope, setHoroscope] = useState(false)
    const [birthDate, setBirthDate] = useState(new Date(myProfileData?.PersonalDetail?.DateofBirth) || '');

    const [loading, setLoading] = useState(false)

    // Handle Form Submition
    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setLoading(true)
            let data = {
                DateofBirth: birthDate && birthDate?.getTime(),
                Horoscope: fields.Horoscope
            }
            dispatch(editHoroscopeDetails(data)).then((res) => {
                if (res.status === 200) {
                    dispatch(getMyProfileData())
                    setModalShow(false)
                }
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        } catch (error) {
            console.log("error", error)
        }
    }

    // Handle Field Values
    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "Horoscope" && value === "Yes") {
            setHoroscope(true)
        } else {
            setFiels({ ...fields, [name]: value })
        }
    }
    return (
        <>
            <Modal.Header className="border-0 pb-1 align-items-baseline" closeButton />
            <Modal.Body className="pt-0 px-4">
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label >Date Of Birth</Form.Label>
                        <ReactDatePicker
                            selected={birthDate}
                            onChange={(date) => setBirthDate(date)}
                            // peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Click to select a date"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            minDate={moment().subtract(100, "years")._d}
                            maxDate={moment().subtract(18, "years")._d}
                        />
                    </Form.Group>
                    <br />

                    <Form.Group>
                        <Form.Label className="m-0">Horoscope</Form.Label>
                        {/* {horoscope ?
                            <> */}
                        <Form.Control
                            type="text"
                            placeholder="Your Horoscope"
                            name="Horoscope"
                            value={fields.Horoscope}
                            onChange={(e) => handleChange(e)}
                        />
                        {/* </> : <>
                                <Form.Select
                                    name="Horoscope"
                                    value={fields.Horoscope}
                                    className={fields.Horoscope === "" && "selectplaceholder"}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value="" selected disabled hidden>
                                        Horoscope Must for Marriage?
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Form.Select>
                            </>} */}
                    </Form.Group>
                    <Form.Group className="text-center my-5">
                        <CustomButton btntext="Save" loading={loading} />
                    </Form.Group>
                </Form>
            </Modal.Body>
        </>
    )
}

export default HoroscopeDetails