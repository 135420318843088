import React, { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import { signupSisterDetails } from "../../redux/actions/authAction"

export default function AddSisterDetails(props) {
  const dispatch = useDispatch()
  const [fields, setFiels] = useState({
    sistername: "",
    designation: '',
    companyname: '',
    sisterincome: '',
    status: ''
  })

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    if (fields.sistername) {
      setError("")
      setLoading(true)
      let data = {
        sistername: fields.sistername,
        designation: fields.designation,
        companyname: fields.companyname,
        sisterincome: parseInt(fields.sisterincome),
        status: fields.status,
      }
      dispatch(signupSisterDetails(data)).then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.familyDetailJourney[0]?.isSelected && !res.data?.data?.familyDetailJourney[0]?.journyCompleted) {
            props.setModalStage(10);
          } else if (res.data?.data?.familyDetailJourney[1]?.isSelected && !res.data?.data?.familyDetailJourney[1]?.journyCompleted) {
            props.setModalStage(11)
          } else if (res.data?.data?.familyDetailJourney[2]?.isSelected && !res.data?.data?.familyDetailJourney[2]?.journyCompleted) {
            props.setModalStage(12)
          } else if (res.data?.data?.familyDetailJourney[3]?.isSelected && !res.data?.data?.familyDetailJourney[3]?.journyCompleted) {
            props.setModalStage(13)
          } else {
            props.setModalStage(14);
          }
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        sistername: !fields.sistername,
        designation: !fields.designation,
        status: !fields.status,
      })
      setLoading(false)
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "sisterincome") {
      setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
    } else {
      setFiels({ ...fields, [name]: value })
    }
  }

  return (
    <>
      <Modal.Header className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group >
            <Form.Label className="m-0">Sister’s Name</Form.Label>
            <Form.Control type="text" name="sistername"
              placeholder='Sister’s name' value={fields.sistername}
              onChange={(e) => handleChange(e)} required />
          </Form.Group>
          <br />
          <Form.Group >
            <Form.Label className="m-0">Designation <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Control type="text" name="designation"
              placeholder='Designation' value={fields.designation}
              onChange={(e) => handleChange(e)} />
          </Form.Group>
          <br />
          <Form.Group >
            <Form.Label className="m-0">Company Name <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Control type="text" name="companyname"
              placeholder='Company Name' value={fields.companyname}
              onChange={(e) => handleChange(e)} />
          </Form.Group>
          <br />
          <Form.Group >
            <Form.Label className="m-0">Sister Income <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Control type="text" name="sisterincome"
              placeholder='$000.00' value={fields.sisterincome}
              onChange={(e) => handleChange(e)} />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label className="m-0">Status <small className='text-grey'>(Optional)</small></Form.Label>
            <Form.Select
              name="status"
              className={fields.community === "" && "selectplaceholder"}
              onChange={(e) => handleChange(e)}
            >
              <option value="" selected disabled hidden>
                Select Status
              </option>
              <option value="married">married</option>
              <option value="unmarried">unmarried</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.sistername} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
