import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TabCard from '../../Components/TabCard'
import ToggleSwitch from '../../Components/ToggleSwitch'
import { getNotificationSettingData, setNotificationSetting } from '../../redux/actions/settingAction'

export default function Notification() {
    const [visiblity, setVisibility] = useState({
        "newInvitations": false,
        "newAccepts": false,
        "recommondations": false,
        "recentVisitors": false,
        "pendingVisitors": false
    })
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNotificationSettingData())
    }, [])

    const settingData = useSelector(state => state.setting.notificationSetting)

    useEffect(() => {
        if (settingData) {
            setVisibility(settingData)
        }
    }, [settingData])

    const handleChange = async (e) => {
        const { name, checked } = e.target

        await dispatch(setNotificationSetting({ ...visiblity, [name]: checked }))
        await setVisibility({ ...visiblity, [name]: checked })
    }
    return (
        <>
            <TabCard headertext='Notification'>
                <Card.Body>
                    <div className="p-4 pb-2">
                        <div className="d-flex justify-content-between mb-4">
                            <div>
                                <h6 className="m-0 fs-17">New Invitations</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="NewInvitations" name="newInvitations" checked={visiblity.newInvitations} handleChange={handleChange} />
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">New Acceptance</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="New Accepts" name="newAccepts" checked={visiblity.newAccepts} handleChange={handleChange} />
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">Recommendations</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="Recommendations" name="recommondations" checked={visiblity.recommondations} handleChange={handleChange} />
                            </div>
                        </div>
                        <hr />
                        {/* <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">New Matches</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="New Matches" />
                            </div>
                        </div>
                        <hr /> */}
                        {/* <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">Shortlisted</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="Shortlisted" />
                            </div>
                        </div>
                        <hr /> */}
                        <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">Recent Visitors</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="Recent Visitors" name="recentVisitors" checked={visiblity.recentVisitors} handleChange={handleChange} />
                            </div>
                        </div>
                        <hr />
                        {/* <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">Premium Matches</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="Premium Matches" />
                            </div>
                        </div>
                        <hr /> */}
                        {/* <div className="d-flex justify-content-between my-4">
                            <div>
                                <h6 className="m-0 fs-17">Pending Invitations</h6>
                            </div>
                            <div>
                                <ToggleSwitch label="Pending Invitations" name="pendingVisitors" checked={visiblity.pendingVisitors} handleChange={handleChange} />
                            </div>
                        </div>
                        <hr /> */}
                    </div>
                </Card.Body>
            </TabCard>
        </>
    )
}
