import React, { useEffect, useState } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import CustomButton from "../../Components/CustomButton"
import styles from "./styles.module.css"
import { signupAccountList, signupStageOne } from "../../redux/actions/authAction"

export default function StageOne({ handleModalStage }) {
  const dispatch = useDispatch()

  const [selectedAccType, setSelectedAccType] = useState(null)

  const [loading, setLoading] = useState(false)
  const [accountData, setAccountData] = useState([])

  // Handle Form Submition
  const handleacctype = () => {

    if (selectedAccType) {
      setLoading(true)
      let data = {
        accounttype: selectedAccType,
      }
      dispatch(signupStageOne(data)).then((res) => {
        if (res.status === 200) {
          handleModalStage()
        }
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      console.log("select AccType")
    }
  }

  useEffect(() => {
    dispatch(signupAccountList()).then((res) => {
      setAccountData(res?.data?.data)
    })
  }, [])

  // console.log('accountData :>> ', accountData);

  return (
    <>
      <Modal.Header className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div>
            <p className="fs-4 fw-semibold">Account</p>
          </div>
        </div>
        <div>
          <Row>
            {accountData.map((data,id) => {
              return (
                
                <Col sm={4} xs={6} className="text-center mb-4" key={id}>
                  <div
                    className={
                      selectedAccType === data.type
                        ? styles.selectedaccounttype
                        : styles.accounttype
                    }
                    onClick={() => setSelectedAccType(data.type)}
                    style={{
                      backgroundImage: `url(${data.picture})`,
                      backgroundPositionY: "center"
                    }}
                  >
                  </div>
                  {data.type === "Client" ? (
                    <div>
                      {data.type}
                      {/* <br />
                      <small>(Marriage Bureau)</small> */}
                    </div>
                  ) : (
                    data.type
                  )}
                </Col>
               
              )
            })}
          </Row>
        </div>
        <div className="text-center mt-3">
          <CustomButton
            btntext="Continue"
            handleClick={handleacctype}
            loading={loading}
          />
        </div>
      </Modal.Body>
    </>
  )
}
