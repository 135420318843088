import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Services/config';
import { settingActions } from '../reducers/settingReducer';
import { logout } from './authAction';

// Fetch UserToken from store
const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))

const token = currentuser?.stsTokenManager

// Global Headers
const headers = {
    headers: {
        Authorization: `Bearer ${token?.accessToken}`,
    },
};

// Handle error messages
const getMessage = error => {
    let message = null;
    if (error && typeof error === 'object' && Object.keys(error).length) {
        if (error?.response && error?.response?.data) {
            if (error?.response?.data?.status && error?.response?.data?.status?.message) {
                if ((error?.response?.data?.status?.code === 400 && error?.response?.data?.status?.message === "User Not Found") || error?.response?.data?.status?.code === 401) {
                    logout()
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
                message = error?.response?.data?.status?.message;
            }
        }
    }

    if (!message) {
        message = 'Unable to proceed at the moment!!';
    }

    return message;
};

export const getProfileSettingData = () => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(settingActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_profile_setting";
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(settingActions.setLoading(false))
                        dispatch(settingActions.setProfileSetting(response?.data?.data))
                        break
                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const setProfileSetting = (props) => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(settingActions.setLoading(true))
        try {
            const url = BASE_URL + `/app/setting/edit_profile_setting`;
            const response = await axios.post(url, props, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(settingActions.setLoading(false))
                        toast.success("Settings updated successfully")
                        return response
                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const getNotificationSettingData = () => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(settingActions.setLoading(true))
        try {
            const url = BASE_URL + "/app/setting/get_notification_setting";
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(settingActions.setLoading(false))
                        dispatch(settingActions.setNotificationSetting(response?.data?.data))
                        break
                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const setNotificationSetting = (props) => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(settingActions.setLoading(true))
        try {
            const url = BASE_URL + `/app/setting/edit_notification_setting`;
            const response = await axios.post(url, props, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        dispatch(settingActions.setLoading(false))
                        toast.success("Settings updated successfully")
                        return response
                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}