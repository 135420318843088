import { createSlice } from '@reduxjs/toolkit'


const INITIAL_STATE = {
    loading: true,
    refreshToken: null,
    phonenumber: null,
    currentUser: null,
}

export const inboxReducer = createSlice({
    name: 'inbox',
    initialState: INITIAL_STATE,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
})

export const inboxActions = inboxReducer.actions;

export default inboxReducer;