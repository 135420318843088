import React from 'react'
import styles from "./styles.module.css"
import { useNavigate } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'


function PaymentCancel() {
    const navigate = useNavigate()

    return (
        <>
            <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: "95vh" }}>
                <div className="mb-4 text-center">
                    <AiFillCloseCircle size={75} />
                </div>
                <div className="text-center">
                    <h5 >Sorry, Something got wrong. Try again !</h5>
                    <button className={`${styles.paybtn} mt-3`} onClick={() => navigate("/")}>Back Home</button>
                </div>
            </div>
        </>
    )
}

export default PaymentCancel