import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import CustomButton from '../../../Components/CustomButton'
import { editMotherDetails, getMyProfileData } from '../../../redux/actions/editProfileAction'

const MotherDetails = ({ setModalShow, myProfileData }) => {
    const dispatch = useDispatch()

    const [fields, setFiels] = useState({
        mothername: myProfileData?.FamilyDetails?.MotherDetails?.MotherName || "",
        designation: myProfileData?.FamilyDetails?.MotherDetails?.Designation || '',
        companyname: myProfileData?.FamilyDetails?.MotherDetails?.CompanyName || '',
        motherincome: myProfileData?.FamilyDetails?.MotherDetails?.MotherIncome || '',

    })
    const [loading, setLoading] = useState(false)

    // Handle Form Submition
    async function handleSubmit(e) {
        e.preventDefault()

        setLoading(true)
        let data = {
            MotherName: fields.mothername,
            Designation: fields.designation,
            CompanyName: fields.companyname,
            MotherIncome: parseInt(fields.motherincome)
        }
        dispatch(editMotherDetails(data)).then((res) => {
            if (res.status === 200) {
                dispatch(getMyProfileData())
                setModalShow(false)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    // Handle Field Values
    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "motherincome") {
            setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        } else {
            setFiels({ ...fields, [name]: value })
        }
    }
    return (
        <>
            <Modal.Header className="border-0 pb-0 align-items-baseline" closeButton />
            <Modal.Body className="pt-0 px-4">
                <Form onSubmit={handleSubmit}>
                    <Form.Group >
                        <Form.Label className="m-0">Mother’s Name</Form.Label>
                        <Form.Control type="text" name="mothername"
                            placeholder='Mother’s name' value={fields.mothername}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Designation</Form.Label>
                        <Form.Control type="text" name="designation"
                            placeholder='Designation' value={fields.designation}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Company Name</Form.Label>
                        <Form.Control type="text" name="companyname"
                            placeholder='Company Name' value={fields.companyname}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Mother Income</Form.Label>
                        <Form.Control type="text" name="motherincome"
                            placeholder='$000.00' value={fields.motherincome}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <Form.Group className="text-center my-5">
                        <CustomButton btntext="Continue" loading={loading} />
                    </Form.Group>
                </Form>
            </Modal.Body>

        </>
    )
}

export default MotherDetails