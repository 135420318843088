import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import {
  verifyOTP,
  generateRecaptcha,
  setPhoneNumber,
  loginToDatabase,
} from "../../redux/actions/authAction";
import CustomButton from "../../Components/CustomButton";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
/* global grecaptcha */

export default function OtpModal(props) {
  const [fields, setFiels] = useState({
    value: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    disable: true,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const handleClose = () => { props.setModalStage(-2); }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle OTP Field Values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFiels({ ...fields, [name]: value.replace(/[^\d]/g, "") });
  };

  // Handle OTP Submition
  async function handleSubmit(e) {
    e.preventDefault();
    let otp = fields.value.concat(
      fields.otp1,
      fields.otp2,
      fields.otp3,
      fields.otp4,
      fields.otp5,
      fields.otp6
    );
    try {
      setError("");
      setLoading(true);
      await verifyOTP(otp).then((status) => {
        if (status) {
          dispatch(loginToDatabase()).then((res) => {
            if (res?.data?.isCompleted) {
              navigate("/", { replace: true });
            } else if (res?.data?.stage) {
              props.setModalStage(res?.data?.stage);
            }
          });
        }
      });
      setLoading(false);
    } catch (error) {
      setError("Failed to sign in");
    }
  }

  // Focus move to the next step after entering the value
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (
      (elmnt.keyCode >= 48 && elmnt.keyCode <= 57) ||
      (elmnt.keyCode >= 96 && elmnt.keyCode <= 105)
    ) {
      // console.log("next")
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  // fetch Phone Number from store
  const phoneNumber = useSelector((state) => state.auth.phonenumber);

  // Resend the OTP
  async function resendOTP() {
    try {
      // const auth = getAuth();
      // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {size: "invisible"},auth);

      // window.recaptchaVerifier.render().then(function (widgetId) {
      //   grecaptcha.reset(widgetId)
      // })
      await generateRecaptcha()
      // grecaptcha.reset(window.recaptchaWidgetId);
      // grecaptcha.reset()
      dispatch(setPhoneNumber(phoneNumber));
    } catch (error) { 
    }
  }

  return (
    <>
      <Modal.Header closeButton onHide={handleClose} className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className={`${styles.loginlogo} bg-lightgrey`}>
            <img src="/Assest/icons/spouzeLogo.png" alt="" className="w-100" />
          </div>
          <div className="pt-3 mt-4 text-black text-center">
            <p className={`${styles.footerText}`}>
              You will receive an SMS with verification PIN to <br />{phoneNumber}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <Form onSubmit={handleSubmit}>
            <Form.Group id="MobileNumber">
              <Form.Label>Please enter the OTP </Form.Label>
              <div className="d-flex justify-content-center">
                <Form.Control
                  name="otp1"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={fields.otp1}
                  // onKeyPress={this.keyPressed}
                  onChange={(e) => handleChange(e)}
                  tabIndex="1"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                  style={{ width: "10%", marginRight: "10px" }}
                />
                <Form.Control
                  name="otp2"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={fields.otp2}
                  // onKeyPress={this.keyPressed}
                  onChange={(e) => handleChange(e)}
                  tabIndex="2"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                  style={{ width: "10%", marginRight: "10px" }}
                />
                <Form.Control
                  name="otp3"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={fields.otp3}
                  // onKeyPress={this.keyPressed}
                  onChange={(e) => handleChange(e)}
                  tabIndex="3"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                  style={{ width: "10%", marginRight: "10px" }}
                />
                <Form.Control
                  name="otp4"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={fields.otp4}
                  // onKeyPress={this.keyPressed}
                  onChange={(e) => handleChange(e)}
                  tabIndex="4"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                  style={{ width: "10%", marginRight: "10px" }}
                />
                <Form.Control
                  name="otp5"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={fields.otp5}
                  // onKeyPress={this.keyPressed}
                  onChange={(e) => handleChange(e)}
                  tabIndex="5"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                  style={{ width: "10%", marginRight: "10px" }}
                />
                <Form.Control
                  name="otp6"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={fields.otp6}
                  // onKeyPress={this.keyPressed}
                  onChange={(e) => handleChange(e)}
                  tabIndex="6"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                  style={{ width: "10%", marginRight: "10px" }}
                />
              </div>
            </Form.Group>
            <Form.Group className="text-center my-5">
              <CustomButton loading={loading} btntext="SIGN IN" />
            </Form.Group>
          </Form>
        </div>
        <div
          className="text-grey text-center mt-4 cursor-pointer"
          onClick={resendOTP}
        >
          Resend Code?
        </div>
        <div id="recaptcha-container"></div>
        <div id="recaptcha-container-resend" />
      </Modal.Body>
    </>
  );
}
