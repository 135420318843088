import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import styles from "./styles.module.css";
import { GoDotFill } from "react-icons/go";
import { useDispatch } from "react-redux";
import {
  matchesCategory,
  matchesData,
} from "../../redux/actions/matchesAction";
import Loader from "../../Components/Loader";
import { sendRequest, withdrawRequest } from "../../redux/actions/inboxAction";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "./Filter";
import CustomEditModal from "../../Components/CustomEditModal";
import { Col, Container, Row } from "react-bootstrap";
import { Pagination } from "@mui/material";
import { BiSliderAlt } from "react-icons/bi";
import ViewProfile from "./ViewProfile";
import {ConvertInchToFeetAndInch} from "../../Utils/HeightUnitsConvertUtils"


export default function Matches() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [MatchesList, setMatchesList] = useState([]);
  const [MatchesCategoryList, setMatchesCategoryList] = useState([]);
  const [currentOnline, setCurrentOnline] = useState(0);
  const [selctedMatchesCategoryId, setSelctedMatchesCategoryId] = useState(
    "6353b5f50d631f876ce07270"
  );
  const [selctedUserId, setSelctedUserId] = useState("");

  const [totalpages, setTotalpages] = useState(1);
  const [page, setPage] = useState(1);

  function getMatches(category) {
    // console.log(category)
    setIsLoading(true);
    setSelctedMatchesCategoryId(category);
    dispatch(
      matchesData({
        category,
        page: category === selctedMatchesCategoryId ? page : 1,
      })
    ).then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        setMatchesList(res?.data?.data?.list);
        setTotalpages(res?.data?.data?.pages);
      }
    });
    setPage(category === selctedMatchesCategoryId ? page : 1);
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(matchesCategory()).then((res) => {
      setIsLoading(false);
      setMatchesCategoryList(res?.data?.data?.list);
      setCurrentOnline(res?.data?.data?.online_count);
    });
    if (location.state) {
      getMatches(location.state);
      window.history.replaceState({}, document.title);
    } else {
      getMatches(selctedMatchesCategoryId);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      getMatches(selctedMatchesCategoryId);
    }
  }, [page]);

  return (
    <>
      <Header />
      {selctedUserId ? (
        <ViewProfile
          selctedUserId={selctedUserId}
          setSelctedUserId={setSelctedUserId}
        />
      ) : (
        <section>
          <div
            className="py-2"
            style={{ boxShadow: "0px 3px 12px 2px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="container text-center">
              <div className={`${styles.subheader_scrollbar}`}>
                <button
                  className={`${styles.subHeaderBtn}`}
                  onClick={() => {
                    setSelctedMatchesCategoryId("search");
                    setShowFilterModal(true);
                  }}
                  style={
                    selctedMatchesCategoryId === "search"
                      ? { backgroundColor: "#F0003B", color: "#FFFFFF" }
                      : {}
                  }
                >
                  <BiSliderAlt
                  size={25}
                  className="me-1 theme-color"
                  style={
                    selctedMatchesCategoryId === "search"
                      ? { color: "#FFFFFF" }
                      : {}
                  }/>
                  Search
                </button>
                {MatchesCategoryList?.map((item,index) => {
                  return (
                    <>
                     
                      <button
                        key={index}
                        className={`${styles.subHeaderBtn}`}
                        onClick={() => getMatches(item?._id)}
                        style={
                          item?._id === selctedMatchesCategoryId
                            ? {
                                backgroundColor: "#F0003B",
                                color: "#FFFFFF",
                              }
                            : {}
                        }
                      >
                        {item?.matches_category === "Online"
                          ? item?.matches_category + ` (${currentOnline})`
                          : item?.matches_category}
                      </button>
                    
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <Container>
            <Row className="m-0 p-0">
              {isLoading ? (
                <Loader />
              ) : MatchesList.length > 0 ? (
                <>
                  {MatchesList?.map((item,index) => {
                    return (
                      <Col lg={4} md={6} sm={12} className="my-3" key={index}>
                        <div
                          className="position-relative  m-auto"
                          style={{ borderRadius: 20, width: "85%" }}
                          // onClick={(e) => {
                          //   e.preventDefault()
                          //   navigate("/viewprofile", { state: item.user_id })
                          // }}
                        >
                          <div
                            className="cursor-pointer"
                            style={{
                              borderRadius: 20,
                              overflow: "hidden",
                              boxShadow: "rgb(0 0 0 / 24%) 1px 3px 5px 0px",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelctedUserId(item.user_id);
                              // navigate("/viewprofile", { state: item.user_id })
                            }}
                          >
                            <img
                              className="card-img-top"
                              src={
                                item?.user_details?.user_profile_pic ||
                                "/Assest/image/homepage-userphoto.png"
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/Assest/image/homepage-userphoto.png";
                              }}
                              alt="."
                              height={385}
                            />
                          </div>
                          <div
                            className={`${styles.matchesCardText} text-white w-100 p-3`}
                          >
                            <h6 className="">
                              {item?.user_details?.user_name}{" "}
                            </h6>
                            <small className="card-text d-block mb-1">
                              {item?.user_details?.age &&
                              item?.user_details?.age !== 0
                                ? item?.user_details?.age + " yrs"
                                : ""}

                              {item?.user_details?.height &&
                                (item?.user_details?.height !== 0
                                  ? ", " + `${ConvertInchToFeetAndInch(item?.user_details?.height)[0]}'${ConvertInchToFeetAndInch(item?.user_details?.height)[1]}"`
                                  : `0'0"`)}

                              {item?.user_details?.education &&
                                ((item?.user_details?.age &&
                                  item?.user_details?.age !== 0) ||
                                (item?.user_details?.height &&
                                  item?.user_details?.height !== 0) ? (
                                  <>
                                    <GoDotFill className="ms-1" />{" "}
                                    {item?.user_details?.education}
                                  </>
                                ) : (
                                  <>{item?.user_details?.education}</>
                                ))}
                            </small>
                            <small className="card-text">
                              {item?.about_me?.mother_toungue &&
                                item?.about_me?.mother_toungue + " "}
                              {item?.user_details?.resident_city && (
                                <>
                                  {item?.about_me?.mother_toungue && (
                                    <GoDotFill className="me-1" />
                                  )}
                                  {item?.user_details?.resident_city}
                                </>
                              )}
                              {item?.user_details?.country}
                            </small>
                            <div className="d-flex justify-content-between mt-3">
                              <div className="w-100 text-center">
                                {item?.isYourFriend ? (
                                  <button className={styles.sendbtn}>
                                    Accepted
                                  </button>
                                ) : item?.isRequestSended ? (
                                  <button
                                    className={styles.sendbtn}
                                    onClick={() => {
                                      dispatch(
                                        withdrawRequest(item?.user_id)
                                      ).then((res) => {
                                        if (res.status === 200) {
                                          dispatch(
                                            matchesData({
                                              selctedMatchesCategoryId,
                                              page,
                                            })
                                          ).then((res) => {
                                            if (res.status === 200) {
                                              setMatchesList(
                                                res?.data?.data?.list
                                              );
                                            }
                                          });
                                        }
                                      });
                                    }}
                                  >
                                    Withdraw Request
                                  </button>
                                ) : (
                                  <button
                                    className={styles.sendbtn}
                                    onClick={() => {
                                      dispatch(sendRequest(item?.user_id)).then(
                                        (res) => {
                                          if (res.status === 200) {
                                            toast.success(
                                              "Request Sent Successfully"
                                            );
                                            dispatch(
                                              matchesData({
                                                selctedMatchesCategoryId,
                                                page,
                                              })
                                            ).then((res) => {
                                              if (res.status === 200) {
                                                setMatchesList(
                                                  res?.data?.data?.list
                                                );
                                              }
                                            });
                                          }
                                        }
                                      );
                                    }}
                                  >
                                    Connect
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </>
              ) : (
                <>
                  <Col sm={12}>
                    <h4 className="text-center mt-5">No Matches Found.</h4>
                  </Col>
                </>
              )}
            </Row>
          </Container>
          {MatchesList?.length > 0 && totalpages > 1 && (
            <Row className="position-sticky bottom-0 mb-3 w-100 m-0 p-0">
              <Col className="d-flex justify-content-center m-0 p-0">
                <Pagination
                  count={totalpages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={(event, value) => {
                    setIsLoading(true);
                    setPage(value);
                  }}
                />
              </Col>
            </Row>
          )}

          {
            <CustomEditModal
              show={showFilterModal}
              size="xl"
              onHide={() => setShowFilterModal(false)}
            >
              <Filter
                setShowFilterModal={setShowFilterModal}
                setMatchesList={setMatchesList}
              />
            </CustomEditModal>
          }
        </section>
      )}
    </>
  );
}
