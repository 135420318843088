import React from "react"
import { Accordion, Card, Col, Container, Row } from "react-bootstrap"
import { BsCreditCard2Back, BsCreditCard2Front } from "react-icons/bs"
import { TbWorld } from "react-icons/tb"
import Header from "../../Components/Header"
import styles from "./styles.module.css"

export default function MembershipPay() {
    return (
        <>
            <main>
                {/* <Header /> */}
                <Container>
                    <Row className="mt-3">
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card body className={styles.paymentCards}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h5 className="mb-0 text-grey">1 Month</h5>
                                        <small style={{ color: "#0FA958" }}>
                                            (36% off applied)
                                        </small>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <del className="text-grey me-3">$200.00 </del>
                                        <h5 className="mb-0">$153.00</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <h5 className="fw-semibold m-0">
                                        Grand Total <small className="text-grey">(incl GST)</small>
                                    </h5>
                                    <h5 className="fw-semibold m-0">$153.00</h5>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={{ span: 8, offset: 2 }}>
                            <h5 className="fw-semibold" style={{ color: "#FF648A" }}>
                                PAYMENT MODES
                            </h5>
                        </Col>
                    </Row>

                    <Row className="mt-1">
                        <Col md={{ span: 8, offset: 2 }}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className={styles.accordianItems}>
                                    <Accordion.Header className={styles.accordianHeader}>
                                        <BsCreditCard2Front
                                            size={30}
                                            style={{ color: "#D19E18", marginRight: "15px" }}
                                        />{" "}
                                        <h5 className="m-0 fw-semibold">Credit Card</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1" className={styles.accordianItems}>
                                    <Accordion.Header className={styles.accordianHeader}>
                                        <BsCreditCard2Back
                                            size={30}
                                            style={{ color: "#F06500", marginRight: "15px" }}
                                        />{" "}
                                        <h5 className="m-0 fw-semibold">Debit Card</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className={styles.accordianItems}>
                                    <Accordion.Header className={styles.accordianHeader}>
                                        <img
                                            src="/Assest/icons/netbanking.png"
                                            alt=""
                                            className="me-3"
                                        />
                                        <h5 className="m-0 fw-semibold">Net Banking</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item
                                    eventKey="3"
                                    className={`py-0 ${styles.accordianItems}`}
                                >
                                    <Accordion.Header className={styles.accordianHeader}>
                                        <img
                                            src="/Assest/icons/bhim.png"
                                            alt=""
                                            className="me-2"
                                        />
                                        <h5 className="m-0 fw-semibold">UPI</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4" className={styles.accordianItems}>
                                    <Accordion.Header className={styles.accordianHeader}>
                                        <img
                                            src="/Assest/icons/paytm.png"
                                            alt=""
                                            className="me-2"
                                        />
                                        <h5 className="m-0 fw-semibold">Paytm</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}
