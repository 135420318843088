import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap"
import { FaCrown, FaChevronDown } from "react-icons/fa"
import { logout } from "../../redux/actions/authAction"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { homePageData } from "../../redux/actions/homeAction"
import { useDispatch, useSelector } from "react-redux"
import CustomEditModal from "../CustomEditModal"

export default function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const userDetails = useSelector((state) => state.home.userDetails)
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    if (!userDetails) {
      dispatch(homePageData())
    }
  }, [])

  return (
    <>
      <header>
        <Navbar
          collapseOnSelect
          expand="lg"
          style={{
            background:
              "linear-gradient(91.16deg, #FF648A -60.1%, #F0003B 90.47%)",
            boxShadow: "0px 3px 12px 2px rgba(255, 255, 255, 0.3)",
          }}
        >
          <Container>
            <Navbar.Brand href="/">
              <img
                src="/Assest/icons/spouzeLogo.png"
                alt="."
                height={31}
                width={32}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="flex-fill my-2 my-lg-0 justify-content-evenly">
                <Nav.Link
                  onClick={() => navigate("/")}
                  className={`${location.pathname === "/" ? "text-dark fw-bold" : "text-white"
                    }`}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate("/matches")}
                  className={`${location.pathname === "/matches"
                    ? "text-dark fw-bold"
                    : "text-white"
                    }`}
                >
                  Matches
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate("/inboxpage")}
                  className={`${location.pathname === "/inboxpage"
                    ? "text-dark fw-bold"
                    : "text-white"
                    }`}
                >
                  Inbox
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate("/chats")}
                  className={`${location.pathname === "/chats" ? "text-dark fw-bold" : "text-white"
                    }`}
                >
                  Chat
                </Nav.Link>
              </Nav>
              <div className="ms-auto mb-2 mb-lg-0 text-white d-sm-flex justify-content-between">
                <div>
                  <button
                    className="btn btn-outline-light d-flex align-items-center me-4"
                    onClick={() => navigate("/premimumplans")}
                    style={
                      location.pathname === "/premimumplans"
                        ? { visibility: "hidden" }
                        : {}
                    }
                  >
                    <FaCrown size={28} className="me-3" />
                    Upgrade Now
                  </button>
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6
                      className="me-2 m-0 cursor-pointer"
                      onClick={() => setModalShow(true)}
                    >
                      Contact us
                    </h6>
                    <div
                      style={{
                        width: "1px",
                        opacity: "0.5",
                        background: "white",
                        height: "30px",
                        margin: "auto",
                      }}
                    ></div>
                    <NavDropdown
                      title={
                        <>
                          <img
                            // src="/Assest/image/user.jpg"
                            src={
                              userDetails?.user_detail?.user_profile_pic ||
                              "/Assest/image/homepage-userphoto.png"
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src =
                                "/Assest/image/homepage-userphoto.png"
                            }}
                            alt="."
                            height="40px"
                            width="40px"
                            className="rounded-circle"
                          />
                          <FaChevronDown
                            className="ms-2"
                            style={{ color: "#F2F2F2" }}
                          />
                        </>
                      }
                      id="basic-nav-dropdown"
                      className="ms-2 ms-lg-2 text-white"
                      align="end"
                    >
                      <NavDropdown.Item onClick={() => navigate("/setting")}>
                        Setting
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => navigate("/interestandrequests")}
                      >
                        Interests & Requests
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => navigate("/editprofile")}
                      >
                        Edit Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => navigate("/editpreferences")}
                      >
                        Edit Preferences
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        href="/matrimonial"
                        onClick={async () => {
                          await logout()
                        }}
                      >
                        Log out
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <CustomEditModal
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
      >
        <Modal.Header
          className="border-0 pb-1 align-items-baseline"
          closeButton
        >
          <strong>Contact us</strong>{" "}
        </Modal.Header>
        <Modal.Body className="pt-0 px-4">
          <div className="my-3">
            <Row>Contanct no :</Row>
            <Row className="ms-2"> +645 658 2125</Row>
            <Row className="mt-3">Email :</Row>
            <Row className="ms-2"> Spouze@gmail.com</Row>
          </div>
        </Modal.Body>
      </CustomEditModal>
    </>
  )
}
