import React, { useState, useRef } from "react"
import { Row, Form, Modal, Col } from "react-bootstrap"
import CustomButton from "../../Components/CustomButton"
import { useDispatch } from "react-redux"
import { signupSiblingsDetails } from "../../redux/actions/authAction"

export default function StageFourteen({ handleModalStage }) {
  const dispatch = useDispatch()

  const [fields, setFiels] = useState({
    noofbrothers: '',
    marriedbrothers: '',
    noofsisters: '',
    marriedsisters: '',

  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)


  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault()

    setLoading(true)
    let data = {
      noofbrothers: parseInt(fields.noofbrothers),
      marriedbrothers: parseInt(fields.marriedbrothers),
      noofsisters: parseInt(fields.noofsisters),
      marriedsisters: parseInt(fields.marriedsisters)
    }
    dispatch(signupSiblingsDetails(data)).then((res) => {
      if (res.status === 200) {
        handleModalStage()
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value } = e.target

    setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
  }

  return (
    <>
      <Modal.Header className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <h6 className="mb-3">How Many Siblings do you have? <small className='text-grey'>(Optional)</small></h6>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={6} className="mt-3">
              <Form.Group>
                <Form.Label className="m-0">No of Brothers</Form.Label>
                <Form.Control type="text"
                  placeholder='0'
                  name="noofbrothers"
                  value={fields.noofbrothers}
                  onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Col>
            <Col sm={6} className="mt-3">
              <Form.Group>
                <Form.Label className="m-0">Married Brothers</Form.Label>
                <Form.Control type="text"
                  placeholder='0'
                  name="marriedbrothers"
                  value={fields.marriedbrothers}
                  onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mt-3">
              <Form.Group>
                <Form.Label className="m-0">No of Sisters</Form.Label>
                <Form.Control
                  type="text"
                  placeholder='0'
                  name="noofsisters"
                  value={fields.noofsisters}
                  onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Col>
            <Col sm={6} className="mt-3">
              <Form.Group>
                <Form.Label className="m-0">Married Sisters</Form.Label>
                <Form.Control
                  type="text"
                  placeholder='0'
                  name="marriedsisters"
                  value={fields.marriedsisters}
                  onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Col>
          </Row>


          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue"
              loading={loading}
            // handleClick={() => navigate("/welcome", { replace: true })} 
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  )
}
