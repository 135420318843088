import React, { useEffect, useState } from 'react'
import CustomModal from '../Components/CustomModal'
import Login from './Login'
import Signup from './SignUp'
import OtpModal from './SignUp/OtpModal'
import StageEight from './SignUp/StageEight'
import StageFive from './SignUp/StageFive'
import StageFour from './SignUp/StageFour'
import StageNine from './SignUp/StageNine'
import StageOne from './SignUp/StageOne'
import StageSeven from './SignUp/StageSeven'
import StageSix from './SignUp/StageSix'
import StageFifteen from './SignUp/StageFifteen'
import StageThree from './SignUp/StageThree'
import StageFourteen from './SignUp/StageFourteen'
import StageTwo from './SignUp/StageTwo'
import AddFatherDetails from './SignUp/AddFatherDetails'
import AddMotherDetails from './SignUp/AddMotherDetails'
import AddBrotherDetails from './SignUp/AddBrotherDetails'
import AddSisterDetails from './SignUp/AddSisterDetails'

export default function Modals(props) {
    const [modalStage, setModalStage] = useState(-2)
    // const [modalStage, setModalStage] = useState(4)

    useEffect(() => {
        setModalStage(props.initial)
    }, [props.initial])


    // Handle Log-in/Sign-up modal stages
    const handleModalStage = () => {
        switch (modalStage) {
            case -3:
            case -2:
            case -1:
                setModalStage(0)
                break;

            default:
                setModalStage(modalStage + 1)
                break;
        }
    }

    return (
        <>
            <CustomModal
                show={props.modalShow}
                onHide={() => props.setModalShow(false)}
                modalStage={modalStage}
            >
                {modalStage === -3 && <Login onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} modalstage={modalStage}/>}
                {modalStage === -2 && <Login onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} modalstage={modalStage}/>}
                {modalStage === -1 && <Signup onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}
                {modalStage === 0 && <OtpModal onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}

                {modalStage === 1 && <StageOne onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 2 && <StageTwo onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 3 && <StageThree onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 4 && <StageFour onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 5 && <StageFive onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 6 && <StageSix onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 7 && <StageSeven onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 8 && <StageEight onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
                {modalStage === 9 && <StageNine onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}

                {modalStage === 10 && <AddFatherDetails onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}
                {modalStage === 11 && <AddMotherDetails onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}
                {modalStage === 12 && <AddBrotherDetails onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}
                {modalStage === 13 && <AddSisterDetails onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} setModalStage={setModalStage} />}
                {modalStage === 14 && <StageFourteen onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}

                {modalStage === 15 && <StageFifteen onHide={() => props.setModalShow(false)} handleModalStage={handleModalStage} />}
            </CustomModal>
        </>
    )
}
