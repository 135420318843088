import React from "react"
import { Card } from "react-bootstrap"
import styles from "./styles.module.css"

export default function TabCard(props) {
  return (
    <>
      <Card className={styles.settingCards}>
        <Card.Header className={styles.settingCardHeader}>
          <h4 className="m-0 fw-semibold">{props.headertext}</h4>
        </Card.Header>
        {props.children}
      </Card>
    </>
  )
}
