import React, { useEffect } from 'react'
import { BsFillCheckCircleFill } from "react-icons/bs"
import styles from "./styles.module.css"
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BASE_URL } from "../../Services/config"
import { useSelector } from 'react-redux'

function PaymentSuccess() {
    const navigate = useNavigate()
    const accessToken = useSelector((store) => store.auth.accessToken)
    const [searchParams] = useSearchParams();

    const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
    const currentUserID = useSelector(state => state.auth.currentUserID)
    const getUserDetail = currentUserID || currentuser?.uid


    useEffect(() => {

        console.log("==============", searchParams.get('session_id'));
    }, []);

    const handleSubmit = async (e) => {
        console.log('submit success to backend');
        e.preventDefault()
        fetch(`${BASE_URL}/app/subscription/paymentsuccess`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({sessionId: searchParams.get('session_id'), user_id: getUserDetail})
          })
            .then(res => {
                if (res.ok) return res.json();
                return res.json().then(json => Promise.reject(json));
            })
            .then(data => {
                console.log(data.message);
                navigate("/")
            })
            .catch(e => {
                console.log(e.error);
            });
    }


    return (
        <>
            <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: "95vh" }}>
                <div className="mb-4 text-center">
                    <BsFillCheckCircleFill size={75} />
                </div>
                <div className="text-center">
                    <h1>Thank You !</h1>
                    <button className={`${styles.paybtn} mt-3`} onClick={handleSubmit}>Back Home</button>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccess