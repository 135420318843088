import { createSlice } from '@reduxjs/toolkit'


const INITIAL_STATE = {
    loading: true,
    userProfileDetails: null,
    userPreferencesDetails: null,
    notificationList: null,
}

export const editProfileReducer = createSlice({
    name: 'home',
    initialState: INITIAL_STATE,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setUserProfileDetails(state, action) {
            state.userProfileDetails = action.payload;
        },
        setUserPreferencesDetails(state, action) {
            state.userPreferencesDetails = action.payload;
        }
    }
})

export const editProfileActions = editProfileReducer.actions;

export default editProfileReducer;