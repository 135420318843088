import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { HiOutlineChevronDown } from "react-icons/hi"
import TabCard from "../../Components/TabCard"
import ToggleSwitch from "../../Components/ToggleSwitch"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import styles from "./styles.module.css"
import { deleteProfile } from "../../redux/actions/editProfileAction"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logout } from "../../redux/actions/authAction"
import { toast } from "react-toastify"
import { getProfileSettingData, setProfileSetting } from "../../redux/actions/settingAction"

export default function Profile() {
  const [visiblity, setVisibility] = useState({
    "isPrivate": false,
    "PhotoVisibleToOther": false,
    "PhoneVisibleToOther": false
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getProfileSettingData())
  }, [])

  const settingData = useSelector(state => state.setting.profileSetting)

  useEffect(() => {
    if (settingData) {
      setVisibility(settingData)
    }
  }, [settingData])

  const handleChange = async (e) => {
    const { name, checked } = e.target

    await dispatch(setProfileSetting({ ...visiblity, [name]: checked }))
    await setVisibility({ ...visiblity, [name]: checked })
  }

  return (
    <>
      <TabCard headertext="Profile">
        <Card.Body>
          <div className="p-4">
            {/* <div className="d-flex justify-content-between">
              <div>
                <h5 className="theme-color fw-semibold">Photo</h5>
                <h6 className="text-lightgrey fw-bold">Visible to all {visiblity.PhotoVisibleToOther && "premium"} members</h6>
              </div>
              <div>
                <ToggleSwitch label="Photo" name="PhotoVisibleToOther" checked={visiblity.PhotoVisibleToOther} handleChange={handleChange} />
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-4">
              <div>
                <h5 className="theme-color fw-semibold">Phone</h5>
                <h6 className="text-lightgrey fw-bold">
                  Visible to all {visiblity.PhoneVisibleToOther && "premium"} members
                </h6>
              </div>
              <div>
                <ToggleSwitch label="Phone" name="PhoneVisibleToOther" checked={visiblity.PhoneVisibleToOther} handleChange={handleChange} />
              </div>
            </div>
            <hr /> */}
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="theme-color fw-semibold">Hide Profile</h5>
                {visiblity.isPrivate ?
                  <h6 className="text-lightgrey fw-bold">
                    Your profile is currently hidden
                  </h6>
                  :
                  <h6 className="text-lightgrey fw-bold">
                    Your profile is currently visible
                  </h6>
                }
              </div>
              <div>
                <ToggleSwitch label="profileSetting" name="isPrivate" checked={visiblity.isPrivate} handleChange={handleChange} />
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-4">
              <div>
                <h5 className="theme-color fw-semibold">Delete Profile</h5>
                {/* <h6 className="text-lightgrey">
                  Delete your Profile from Spouze
                </h6> */}
              </div>
              <div>
                <h5 className="text-info cursor-pointer" onClick={() => setDeleteModal(true)}>Delete</h5>
              </div>
            </div>
          </div>
        </Card.Body>
        <Modal
          show={deleteModal}
          size="md"
          onHide={() => setDeleteModal(false)}
          fullscreen="sm-down"
          centered
          backdrop="static"
        >
          <ModalHeader toggle={() => setDeleteModal(false)} className="theme-color fw-semibold">Delete Profile</ModalHeader>
          <ModalBody>
            Are you sure, you want to delete your account?
          </ModalBody>
          <ModalFooter>
            <h5 className="text-info me-4 cursor-pointer" onClick={() => {
              dispatch(deleteProfile()).then(async (res) => {
                if (res.status === 200) {
                  setDeleteModal(false)
                  toast.success("Delete successfully")
                  await logout()
                  localStorage.clear()
                  navigate("/matrimonial")
                }
              })
            }}>Yes</h5>
            <h5 className="theme-color cursor-pointer" onClick={() => setDeleteModal(false)}>No</h5>
          </ModalFooter>
        </Modal>
      </TabCard>
    </>
  )
}