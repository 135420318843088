import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import CustomButton from '../../../Components/CustomButton'
import { editContactDetails, getMyProfileData } from '../../../redux/actions/editProfileAction'

const ContactDetails = ({ setModalShow, myProfileData }) => {
    const dispatch = useDispatch()
    const [fields, setFiels] = useState({
        phonenumber: myProfileData?.PersonalDetail?.MobileNumber,
        email: myProfileData?.PersonalDetail?.EmailAddress,
    })
    const [loading, setLoading] = useState(false)

    // Handle Form Submition
    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        let data = {
            MobileNumber: fields.phonenumber,
            EmailAddress: fields.email
        }
        dispatch(editContactDetails(data)).then((res) => {
            if (res.status === 200) {
                dispatch(getMyProfileData())
                setModalShow(false)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    // Handle Field Values
    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "phonenumber") {
            setFiels({ ...fields, [name]: value.replace(/[^\d+]/g, "") })
        } else {
            setFiels({ ...fields, [name]: value })
        }
    }
    return (
        <>
            <Modal.Header className="border-0 pb-1 align-items-baseline" closeButton />
            <Modal.Body className="pt-0 px-4">
                <Form onSubmit={handleSubmit}>
                    <Form.Group >
                        <Form.Label className="m-0">Phone Number</Form.Label>
                        <Form.Control
                            type="text" name="phonenumber"
                            value={fields.phonenumber}
                            placeholder='Mobile number with country code'
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Email</Form.Label>
                        <Form.Control
                            type="email" name="email"
                            placeholder='@gmail.com' value={fields.email}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <Form.Group className="text-center my-5">
                        <CustomButton btntext="Save" loading={loading} />
                    </Form.Group>
                </Form>
            </Modal.Body>
        </>
    )
}

export default ContactDetails