import React, { useState } from "react";
import { Form, Modal, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import styles from "./styles.module.css";
import { signupStageNine } from "../../redux/actions/authAction";

export default function StageNine(props) {
  const dispatch = useDispatch();

  const [fields, setFiels] = useState({
    aboutyourself: "",
    father: false,
    mother: false,
    brother: false,
    sister: false,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle Form Submition
  async function handleSubmit(e) {
    e.preventDefault();

    if (fields.aboutyourself) {
      setError({});
      setLoading(true);
      let data = {
        aboutyourself: fields.aboutyourself,
        father: fields.father,
        mother: fields.mother,
        brother: fields.brother,
        sister: fields.sister,
      };
      dispatch(signupStageNine(data)).then((res) => {
        if (res.status === 200) {
          if (data?.father === true) {
            props.setModalStage(10);
          } else if (data?.mother === true) {
            props.setModalStage(11)
          } else if (data?.brother === true) {
            props.setModalStage(12)
          } else if (data?.sister === true) {
            props.setModalStage(13)
          } else {
            props.setModalStage(14);
          }
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setError({
        aboutyourself: !fields.aboutyourself,
      });
      setLoading(false);
    }
  }

  // Handle Field Values
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (typeof checked != "undefined") {
      setFiels({ ...fields, [name]: checked });
    } else {
      setFiels({ ...fields, [name]: value });
    }
  };

  return (
    <>
      <Modal.Header className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0 px-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label
              className="m-0 mb-1 fw-semibold"
              style={{ fontSize: "15px" }}
            >
              About Yourself
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Write somthing about yourself..."
              name="aboutyourself"
              value={fields.aboutyourself}
              onChange={(e) => handleChange(e)}
              style={{ fontSize: "13px" }}
              required
              maxLength={4000}
            />
            <small className="d-block text-end mt-2">
              <span className="theme-color">{fields.aboutyourself.length}</span>
              (4000 max)
            </small>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label
              className="m-0 mb-3 fw-semibold"
              style={{ fontSize: "15px" }}
            >
              Add Family Details <small className='text-grey'>(Optional)</small>
            </Form.Label>
            <Row className="mb-2">
              <Col xs="6">
                <Form.Check
                  inline
                  label="Father"
                  className="mr-auto"
                  name="father"
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                />
              </Col>
              <Col xs="6">
                <Form.Check
                  inline
                  label="Mother"
                  name="mother"
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Form.Check
                  inline
                  label="Brothers"
                  name="brother"
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                />
              </Col>
              <Col xs="6">
                <Form.Check
                  inline
                  label="Sisters"
                  name="sister"
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="text-center my-5">
            <CustomButton btntext="Continue" loading={loading || !fields.aboutyourself} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  );
}
