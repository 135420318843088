import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FiChevronRight } from "react-icons/fi"
import Header from "../../Components/Header"
import { TbChevronLeft } from "react-icons/tb"
import styles from "./styles.module.css"
import ChangePassword from "./ChangePassword"
import Profile from "./Profile"
import Notification from "./Notification"
import ProfileVisibility from "./ProfileVisibility"
import { useNavigate } from "react-router-dom"

export default function Setting() {
  const navigate = useNavigate()
  return (
    <>
      <main>
        <Header />
        <Container>
          <div className="my-3 fw-semibold cursor-pointer" onClick={() => navigate(-1)}>
            <TbChevronLeft size={20} /> &nbsp; Settings
          </div>
          <Tabs>
            <Row className="m-0 gx-2">
              <Col md={3}>
                <TabList className={styles.settingTabs}>
                  {/* <Tab>
                    <div className="d-flex justify-content-between align-items-center cursor-pointer">
                      <div>
                        <h6 className="m-0">Change Password</h6>
                        <small className="text-lightgrey fs-12">
                          Change Password{" "}
                        </small>
                      </div>
                      <div>
                        <FiChevronRight />
                      </div>
                    </div>
                  </Tab> */}

                  <Tab>
                    <div className="d-flex justify-content-between align-items-center cursor-pointer">
                      <div>
                        <h6 className="m-0">Profile</h6>
                        <small className="text-lightgrey fs-12">
                          Hide and Delete Profile
                        </small>
                      </div>
                      <div>
                        <FiChevronRight />
                      </div>
                    </div>
                  </Tab>

                  <Tab>
                    <div className="d-flex justify-content-between align-items-center cursor-pointer">
                      <div>
                        <h6 className="m-0">Notification </h6>
                        <small className="text-lightgrey fs-12">
                          Push Notification Settings
                        </small>
                      </div>
                      <div>
                        <FiChevronRight />
                      </div>
                    </div>
                  </Tab>

                  {/* <Tab>
                    <div className="d-flex justify-content-between align-items-center cursor-pointer">
                      <div>
                        <h6 className="m-0">Set Profile Visibility </h6>
                        <small className="text-lightgrey fs-12">
                          push Notification Settings
                        </small>
                      </div>
                      <div>
                        <FiChevronRight />
                      </div>
                    </div>
                  </Tab> */}
                </TabList>
              </Col>

              <Col md={9}>
                {/* <TabPanel>
                  <ChangePassword />
                </TabPanel> */}

                <TabPanel>
                  <Profile />
                </TabPanel>

                <TabPanel>
                  <Notification />
                </TabPanel>

                {/* <TabPanel>
                  <ProfileVisibility />
                </TabPanel> */}
              </Col>
            </Row>
          </Tabs>
        </Container>
      </main>
    </>
  )
}
