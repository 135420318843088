import React from "react"
import { Form, Button, Modal, Card, Alert, NavDropdown } from "react-bootstrap"
import { useSelector } from "react-redux"
import { FaChevronDown } from "react-icons/fa"
import { logout } from "../../redux/actions/authAction"

function CustomEditModal(props) {
  const user = useSelector((store) => store.auth.currentUser)

  return (
    <>
      <Modal
        {...props}
        fullscreen="sm-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        {props.children}
      </Modal>
    </>
  )
}

export default CustomEditModal
