import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import CustomButton from '../../../Components/CustomButton'
import { editFatherDetails, getMyProfileData } from '../../../redux/actions/editProfileAction'

const FatherDetails = ({ setModalShow, myProfileData }) => {
    const dispatch = useDispatch()
    const [fields, setFiels] = useState({
        fathersname: myProfileData?.FamilyDetails?.FatherDetails?.FatherName || "",
        designation: myProfileData?.FamilyDetails?.FatherDetails?.Designation || '',
        companyname: myProfileData?.FamilyDetails?.FatherDetails?.CompanyName || '',
        fatherincome: myProfileData?.FamilyDetails?.FatherDetails?.FatherIncome || '',
    })
    const [loading, setLoading] = useState(false)

    // Handle Form Submition
    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        let data = {
            FatherName: fields.fathersname,
            Designation: fields.designation,
            CompanyName: fields.companyname,
            FatherIncome: parseInt(fields.fatherincome)
        }
        dispatch(editFatherDetails(data)).then((res) => {
            if (res.status === 200) {
                dispatch(getMyProfileData())
                setModalShow(false)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    // Handle Field Values
    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "fatherincome") {
            setFiels({ ...fields, [name]: value.replace(/[^0-9.]/g, "") })
        } else {
            setFiels({ ...fields, [name]: value })
        }
    }
    return (
        <>
            <Modal.Header className="border-0 pb-1 align-items-baseline" closeButton />
            <Modal.Body className="pt-0 px-4">
                <Form onSubmit={handleSubmit}>
                    <Form.Group >
                        <Form.Label className="m-0">Father’s Name</Form.Label>
                        <Form.Control
                            type="text" name="fathersname"
                            placeholder='Father’s name' value={fields.fathersname}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Designation</Form.Label>
                        <Form.Control
                            type="text" name="designation"
                            placeholder='Designation' value={fields.designation}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Company Name</Form.Label>
                        <Form.Control
                            type="text" name="companyname"
                            placeholder='Company Name' value={fields.companyname}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <br />
                    <Form.Group >
                        <Form.Label className="m-0">Father Income</Form.Label>
                        <Form.Control
                            type="text" name="fatherincome"
                            placeholder='$000.00' value={fields.fatherincome}
                            onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <Form.Group className="text-center my-5">
                        <CustomButton btntext="Save" loading={loading} />
                    </Form.Group>
                </Form>
            </Modal.Body>
        </>
    )
}

export default FatherDetails