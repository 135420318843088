import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import styles from "./styles.module.css";
import Slider from "react-slick";
import { BsCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "../../redux/actions/homeAction";
import { BASE_URL } from "../../Services/config"

// Hidden Arrows Right and Left
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}

export default function PremimumPlans() {
  const accessToken = useSelector((store) => store.auth.accessToken)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [plans, setPlans] = useState([]);
  const [activePlanId, setActivePlanId] = useState('')

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      // {
      //   breakpoint: 1324,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 1,
      //     infinite: true,
      //     dots: true,
      //   },
      // },
      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    dispatch(getPlans());
  }, []);

  const userplans = useSelector((state) => state.home.plans);


  useEffect(() => {
    if (userplans) {
      setPlans(userplans);

      userplans?.map((plan,index) => {
        if (plan.userPlanActive) {
          setActivePlanId(plan._id)
        }
      })
    }
  }, [userplans]);

  const handleSubmit = async (e, data) => {
    e.preventDefault()
    if (activePlanId !== '') {
      alert('You are a premium member!\nYou can activate new plan after current plan complete!')
      return
    }
    fetch(`${BASE_URL}/app/subscription/subscriptioncheckout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(
        { product_id: data.product_id.web },
      ),
    }).then(res => {
      if (res.ok) return res.json()
      console.log(res)
      return res.json().then(json => Promise.reject(json))
    }).then((res) => {
      if (res.data.session !== null) {
        window.location = res.data.session.url
      } else if (res.data.active_plan !== null) {
        setActivePlanId(res.data.active_plan)
      }

    }).catch(e => {
      console.error(e.error)
    })
  }

  const handleRestore = async (e) => {
    e.preventDefault()
    if (activePlanId !== '') {
      alert('You are a premium member!')
      return
    }
    fetch(`${BASE_URL}/app/subscription/restorepayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(
        {},
      ),
    }).then(res => {
      if (res.ok) return res.json()
      console.log(res)
      return res.json().then(json => Promise.reject(json))
    }).then((res) => {
      setActivePlanId(res.data.active_plan)
    }).catch(e => {
      console.error(e.error)
    })
  }

  const cancelSubscription = async(e)=>{
    e.preventDefault()
    fetch(`${BASE_URL}/app/subscription/cancelsubscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(
        {},
      ),
    }).then(res => {
      if (res.ok) return res.json()
      console.log(res)
      return res.json().then(json => Promise.reject(json))
    }).then((res) => {
      alert(res.msg)
    }).catch(e => {
      console.error(e.error)
    })
  }
  // c

  return (
    <>
      <Header />
      <main>
        <div className="position-relative overflow-hidden">
          <div className={styles.premimumBackCurv}></div>
        </div>
        <Container fluid className={styles.premimumCardsContainer}>
          <div className="text-white text-center mb-4">
            <h3 className="fw-semibold mb-4">Upgrade to Premium</h3>
            {/* <h5>
              Limited time offer! Get up to <u>15 Extra Days</u> on <br />{" "}
              Select Plans.
            </h5> */}
          </div>
          <br />
          <br />
          <br />
          <Slider {...settings}>
            {plans?.map((data,index) => {
              return (
                <div key={index}>
                  <div className="mt-3 d-flex justify-content-center">
                    <Card className={styles.premimumCard}>
                      <Card.Body className="text-center">
                        <Card.Title className="fw-bold">
                          {data.planName}
                        </Card.Title>
                        <p className="mb-2 text-grey">{data.days} Days</p>
                        <div className={styles.hrline}></div>
                        <Card.Text>
                          <p
                            className={
                              data.planName === "freeTrial" && "invisible"
                            }
                          >
                            {/* <del className="fw-semibold">${data.price * 2}</del>{" "}
                            &nbsp; &nbsp; &nbsp;{" "}
                            <span style={{ color: "#14D111" }}>50% off</span> */}
                          </p>
                          <h3 className="fw-bold">${data.price}</h3>
                          {/* <p className="text-grey">
                            Per day - ${(data.price / data.days).toFixed(2)}
                          </p> */}
                          {activePlanId === data._id ?
                            <button
                              className={`${styles.continueBtn}`}
                              onClick={(e) => handleSubmit(e, data)
                                //navigate("/premimumpayments", { state: data })
                              }
                            >
                              Subscribed
                            </button>
                            :
                            <button
                              className={`${styles.continueBtn}`}
                              onClick={(e) => handleSubmit(e, data)
                                //navigate("/premimumpayments", { state: data })
                              }
                            >
                              Continue
                            </button>}
                          <div className="text-start mt-4">
                            {data.description.map((descriptionline,index) =>
                              <p style={{ fontSize: 12 }} key={index}>
                                <BsCheckCircleFill
                                  className="me-2"
                                  size={15}
                                  style={{ color: "#14D111" }}
                                />
                                {descriptionline}
                              </p>)
                            }
                            {/* <p style={{ fontSize: 12 }}>
                              <BsCheckCircleFill
                                className="me-2"
                                size={15}
                                style={{ color: "#14D111" }}
                              />
                              View 200 contact numbers
                            </p>
                            <p style={{ fontSize: 12 }}>
                              <BsCheckCircleFill
                                className="me-2"
                                size={15}
                                style={{ color: "#14D111" }}
                              />
                              Whatsapp your Matches
                            </p> */}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div style={{display:"flex",justifyContent:"center"}}>
            {
              activePlanId === '' ?
                <button
                  style={{top:"70%"}}
                  // className={`${styles.continueBtn}`}
                  className= {styles.restoreBtn}
                  onClick={(e) => { handleRestore(e) }}
                >
                  Restore Purchase
                </button>
                :
                <button
                  style={{top:"70%"}}
                  // className={`${styles.continueBtn}`}
                  className= {styles.cancelBtn}
                  onClick={(e) => { cancelSubscription(e) }
                  }
                >
                  Cancel Subscription
                </button>
            }

          </div>
        </Container>
      </main>
    </>
  );
}
