import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { GoClock } from "react-icons/go"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../Components/Header"
import Loader from "../../Components/Loader"
import { getProfileViewNotificationList } from "../../redux/actions/homeAction"
import styles from "./styles.module.css"

export default function InterestAndRequests() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [notificationList, setNotificationList] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    dispatch(getProfileViewNotificationList(page)).then(res => {
      if (res.status === 200) {
        setNotificationList(res.data?.data?.list)
        if (res?.data?.data?.pages === 0 || res.data?.data?.current === res.data?.data?.pages) {
          setHasMore(false)
        }
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      setHasMore(false)
    })
  }, [])

  const fetchMoreNotification = () => {
    dispatch(getProfileViewNotificationList(page + 1)).then(res => {
      if (res.status === 200) {
        setNotificationList([...notificationList, ...res?.data?.data?.list])
        if (res?.data?.data?.pages === 0 || res?.data?.data?.current === res?.data?.data?.pages) {
          setHasMore(false)
        } else {
          setPage(res?.data?.data?.current)
        }
      }
    }).catch(error => {
      setHasMore(false)
    })
  }

  return (
    <>
      <main>
        <Header />
        <Container>
          <Row className="mt-4 mb-3">
            <Col md={{ span: 8, offset: 2 }}>
              {!loading &&
                <h5>{notificationList?.length ? "Alerts about Interest & Requests you sent" : "No alert at this time"}</h5>}
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <InfiniteScroll
                dataLength={page * 15}
                next={fetchMoreNotification}
                hasMore={hasMore}
                loader={<Loader />}
              // scrollableTarget="scrollableDiv"
              // height={400}
              // endMessage={
              //   <p style={{ textAlign: "center" }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
              >
                {notificationList?.map(item => {
                  return (
                    <Card
                      body
                      className={`${styles.othersCard} mb-2`}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="fs-17">
                          <img
                            src={item?.user_profile_pic || "/Assest/image/homepage-userphoto.png"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/Assest/image/homepage-userphoto.png";
                            }}
                            alt="."
                            height="40px"
                            width="40px"
                            className="rounded-circle me-3"
                          />
                          {item?.message}
                        </div>
                        <div>
                          <small className="text-grey">
                            <GoClock size={18} /> {moment(item.createdAt).fromNow()}
                          </small>
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </InfiniteScroll>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}
