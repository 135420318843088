import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Services/config';
import { matchesActions } from '../reducers/matchesReducer';
import { logout } from './authAction';

// Handle error messages
const getMessage = error => {
    let message = null;
    if (error && typeof error === 'object' && Object.keys(error).length) {
        if (error?.response && error?.response?.data) {
            if (error?.response?.data?.status && error?.response?.data?.status?.message) {
                if ((error?.response?.data?.status?.code === 400 && error?.response?.data?.status?.message === "User Not Found") || error?.response?.data?.status?.code === 401) {
                    logout()
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
                message = error?.response?.data?.status?.message;
            }
        }
    }

    if (!message) {
        message = 'Unable to proceed at the moment!!';
    }

    return message;
};

export const matchesCategory = () => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(matchesActions.setLoading(false))
        try {
            const url = BASE_URL + "/app/matches_category_list";
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        return response

                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const matchesData = (props) => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(matchesActions.setLoading(false))
        try {
            const url = BASE_URL + `/app/matches_list?page=${props.page}&limit=6&category_id=${props.category || props.selctedMatchesCategoryId}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        return response

                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const matchesFilter = (props) => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(matchesActions.setLoading(false))
        try {
            const url = BASE_URL + `/app/search?page=1&limit=10`;
            const response = await axios.post(url, props, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        return response

                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}

export const ProfileView = (props) => {
    return async dispatch => {
        const currentuser = JSON.parse(localStorage.getItem('matrimonialAuthCredentials'))
        const token = currentuser?.stsTokenManager
        dispatch(matchesActions.setLoading(false))
        try {
            const url = BASE_URL + `/app/profile/viewIncrease?userid=${props}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            });
            if (response && response.status) {
                switch (response.status) {
                    case 200:
                        return response

                    default:
                        console.log(false);
                }
            }
        } catch (error) {
            const message = getMessage(error);
            toast.error(message);
        }
    }
}