import React from 'react'
import { Button } from "react-bootstrap"
import styles from "./styles.module.css"

export default function CustomButton({ loading, btntext, handleClick, id }) {

    return (
        <>
            <Button
                type="submit"
                disabled={loading}
                className={styles.custombutton}
                onClick={handleClick}
                id={id}
            >
                {btntext}
            </Button>
        </>
    )
}
