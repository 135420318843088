import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  signupSuggestionList,
  signupSuggestionRequestSent,
} from "../../redux/actions/authAction";

const PageTwo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [suggestonList, setSuggestonList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  const accessToken = useSelector((store) => store.auth.accessToken);

  useEffect(() => {
    if (accessToken) {
      dispatch(signupSuggestionList()).then((res) => {
        setSuggestonList(res?.data?.data?.list);
        const userIds = res?.data?.data?.list.map((item,index) => item.user_id[index]);
        setSelectedList(userIds);
      });
    }
  }, [accessToken]);

  const handleSubmit = () => {
    if (selectedList.length) {
      let data = {
        receiver: selectedList,
      };
      dispatch(signupSuggestionRequestSent(data))
        .then((res) => {
          if (res.status === 200) {
            navigate("/", { replace: true });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <Container
      fluid
      className="position-relative vh-100"
      style={{
        background:
          "linear-gradient(163.09deg, #f54872 -4.42%, #f0003b 97.78%)",
        boxShadow: "0px 3px 12px 2px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Row className="p-0 m-0 align-items-center" style={{ height: "10vh" }}>
        <Col sm={12} md={12} lg={12} className="my-2 p-3">
          <h3 className={`${styles.headingText} text-white m-0`}>
            Let’s get started by Connecting with a few of your Matches!
            <span
              className={`${styles.headingText2} text-white position-absolute cursor-pointer`}
              onClick={() => navigate("/", { replace: true })}
            >
              Skip
            </span>
          </h3>
        </Col>
      </Row>
      <div className="position-absolute bottom-0 start-50 translate-middle-x w-100">
        <Container fluid className={`${styles.customContainer}`}>
          <Row>
            {suggestonList.map((item, index) => {
              return (
                <Col
                  xxl={2}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  className="my-2 d-flex justify-content-center"
                  key={index}
                >
                  <div
                    className="position-relative"
                    style={{
                      borderRadius: 14,
                      height: "209px",
                    }}
                    onClick={() => {
                      if (selectedList.includes(item.user_id)) {
                        setSelectedList(
                          selectedList.filter((data) => data !== item.user_id)
                        );
                      } else {
                        setSelectedList([...selectedList, item.user_id]);
                      }
                    }}
                  >
                    <div
                      style={{
                        borderTopLeftRadius: 14,
                        borderTopRightRadius: 14,
                        overflow: "hidden",
                        backgroundColor: "black",
                      }}
                    >
                      {selectedList.includes(item.user_id) && (
                        <div
                          className={`${styles.customCheckMark} position-absolute`}
                        >
                          <BsFillCheckCircleFill
                            className={`${styles.customCheckIcon} rounded-circle`}
                            size={27}
                          />
                        </div>
                      )}
                      <img
                        className="card-image"
                        // `/Assest/image/girl-${i + 12}.png`
                        src={
                          item.user_profile_pic ||
                          "/Assest/image/homepage-userphoto.png"
                        }
                        alt="."
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "/Assest/image/homepage-userphoto.png";
                        }}
                        height={152}
                        width={154}
                        style={{
                          objectFit: "scale-down",
                          objectPosition: "center",
                        }}
                      />
                      <h5
                        className="position-absolute text-white p-2"
                        style={{ bottom: "3.5rem", fontSize: 16 }}
                      >
                        {item.user_name}
                      </h5>
                    </div>
                    <div
                      className="bg-white position-absolute w-100 p-2"
                      style={{
                        borderRadius: 14,
                        bottom: -1,
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        fontSize: 12,
                        height: 65,
                      }}
                    >
                      {/* <small className="card-text">{item.age} yrs, 5’10”</small> */}
                      <small className="card-text">
                        {item.age} yrs, {item.height}
                      </small>
                      <br />
                      <small className="card-text">{item.country}</small>
                      <br />
                      <small className="card-text">{item.education}</small>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="pb-2 position-sticky bottom-0">
            <Col className="text-center mt-3">
              <Button
                className={`${styles.connectBtn}`}
                onClick={() => handleSubmit()}
                disabled={!selectedList?.length}
              >
                <AiOutlineCheck size={28} className="me-3" />
                Connect
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default PageTwo;
