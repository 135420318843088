import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TbChevronLeft } from "react-icons/tb";
import { BiEdit } from "react-icons/bi";
import { AiFillCamera, AiFillPlusCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import Header from "../../../Components/Header";
import styles from "../styles.module.css";
import UpdateProfile from "../../../Components/UpdateProfile";
import CustomEditModal from "../../../Components/CustomEditModal";
import FamilyDetails from "./FamilyDetails";
import FatherDetails from "./FatherDetails";
import MotherDetails from "./MotherDetails";
import {
  getMyProfileData,
  editProfilePicture,
  addMorePhotos,
  deletePhotos,
} from "../../../redux/actions/editProfileAction";
import BrotherDetails from "./BrotherDetails";
import SisterDetails from "./SisterDetails";
import ContactDetails from "./ContactDetails";
import AboutMe from "./AboutMe";
import HoroscopeDetails from "./HoroscopeDetails";
import Loader from "../../../Components/Loader";
import { homePageData } from "../../../redux/actions/homeAction";
import moment from "moment";
import PhotoViewer from "../../../Components/PhotoViewer";
import {ConvertInchToFeetAndInch} from "../../../Utils/HeightUnitsConvertUtils"

export default function EditProfile() {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showSelctedPhotos, setShowSelctedPhotos] = useState("");
  const [modalType, setModalType] = useState("");

  const [profilemodalShow, setProfileModalShow] = useState(false);
  const [imgCrop, setImgCrop] = useState(false);
  const [profileImg, setProfileImg] = useState([]);
  const [Imgdetail, setImageDetail] = useState({});
  const navigate = useNavigate();

  const onClose = () => {
    setImgCrop(null);
    setProfileModalShow(false);
  };

  const onCrop = (view) => {
    setImgCrop(view);
  };

  const handleClose = () => {
    setProfileModalShow(false);
  };

  const handlePhoto = (e) => {
    e.preventDefault();
    setProfileImg([...profileImg.splice(1), { imgCrop }]);
    setProfileModalShow(false);
  };

  const [myProfileData, setMyProfileData] = useState({});
  const [morePhotos, setMorePhotos] = useState([]);

  useEffect(() => {
    dispatch(getMyProfileData());
  }, []);

  const userProfileDetails = useSelector(
    (state) => state.editProfile.userProfileDetails
  );

  useEffect(() => {
    if (userProfileDetails) {
      setMyProfileData(userProfileDetails);
      // setMorePhotos(userProfileDetails)
    }
  }, [userProfileDetails]);

  const profileImageShow = profileImg.map((item,index) => item.imgCrop[index]);

  async function handleProfilePhotoChange() {
    if (profileImageShow.length > 0 && profileImageShow[0] !== false) {
      var data = {
        img: profileImageShow[0],
        Imgdetail: Imgdetail,
      };
      dispatch(editProfilePicture(data)).then((res) => {
        if (res?.status === 200) {
          dispatch(getMyProfileData());
          dispatch(homePageData());
        }
      });
    }
  }

  useEffect(() => {
    handleProfilePhotoChange();
  }, [profileImg]);

  const [isLoading, setIsLoading] = useState(false);

  async function handleAddPhotos(e) {
    let files = e.target.files;

    if (files.length > 0) {
      setIsLoading(true);
      dispatch(addMorePhotos(e.target.files)).then((res) => {
        if (res?.status === 200) {
          dispatch(getMyProfileData());
        }
        setIsLoading(false);
      });
    }
  }

  return (
    <>
      <main>
        <Header />
        <Container>
          <Row className="mt-4 mb-3">
            <Col md={{ span: 9, offset: 1 }} className="cursor-pointer">
              <TbChevronLeft size={20} onClick={() => navigate(-1)} /> &nbsp;
              Edit Profile
            </Col>
          </Row>

          <Row className="mt-4 mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <div className="d-flex justify-content-center">
                  <div style={{ width: "fit-content" }}>
                    <div
                      className={styles.editProfileImage}
                      onClick={() => setProfileModalShow(true)}
                    >
                      <img
                        // src="/Assest/image/homepage-userphoto.png"
                        src={
                          myProfileData?.ProfilePhoto ||
                          "/Assest/image/homepage-userphoto.png"
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "/Assest/image/homepage-userphoto.png";
                        }}
                        alt="profile"
                      />
                      <AiFillPlusCircle size={28} />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <h5 className="fw-semibold my-2">
                    {myProfileData?.PersonalDetail?.FirstName}{" "}
                    {myProfileData?.PersonalDetail?.LastName}{" "}
                  </h5>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input d-none"
                      id="inputGroupFile01"
                      multiple
                      accept="image/*"
                      onChange={(e) => handleAddPhotos(e)}
                    />
                    <label
                      className="custom-file-label text-grey cursor-pointer"
                      htmlFor="inputGroupFile01"
                    >
                      {" "}
                      <AiFillCamera size={20} className="mb-1" /> &nbsp; Add
                      More Photos
                    </label>
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-wrap">
                    {myProfileData?.Photos?.map((data,index) => {
                      return (
                        <>
                          <div className="position-relative rounded-3" key={index}>
                            <img
                              className="m-1 rounded-3 position-relative"
                              src={
                                data?.image ||
                                "/Assest/image/homepage-userphoto.png"
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/Assest/image/homepage-userphoto.png";
                              }}
                              alt="profile"
                              height={150}
                              onClick={() => {
                                setShowPhotoModal(true);
                                setShowSelctedPhotos(data?.image);
                              }}
                            />
                            <div
                              className="position-absolute end-0 top-0 m-2 cursor-pointer"
                              onClick={() => dispatch(deletePhotos(data._id))}
                            >
                              <div>
                                <IoClose
                                  className="rounded-circle p-1"
                                  style={{
                                    border: "0.5px solid black",
                                    background: "#ffffff8f",
                                  }}
                                  size={25}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {isLoading && <Loader />}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Family Details */}
          {/* <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Family Details</h5>
                      <BiEdit size={22} className="theme-color" onClick={() => {
                        setModalType("Family Details")
                        setModalShow(true)
                      }} />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Family Member</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>4</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Native Place</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>USA</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Stays in</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>City</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Affluence</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>-</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}

          {/* Father Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Father Details</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("Father Details");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.FatherDetails
                            ?.FatherName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.FatherDetails
                            ?.Designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.FatherDetails
                            ?.CompanyName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.FatherDetails
                            ?.FatherIncome || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Mother Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Mother Details</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("Mother Details");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.MotherDetails
                            ?.MotherName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.MotherDetails
                            ?.Designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.MotherDetails
                            ?.CompanyName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.MotherDetails
                            ?.MotherIncome || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Brother Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Brother Details</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("Brother Details");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.BrotherDetails
                            ?.BrotherName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.BrotherDetails
                            ?.Designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.BrotherDetails
                            ?.CompanyName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.BrotherDetails
                            ?.BrotherIncome || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.BrotherDetails
                            ?.Status || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Sister Details */}
          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Sister Details</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("Sister Details");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.SisterDetails
                            ?.SisterName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Designation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.SisterDetails
                            ?.Designation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Company name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.SisterDetails
                            ?.CompanyName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.SisterDetails
                            ?.SisterIncome || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.FamilyDetails?.SisterDetails
                            ?.Status || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Contact Details</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("Contact Details");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Phone Number</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.MobileNumber || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>E-mail Address</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.EmailAddress || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Whatsapp</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>64851359****</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">About Me</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("About Me");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>First Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.FirstName || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Last Name</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.LastName || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Profile Create By</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.AccountType || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Mother Tongue</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.MotherTongue || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Height</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        {myProfileData?.PersonalDetail?.Hight ? (
                          <h6>
                            {ConvertInchToFeetAndInch(myProfileData?.PersonalDetail?.Hight)[0]}'{ConvertInchToFeetAndInch(myProfileData?.PersonalDetail?.Hight)[1]}"
                            {/* {myProfileData?.PersonalDetail?.Hight}
                            {" "}
                            <small>inch</small> */}
                          </h6>
                        ) : (
                          "-"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Weight</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        {myProfileData?.PersonalDetail?.Weight ? (
                          <h6>
                            {myProfileData?.PersonalDetail?.Weight}{" "}
                            <small>Pound</small>
                          </h6>
                        ) : (
                          "-"
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Gender</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.Gender || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Education</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Education || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Resident Country</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Resident_Country ||
                            "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Resident State</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Resident_State || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Resident City</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Resident_City || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Home Country</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Home_Country || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Home State</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Home_State || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Home City</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Home_City || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>City</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.City || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Residential Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.ResidentialStatus || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Occupation</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Occupation || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Annual Income</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.AnnualIncome || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Work Experience</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.WorkExperience || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Interested In</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.InterestedIn || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Marital Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.MaritalStatus || "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Body Type</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.BodyType || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Passions/Hobbies</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.PassionsorHobbies ||
                            "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Habits</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.Habits || "-"}</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {/* <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>City</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>{myProfileData?.PersonalDetail?.City || "-"}</h6>
                      </Col>
                    </Row>
                  </Col> */}
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Residential Status</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.ResidentialStatus ||
                            "-"}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row className="align-items-center">
                      <Col sm={3} className="pe-0">
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>About Your Self </h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col sm={9}>
                        <h6>
                          {myProfileData?.PersonalDetail?.AboutYourself || "-"}{" "}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={{ span: 9, offset: 1 }}>
              <Card body className={styles.othersCard}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-semibold m-0">Horoscope Details</h5>
                      <BiEdit
                        size={22}
                        className="theme-color"
                        onClick={() => {
                          setModalType("Horoscope Details");
                          setModalShow(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Date Of Birth</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        {/* <h6>13-05-2002</h6> */}
                        {/* var bDate = currentMatch?.horoscope_details?.date_of_birth ? moment(currentMatch?.horoscope_details?.date_of_birth).format("MM/DD/YYYY") : "" */}
                        <h6>
                          {moment(
                            myProfileData?.PersonalDetail?.DateofBirth
                          ).format("MM/DD/YYYY") || "-"}{" "}
                        </h6>
                        {/* <h6>{myProfileData?.PersonalDetail?.DateofBirth || "-"} </h6> */}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center text-lightgrey">
                          <h6>Horoscope</h6>
                          <h6>:</h6>
                        </div>
                      </Col>
                      <Col className="p-0">
                        <h6>
                          {myProfileData?.PersonalDetail?.Horoscope || "-"}{" "}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <UpdateProfile
            showModal={profilemodalShow}
            onCrop={onCrop}
            onClose={onClose}
            handleSubmit={handlePhoto}
            handleClose={handleClose}
            setImageDetail={setImageDetail}
          />
        </Container>
      </main>

      {
        // (currentMatch?.user_details || currentMatch?.user_photos) &&
        <PhotoViewer
          showModal={showPhotoModal}
          setShowModal={setShowPhotoModal}
          // profilePhotos={currentMatch?.user_details}
          photos={myProfileData?.Photos}
          showSelctedUserPhotos={showSelctedPhotos}
        />
      }

      <CustomEditModal
        show={modalShow}
        size={modalType === "About Me" ? "lg" : "md"}
        onHide={() => setModalShow(false)}
      >
        {/* {modalType === "Family Details" && <FamilyDetails />} */}
        {modalType === "Father Details" && (
          <FatherDetails
            setModalShow={setModalShow}
            myProfileData={myProfileData}
          />
        )}
        {modalType === "Mother Details" && (
          <MotherDetails
            setModalShow={setModalShow}
            myProfileData={myProfileData}
          />
        )}
        {modalType === "Brother Details" && (
          <BrotherDetails
            setModalShow={setModalShow}
            myProfileData={myProfileData}
          />
        )}
        {modalType === "Sister Details" && (
          <SisterDetails
            setModalShow={setModalShow}
            myProfileData={myProfileData}
          />
        )}
        {modalType === "Contact Details" && (
          <ContactDetails
            setModalShow={setModalShow}
            myProfileData={myProfileData}
          />
        )}
        {modalType === "About Me" && (
          <AboutMe setModalShow={setModalShow} myProfileData={myProfileData} />
        )}
        {modalType === "Horoscope Details" && (
          <HoroscopeDetails
            setModalShow={setModalShow}
            myProfileData={myProfileData}
          />
        )}
      </CustomEditModal>
    </>
  );
}
