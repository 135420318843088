import React, { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import styles from "./styles.module.css"
import {
    generateRecaptcha,
    setPhoneNumber,
} from "../../redux/actions/authAction"
import CustomButton from "../../Components/CustomButton"
import { toast } from "react-toastify"

export default function Signup(props) {
    const [fields, setFiels] = useState({
        mobile: "",
    })
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    // Handle Field Values
    const handleChange = (e) => {
        const { name, value } = e.target
        setError(false)
        setFiels({ ...fields, [name]: value.replace(/[^\d+]/g, "") })
    }

    // Handle Form Submition
    async function handleSubmit(e) {
        e.preventDefault()

        if (fields.mobile.length >= 7 && fields.mobile.length <= 15 && fields.mobile.startsWith("+")) {
            try {
                setError(false)
                setLoading(true)
                await generateRecaptcha()
                await dispatch(setPhoneNumber(fields.mobile)).then((res) => {
                    if (res) {
                        props.handleModalStage()
                    }
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                })
            } catch (error) {
                toast.error(error?.message);
                setLoading(false)
            }
            setLoading(false)
        } else {
            setError(true)
            toast.warn("Please enter valid mobile number");
        }
    }

    return (
        <>
            <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
            <Modal.Body className="pt-0 px-4">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img
                        src="/Assest/icons/spouzeLogo.png"
                        alt="."
                        height={70}
                        width={70}
                    />
                    {/* <div className={`${styles.loginlogo} bg-lightgrey`}>LOGO</div> */}
                    <div>
                        <p className="text-grey fs-4 mt-3">Spouze</p>
                    </div>
                </div>
                <div className="mt-4">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="MobileNumber">
                            <Form.Label className="m-0">Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="mobile"
                                value={fields.mobile}
                                onChange={handleChange}
                                placeholder="Mobile number with country code"
                            />
                        </Form.Group>
                        <Form.Group className="text-center my-5">
                            <CustomButton
                                loading={loading || fields.mobile.length <= 7 || fields.mobile.length >= 15 || error}
                                btntext="SIGN UP"
                            />
                        </Form.Group>
                    </Form>
                </div>
                <div className="text-grey text-center mt-4">
                    Already a Member?{" "}
                    <span
                        className="theme-color cursor-pointer"
                        onClick={() => props.setModalStage(-2)}
                    >
                        Please Login
                    </span>
                </div>
            </Modal.Body>
            <div id="recaptcha-container"></div>
        </>
    )
}
